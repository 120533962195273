import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
// import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { garanteDate, asyncForEach, getParameterByName, ordenar } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';

import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import {pricetags} from 'react-icons-kit/ionicons/pricetags'
import MultiSelect from "@khanacademy/react-multi-select"



class Etiquetas extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			items: [],
			modal: { show: false },
			filterChanged: '',
			selected: [],
			columns: [],
			selectedItems: [],
			filterSituacao: [],
			filterLotacao: [],
			filterCategorias: [],
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleUnselectAll = this.handleUnselectAll.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		
	}

	

	handleClick(e, codigo, selecionado) {
		e.preventDefault();
		let selected = this.state.selected;
		let data = this.state.data;
		// console.log(e.target.id, selecionado, selected)
		if (selecionado) {
			let filteredData = data.filter((item) => {
				return Number(item.pk_ass) === Number(codigo)
			})
			let indexData = data.indexOf(filteredData[0])
			data.splice(indexData,1)
			console.log(indexData)
			if (filteredData && filteredData[0]) {
				console.log(filteredData[0])
				selected.push(filteredData[0])
			}
			
		} else {
			let filteredData = selected.filter((item) => {
				return Number(item.pk_ass) === Number(codigo)
			})
			let indexData = selected.indexOf(filteredData[0])
			selected.splice(indexData,1)
			console.log(indexData)
			if (filteredData && filteredData[0]) {
				console.log(filteredData[0])
				data.push(filteredData[0])
			}			
		}

		this.setState({selected: selected, data: data})
		
	}

	handleSelectAll(e) {
		e.preventDefault();
		let selected = this.state.selected;
		let data = this.state.data;
		selected = selected.concat(data)
		data = []
		this.setState({selected: selected, data: data})
	}


	handleUnselectAll(e) {
		e.preventDefault();
		let selected = this.state.selected;
		let data = this.state.data;
		data = data.concat(selected)
		selected = []
		this.setState({selected: selected, data: data})
	}

	populaLotacao(item, id) {
		if (item.type === 'lotacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaSituacoes(item, id) {
		if (item.type === 'situacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaCategorias(item, id) {
		if (item.type === 'categorias') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		//Lotação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getLotacoes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosLotacao = this.state.combos;
				await asyncForEach(r, async (item) => {
					let lotacao = {
						type: 'lotacao',
						display: item.descricao,
						value: item.pk_lot,
						label: item.descricao,
					};
					await combosLotacao.push(lotacao);
				});
			});
		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSituacoes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosSituacao = this.state.combos;
				await asyncForEach(r, async (item) => {
					let situacao = {
						type: 'situacao',
						display: item.descricao,
						value: item.pk_sit,
						label: item.descricao,
					};
					await combosSituacao.push(situacao);
				});
			});
		
		//Categorias
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCategorias= this.state.combos;
			await asyncForEach(r, async (item) => {
				let categoria = {
					type: 'categorias',
					display: item.descricao,
						value: item.pk_cat,
						label: item.descricao,
				};
				await combosCategorias.push(categoria);
			});
		});



		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = true;
		query.geral = getParameterByName('geral');
		query.nome = getParameterByName('nome');
		query.lotacao = getParameterByName('lotacao');
		query.situacao = getParameterByName('situacao');
		query.categoria = getParameterByName('categoria');
		query.tipoServico = getParameterByName('tipoServico') || '0';
		this.setState({
			filter: query,
			filterSituacao: JSON.parse(query.situacao || '[]'),
			filterLotacao: JSON.parse(query.lotacao || '[]'),
			filterCategorias: JSON.parse(query.categoria || '[]'),
		});


		this.setState({
			filter: query,
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
		ordenar('filtroLotacao')
		ordenar('filtroSituacao')
		ordenar('filtroCategoria')		
	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteAssociado?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/etiquetas'); //Apaga QueryURL
		this.setState({ filter: [], filterSituacao: [], filterLotacao: [], filterCategorias: [] });
	}

	handlePrint(e) {
		e.preventDefault();
		if (this.state.selected && this.state.selected.length > 0) {
			swal({
				dangerMode: true,
				title: 'Atenção!',
				text: 'As etiquetas serão geradas. Confirma?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) {
					//Gerar
					fetch(
						config.protocol +
							'://' +
							config.server +
							':' +
							config.portBackend +
							'/api/gerarEtiquetas',
						{
							method: 'POST',
							headers: {
								'Content-type': 'application/json'
							},
							body: JSON.stringify({items: this.state.selected})
						}
					)
						.then((r) => r.blob())
						.then((r) => {
							var url = window.URL.createObjectURL(r);
							var a = document.createElement('a');
							a.href = url;
							a.download = "etiqueta.pdf";
							document.body.appendChild(a);
							a.click();    
							a.remove();
						});
				}
			});
		} else {
			swal('Selecione pelo menos um associado!', 'Não foi possível gerar as etiquetas. Selecione ao menos um associado.', 'error');
		}
		
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_nasc = garanteDate(item.data_nasc);
			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let filtered = await data.filter((item) => {
				//Filtro: Geral
				let valores = JSON.stringify(Object.values(item));
				let geral = true;
				if (filter.geral) {
					geral = (valores || '').includes((filter.geral || '').toUpperCase());
				}

				//Filtro: Nome
				let nome = (item.nome || '').includes((filter.nome || '').toUpperCase());

				//Filtro: Lotação
				let lotacao = Number(item.fk_lot) === Number(filter.lotacao) || (filter.lotacao || 'T') === 'T';

				//Filtro: Situação
				let situacao = Number(item.fk_sit) === Number(filter.situacao) || (filter.situacao || 'T') === 'T';

				//Filtro: Categoria
				let categoria = Number(item.fk_cat) === Number(filter.categoria) || (filter.categoria || 'T') === 'T';

				//Filtro: Tipo de Serviço
				let tipoServico = false;
				switch (Number(filter.tipoServico)) {

					//TELEFONIA
					case 1: tipoServico = (item.telefonia_claro==='S') || (item.telefonia_vivo==='S'); break;
					case 2: tipoServico = (item.telefonia_claro==='S'); break;
					case 3: tipoServico = (item.telefonia_vivo==='S'); break;

					//UNIMED
					case 10: tipoServico = (item.unimed_ambulatorial==='S') || (item.unimed_hospitalar==='S') || (item.unimed_global==='S') || (item.unimed_sos==='S') || (item.unimed_odonto==='S'); break;
					case 11: tipoServico = (item.unimed_ambulatorial==='S'); break;
					case 12: tipoServico = (item.unimed_hospitalar==='S'); break;
					case 13: tipoServico = (item.unimed_global==='S'); break;
					case 14: tipoServico = (item.unimed_sos==='S'); break;
					case 15: tipoServico = (item.unimed_odonto==='S'); break;

					//ANAPE
					case 20: tipoServico = (item.anape==='S'); break;

					//TODOS
					default: tipoServico = true;

				}

				let selected = this.state.selected
				let hasSelected = selected.filter((selItem) => {
					return Number(selItem.pk_ass) === Number(item.pk_ass)
				})

				let filtraSelecionado = hasSelected.length === 0

				//Filtra
				return (
					geral &&
					nome &&
					// lotacao &&
					// situacao &&
					// categoria && 
					filtraSelecionado && 
					tipoServico
				);
			});

			let queryString = '?';

			if (filter.geral) {
				if (queryString === '?') {
					queryString = queryString + 'geral=' + filter.geral;
				} else queryString = queryString + '&geral=' + filter.geral;
			}

			if (filter.tipoServico && filter.tipoServico > 0) {
				if (queryString === '?') {
					queryString = queryString + 'tipoServico=' + filter.tipoServico;
				} else queryString = queryString + '&tipoServico=' + filter.tipoServico;
			}

			if (filter.diaIni) {
				if (queryString === '?') {
					queryString = queryString + 'diaIni=' + filter.diaIni;
				} else queryString = queryString + '&diaIni=' + filter.diaIni;
			}

			if (filter.mesIni) {
				if (queryString === '?') {
					queryString = queryString + 'mesIni=' + filter.mesIni;
				} else queryString = queryString + '&mesIni=' + filter.mesIni;
			}

			if (filter.diaFim) {
				if (queryString === '?') {
					queryString = queryString + 'diaFim=' + filter.diaFim;
				} else queryString = queryString + '&diaFim=' + filter.diaFim;
			}

			if (filter.mesFim) {
				if (queryString === '?') {
					queryString = queryString + 'mesFim=' + filter.mesFim;
				} else queryString = queryString + '&mesFim=' + filter.mesFim;
			}


			if (filter.nome) {
				if (queryString === '?') {
					queryString = queryString + 'nome=' + filter.nome;
				} else queryString = queryString + '&nome=' + filter.nome;
			}

			if (filter.lotacao) {
				if (queryString === '?') {
					queryString = queryString + 'lotacao=' + filter.lotacao;
				} else queryString = queryString + '&lotacao=' + filter.lotacao;
			}
			
			
			if (this.state.filterSituacao) {
				if (queryString === '?') {
					queryString = queryString + 'situacao=' + JSON.stringify(this.state.filterSituacao);
				} else queryString = queryString + '&situacao=' + JSON.stringify(this.state.filterSituacao);
			}
			
			if (filter.categoria) {
				if (queryString === '?') {
					queryString = queryString + 'categoria=' + filter.categoria;
				} else queryString = queryString + '&categoria=' + filter.categoria;
			}
			console.log()

			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/etiquetas' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/etiquetas?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let colunasExportacao = [
					{value: 'Código', selected: false},
					{value: 'Nome', selected: false},
					{value: 'Matrícula', selected: false},
					{value: 'Categoria', selected: false},
					{value: 'Celulares', selected: false}
				];
				//Filtra
				let items = await this.filterData(r);
				//Trata
				console.log(items)
				if(this.state.filter.diaIni >= 1){
					items = items.filter (i => { 
						
							return i.aniversario && Number(i.aniversario.split('/')[0]) >= this.state.filter.diaIni 
						
					})
				}
				if(this.state.filter.mesIni >= 1){
					items = items.filter (i => { 
						
							return i.aniversario && Number(i.aniversario.split('/')[1]) >= this.state.filter.mesIni 
						
					})
				}
				if(this.state.filter.diaFim >= 1){
					items = items.filter (i => { 
						
							return i.aniversario && Number(i.aniversario.split('/')[0]) <= this.state.filter.diaFim 
						
					})
				}
				if(this.state.filter.mesIni >= 1){
					items = items.filter (i => { 
						
							return i.aniversario && Number(i.aniversario.split('/')[1]) <= this.state.filter.mesFim 
						
					})
				}
				if(this.state.filterSituacao.length > 0){
					items = (items.filter(i => { return this.state.filterSituacao.includes(i.fk_sit)}))

				}
				if(this.state.filterCategorias.length > 0){
					items = (items.filter(i => { return this.state.filterCategorias.includes(i.fk_cat)}))

				}
				if (this.state.filterLotacao.length>0) {
					items = (items.filter(i => { return this.state.filterLotacao.includes(i.fk_lot)}))

				}
				console.log(items)
				items =  await this.modelingData(items);
				this.setState({ data: items, columns: colunasExportacao });
			});
	}


	render() {
		let lotacoes = this.state.combos.filter((item) => {
			return item.type === 'lotacao';
		});
		let situacoes = this.state.combos.filter((item) => {
			return item.type === 'situacao';
		});
		let categorias = this.state.combos.filter((item) => {
			return item.type === 'categorias';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Etiquetas" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Gerador de Etiquetas</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Geral</label>
									<input
										name="geral"
										type="text"
										id="filtroGeral"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.geral || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Nome</label>
									<input
										name="nome"
										type="text"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.nome || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro" style={{width: '300px'}}>

									<label className="labelFiltro">Lotação</label>
									<MultiSelect
										options={lotacoes}
										selected={this.state.filterLotacao}
										onSelectedChanged={selected => this.setState({ filterLotacao: selected })}
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '190px' }}>
									<label className="labelFiltro">Situação</label>
									<MultiSelect
										options={situacoes}
										selected={this.state.filterSituacao}
										onSelectedChanged={selected => this.setState({ filterSituacao: selected })}
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="itemFiltro" style={{width: 190}}>
									<label className="labelFiltro">Categoria</label>
									{/* <select
										name="categoria"
										id="filtroCategoria"
										className="selectFiltro"
										value={this.state.filter.categoria || 'T'}
										onChange={this.handleChange}
									>
										
										
									</select> */}
									<MultiSelect
										options={categorias}
										selected={this.state.filterCategorias}
										onSelectedChanged={selected => this.setState({ filterCategorias: selected })}
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Tipos de Serviço</label>
									<select
										name="tipoServico"
										id="filtrotipoServico"
										className="selectFiltro"
										value={this.state.filter.tipoServico || '0'}
										onChange={this.handleChange}
									>
										<option value="0">TODOS</option>
										<option value="1">TODOS DE TELEFONIA</option>
										<option value="2">CLARO</option>
										<option value="3">VIVO</option>
										<option value="10">TODOS DE UNIMED</option>
										<option value="11">UNIMED AMBULATORIAL</option>
										<option value="12">UNIMED HOSPITALAR</option>
										<option value="13">UNIMED GLOBAL</option>
										<option value="14">UNIMED SOS</option>
										<option value="15">UNIMED ODONTO</option>
										<option value="20">ANAPE</option>
									</select>
								</div>
								<br/>
								<div className="itemFiltro">
									<label className="labelFiltro">Dia/Mês Inicial Aniversariantes </label>
									<input type="number" id="diaIni" name="diaIni" min="1" max="31" className="selectFiltro"
										value={this.state.filter.diaIni || ''}
										onChange={this.handleChange}
									/>
									<select
										name="mesIni"
										id="mesIni"
										className="selectFiltro"
										value={this.state.filter.mesIni || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o mês</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>

									</select>
								</div>
								<div className="itemFiltro">

									<label className="labelFiltro"> Dia/Mês Final Aniversariantes </label>

									<input type="number" id="diaFim" name="diaFim" min="1" max="31" className="selectFiltro"
										value={this.state.filter.diaFim || ''}
										onChange={this.handleChange}
									/>
									<select
										name="mesFim"
										id="mesFim"
										className="selectFiltro"
										value={this.state.filter.mesFim || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o mês</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>

									</select>

									{/* <input
										type="date"
										name="data_nasc_min"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '140px' }}
										value={this.state.filter.data_nasc_min || ''}
										onChange={this.handleChange}
									/>
									<input
										type="date"
										name="data_nasc_max"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '140px' }}
										value={this.state.filter.data_nasc_max || ''}
										onChange={this.handleChange}
									/> */}
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<button className="buttonNovo" onClick={this.handlePrint}>
									<Icon size={20} style={{ display: 'inline' }} icon={pricetags} /> Gerar Etiquetas
								</button>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela" style={{ width: '48%', float: 'left'}}>
								<div style={{marginBottom: '10px' }}>
									Registros:
									<button 
										className="buttonVoltar" 
										style={{ float:'right' }}
										onClick={this.handleSelectAll}
									>
										Selecionar Todos
									</button>
								</div>
								<div>
									<ReactTable
										data={this.state.data}
										previousText="Anterior"
										nextText="Próximo"
										loadingText="Carregando..."
										pageText="Página"
										ofText="de"
										rowsText="registros"
										noDataText="Nenhum registro encontrado"
										getTdProps={(state, rowInfo, column, instance) => {
											return {
												onClick: (e, handleOriginal) => {
													this.handleClick(e, rowInfo.row.pk_ass, true)
												}
											}
										}}
										columns={[
											{
												Header: 'Associado',
												accessor: 'nome',
											},
											{
												Header: 'Código',
												accessor: 'pk_ass',
												show: false
											}
										]}
										defaultSorted={[
											{
												id: 'nome',
												desc: false
											}
										]}
										defaultPageSize={10}
										className="-striped -highlight"
									/>
								</div>
							</div>
							<div className="divTabela" style={{ width: '48%', float: 'right'}}>
								<div style={{marginBottom: '10px' }}>
									Selecionados:
									<button 
										className="buttonVoltar" 
										style={{ float:'right' }}
										onClick={this.handleUnselectAll}
									>
										Limpar Seleção
									</button>
								</div>
								<div>
									<ReactTable
										data={this.state.selected}
										previousText="Anterior"
										nextText="Próximo"
										loadingText="Carregando..."
										pageText="Página"
										ofText="de"
										rowsText="registros"
										noDataText="Nenhum registro selecionado"
										getTdProps={(state, rowInfo, column, instance) => {
											return {
												onClick: (e, handleOriginal) => {
													this.handleClick(e, rowInfo.row.pk_ass, false)
												}
											}
										}}
										columns={[
											{
												Header: 'Associado',
												accessor: 'nome',
												// width: 420,
												// Cell: (row) => {
												// 	return (
												// 		<div>
												// 			<p
												// 				id={row.row.pk_ass}
												// 				onClick={(e)=>{this.handleClick(e, false)}}
												// 			>
												// 				{row.row.nome}
												// 			</p>
												// 		</div>
												// 	);
												// }
											},
											{
												Header: 'Código',
												accessor: 'pk_ass',
												show: false
											}
										]}
										defaultSorted={[
											{
												id: 'nome',
												desc: false
											}
										]}
										defaultPageSize={10}
										className="-striped -highlight"
									/>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Etiquetas;
