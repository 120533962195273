import React, { Component } from "react";
import config from "../config";
import NavBar from "../components/NavBar";
import "../css/Default.css";
import { Modal } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  dateSql,
  ordenar,
  asyncForEach,
  getParameterByName,
  populateForm,
  onBlurCurrency,
} from "../Utils";
import swal from "@sweetalert/with-react";
import { Icon } from "react-icons-kit";
import { edit } from "react-icons-kit/ionicons/edit";
import { iosSearchStrong } from "react-icons-kit/ionicons/iosSearchStrong";
import { ic_clear } from "react-icons-kit/md/ic_clear";
import { ic_email } from "react-icons-kit/md/ic_email";
import { coinDollar } from "react-icons-kit/icomoon/coinDollar";
import $ from "jquery";
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';

const inputParsers = {
  date(input) {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase(input) {
    return input.toUpperCase();
  },
  number(input) {
    return parseFloat(input);
  },
};

class Lista_Espera extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      combos: [],
      filter: [],
      modal: { show: false },
      edit: false,
      filterChanged: "",
      data: [],
    };
    this.getData = this.getData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleClean = this.handleClean.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.modalState = this.modalState.bind(this);
    this.handleSave = this.handleSave.bind(this);

  }


  populaLocal(item, id) {
    if (item.type === "local") {
      return (
        <option value={item.value} key={id}>
          {item.display}
        </option>
      );
    }
  }


  populaAss(item, id) {
    if (item.type === 'associado') {
      return (
        <option value={item.value} key={id}>
          {item.display}
        </option>
      );
    }
  }


  closeModal() {
    swal({
      dangerMode: true,
      title: "Atenção!",
      text: "Ao cancelar, todas as alterações serão descartadas. Confirma?",
      buttons: ["Sim", "Não"],
    }).then((result) => {
      if (!result) this.setState({ modal: { show: false } });
    });
  }

  async showModal(e, codigo) {
    e.preventDefault();
    let edicao = false;
    let pk = "0";
    if (Number(codigo) > 0) {
      edicao = true;
      pk = codigo;

      await fetch(
        config.protocol +
        "://" +
        config.server +
        ":" +
        config.portBackend +
        "/api/getLista_Espera?pk=" +
        Number(codigo).toString()
      )
        .then((r) => r.json())
        .then(async (r) => {
          if (typeof r[0] === "undefined") {
            window.location.href = "/lista_espera";
          } else {
            let form = document.getElementById("registroLista_Espera");
            console.log(r[0]);
            r[0].data = dateSql(r[0].data);
            await populateForm(form, r[0]);
          }
        });
    } else {
      edicao = false;
      document.getElementById("registroLista_Espera").reset();
    }
    this.setState({ modal: { show: true }, edit: edicao, codigo: pk });
  }


  modalState() {
    if (this.state.modal.show === true) {
      return "ModalShow";
    } else {
      return "ModalHide";
    }
  }

  handleSave(e) {
    e.preventDefault();
    swal({
      title: "Atenção!",
      text: this.state.edit
        ? "As alterações no registro serão salvas. Confirma?"
        : "O registro será incluído. Confirma?",
      buttons: ["Sim", "Não"],
    }).then((result) => {
      if (!result) this.submitData(e);
    });
  }


  submitData(e) {
    e.preventDefault();
    //Pega valores do form
    const form = document.getElementById("registroLista_Espera");
    const data = new FormData(form);

    //Trata valores conforme data-parse dos inputs
    for (let name of data.keys()) {
      const input = form.elements[name];

      // Rejeita type = undefined (radio inputs)
      if (typeof input.type !== "undefined") {
        const parserName = input.dataset.parse;
        if (parserName) {
          const parser = inputParsers[parserName];
          const parsedValue = parser(data.get(name));
          data.set(name, parsedValue);
        }
      }
    }

    //Converte FormData em JSON
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    object.dataTax = this.state.dataTax;
    var json = JSON.stringify(object);

    //Post no server
    if (this.state.edit) {
      //Editar
      console.log(json);
      fetch(
        config.protocol +
        "://" +
        config.server +
        ":" +
        config.portBackend +
        "/api/editLista_Espera?pk=" +
        this.state.codigo,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: json,
        }
      )
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          if (r.message === "Success!") {
            swal(
              "Alteração realizada",
              "Registro alterado com sucesso!",
              "success"
            ).then((result) => {
              this.setState({ modal: { show: false } });
              var event = new Event("build");
              this.getData(event);
            });
          } else {
            swal(
              "Alteração não realizada",
              "Registro não foi alterado. Verifique os campos.",
              "error"
            );
          }
        });
    } else {
      //Inserir
      fetch(
        config.protocol +
        "://" +
        config.server +
        ":" +
        config.portBackend +
        "/api/insertListaEspera",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: json,
        }
      )
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          if (r.message === "Success!") {
            swal(
              "Inclusão realizada",
              "Registro incluído com sucesso!",
              "success"
            ).then((result) => {
              this.setState({ modal: { show: false } });
              var event = new Event("build");
              this.getData(event);
            });
          } else {
            swal(
              "Inclusão não realizada",
              "Registro não foi incluído. Verifique os campos.",
              "error"
            );
          }
        });
    }
  }

  async componentDidMount() {
    //Carregar Parâmetros de pesquisa

    await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
      .then((r) => r.json())
      .then(async (r) => {
        let comboAss = this.state.combos;
        await asyncForEach(r, async (item) => {
          let associado = {
            type: 'associado',
            display: item.nome,
            value: item.pk_ass
          };
          await comboAss.push(associado);
        });
      });
    ordenar('comboAss')

    await fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getLocal"
    )
      .then((r) => r.json())
      .then(async (r) => {
        let comboLocal = this.state.combos;
        await asyncForEach(r, async (item) => {
          let local = {
            type: "local",
            display: item.descricao,
            value: item.pk_loc,
          };
          await comboLocal.push(local);
        });
      });
    ordenar("comboLocal");

    let query = {};
    query.filtered = true;
    getParameterByName("filtered");
    query.descricao = getParameterByName("descricao");

    this.setState({
      filter: query,
    });

    //Caso filtered=true, traz form já processado
    var event = new Event("build");
    if (query.filtered) this.getData(event);
  }

  handleDelete(e, pk) {
    e.preventDefault();

    swal({
      dangerMode: true,
      title: "Atenção!",
      text: "O registro selecionado será excluído! Confirma?",
      buttons: ["Sim", "Não"],
    }).then((result) => {
      if (!result) {
        //Delete
        fetch(
          config.protocol +
          "://" +
          config.server +
          ":" +
          config.portBackend +
          "/api/deleteLista_Espera?pk=" +
          pk,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({}),
          }
        )
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            if (r.message === "Success!") {
              swal(
                "Exclusão realizada",
                "Registro excluído com sucesso!",
                "success"
              ).then((result) => {
                //Caso filtered=true, traz form já processado
                var event = new Event("build");
                this.getData(event);
              });
            } else {
              swal(
                "Exclusão não realizada",
                "Registro não foi excluído.",
                "error"
              );
            }
          });
      }
    });

  }

  handleChange(e) {
    //Popula inputs do filtro
    e.preventDefault();
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;
    let reg = this.state.filter;
    reg[name] = value;
    this.setState({
      filter: reg,
    });
  }

  handleClean(e) {
    //Limpa o filtro
    e.preventDefault();
    console.log("limpa");
    window.history.replaceState(
      { filtered: false },
      "filter",
      "/apergs/lista_espera"
    ); //Apaga QueryURL
    this.setState({ filter: [] });
  }

  selectFile(e) {
    e.preventDefault();
    $("#html_btn").click();
  }

  async filterData(data) {
    //Filtra os dados
    return new Promise(async (resolve) => {
      let filter = this.state.filter;

      let queryString = "?";

      if (filter.fk_loc) {
        if (queryString === "?") {
          queryString = queryString + "fk_loc=" + filter.fk_loc;
        } else queryString = queryString + "&fk_loc=" + filter.fk_loc;
      }

      if (filter.fk_ass) {
        if (queryString === "?") {
          queryString = queryString + "fk_ass=" + filter.fk_ass;
        } else queryString = queryString + "&fk_ass=" + filter.fk_ass;
      }

      if (filter.fk_tpe) {
        if (queryString === "?") {
          queryString = queryString + "fk_tpe=" + filter.fk_tpe;
        } else queryString = queryString + "&fk_tpe=" + filter.fk_tpe;
      }

      if (filter.fk_sev) {
        if (queryString === "?") {
          queryString = queryString + "fk_sev=" + filter.fk_sev;
        } else queryString = queryString + "&fk_sev=" + filter.fk_sev;
      }
      if (filter.data_ini) {
        if (queryString === "?") {
          queryString = queryString + "data_ini=" + filter.data_ini;
        } else queryString = queryString + "&data_ini=" + filter.data_ini;
      }

      if (filter.data_fim) {
        if (queryString === "?") {
          queryString = queryString + "data_fim=" + filter.data_fim;
        } else queryString = queryString + "&data_fim=" + filter.data_fim;
      }
      //Monta Query URL
      if (queryString !== "?") {
        window.history.replaceState(
          { filtered: true },
          "filter",
          "/apergs/lista_espera" + queryString + "&filtered=true"
        );
      } else {
        window.history.replaceState(
          { filtered: true },
          "filter",
          "/apergs/lista_espera?filtered=true"
        );
      }

      // resolve(filtered);
      resolve(queryString);
    });
  }

  async getData(e) {
    e.preventDefault();
    let queryString = await this.filterData();
    await fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getLista_Espera" +
      queryString
    )
      .then((r) => r.json())
      .then(async (r) => {
        console.log(r);
        this.setState({ data: r });
      });
  }

  async getDataTax(e, codigo) {
    e.preventDefault();

    await fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getLista_Espera?pk=" +
      codigo
    )
      .then((r) => r.json())
      .then(async (r) => {
        this.setState({ dataTax: r, total: r[0].total.replace(".", "").replace(",", ".") });
        console.log(r[0])
      })
  }

  render() {
    let local = this.state.combos.map(this.populaLocal);
    let associado = this.state.combos.map(this.populaAss);

    return (
      <div className="boxSite colorSettings">
        {/***************** Barra de Navegação *******************/}
        <div className="boxNavBar">
          <NavBar selected="GrauParentesco" />
        </div>
        {/***************** Tela do WebSite *******************/}
        <div className="boxTela">
          {/*********************** Header ***********************/}
          <div className="boxHeader">
            <h3 className="headerCadastro">
              Cadastro de Lista de Espera de Reservas - Sede Social
            </h3>
          </div>
          {/*********************** Filtros ***********************/}
          <form className="boxFiltros">
            {/* Parâmetros de pesquisa:<br/> */}
            <div className="filtros">
              <div className="column-filter">
                <div className="itemFiltro">
                  <label className="labelFiltro">Local</label>
                  <select
                    name="fk_loc"
                    id="comboLocal"
                    className="selectFiltro"
                    value={this.state.filter.fk_loc || ""}
                    onChange={this.handleChange}
                    style={{ width: "200px" }}
                  >
                    {local}
                    <option value="">TODOS</option>
                  </select>
                </div>

                <div className="itemFiltro">
                  <label className="labelFiltro">Período</label>
                  <input
                    name="data_ini"
                    type="date"
                    id="filtroDataIni"
                    className="inputFiltro"
                    style={{ width: "10vw" }}
                    value={this.state.filter.data_ini || ""}
                    onChange={this.handleChange}
                  />

                  <input
                    name="data_fim"
                    type="date"
                    id="filtroDataFim"
                    className="inputFiltro"
                    style={{ width: "10vw" }}
                    value={this.state.filter.data_fim || ""}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="itemFiltro">
                  <label className="labelFiltro">Associado</label>
                  <select
                    name="fk_ass"
                    id="comboAss"
                    className="selectFiltro"
                    value={this.state.filter.fk_ass || ''}
                    onChange={this.handleChange}
                    style={{ width: '300px' }}
                  >
                    {associado}
                    <option value="">TODOS</option>

                  </select>
                </div>


              </div>
              <br />
              <div className="column-filter-2">
                <button
                  className="buttonFiltroProcessar"
                  onClick={this.getData}
                >
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={iosSearchStrong}
                  />
                  Processar
                </button>
                <button
                  className="buttonFiltroLimpar"
                  onClick={this.handleClean}
                >
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={ic_clear}
                  />
                  Limpar
                </button>
                {/* <button className="buttonNovo" onClick={this.showModal}>
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={ic_add_circle}
                  />
                  Novo Registro
                </button> */}

              </div>
            </div>
          </form>
          {/*********************** Tabela ***********************/}
          <div style={{ paddingBottom: "30px" }}>
            <div className={this.modalState()} tabIndex="-1">
              <Modal.Dialog className="Modal">
                <div>
                  <Modal.Header className="ModalBg">
                    <div className="ModalHeader">
                      <h3 className="headerModal">Edição de Lista de Espera</h3>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="ModalBg">
                    <div className="ModalBody">
                      <form
                        id="registroReservas"
                        name="registroReservas"
                        onSubmit={this.submitData}
                      >
                        <div>

                          <label className="labelModal">Associado</label>
                          <input
                            type="text"
                            id="nomeass"
                            name="nomeass"
                            className="form-control"
                            data-parse="uppercase"
                            disabled
                          />
                        </div>

                        <div class="row">

                          <div className="col-sm-2" style={{ width: "200px" }}>

                            <label className="labelModal">Fone Contato</label>
                            <input
                              type="text"
                              id="fone_contato"
                              name="fone_contato"
                              className="form-control"
                              data-parse="uppercase"
                              
                            />
                          </div>

                          <div className="col-sm-2" style={{ width: "200px" }}>

                            <label className="labelModal">Email Contato</label>
                            <input
                              type="text"
                              id="email_contato"
                              name="email_contato"
                              className="form-control"
                              data-parse="uppercase"
                              
                            />
                          </div>


                        </div>
                        <div class="row">
                          <div className="col-sm-2" style={{ width: "200px" }}>
                            <label className="labelModal">Data</label>
                            <input
                              type="date"
                              id="data"
                              name="data"
                              className="form-control"
                              disabled
                            />

                          </div>

                          <div className="col-sm-2" style={{ width: "100px" }}>
                            <label className="labelModal">Turno</label>
                            <select
                              data-parse="uppercase"
                              id="turno"
                              name="turno"
                              className="form-control"
                              style={{ width: '100px' }}
                              disabled
                            >
                              <option value="M">Manhã</option>
                              <option value="T">Tarde</option>
                              <option value="N">Noite</option>

                            </select>
                          </div>

                          <div className="col-sm-2" style={{ width: "250px" }}>
                            <label className="labelModal">Local</label>
                            <input
                              type="text"
                              id="nomeloc"
                              name="nomeloc"
                              className="form-control"
                              data-parse="uppercase"
                              disabled
                            />
                          </div>

                          <div className="col-sm-2" style={{ width: "100px" }}>
                            <label className="labelModal">Ativo</label>
                            <select
                              data-parse="uppercase"
                              id="ativa"
                              name="ativao"
                              className="form-control"
                              style={{ width: '100px' }}
                            >
                              <option value="S">Sim</option>
                              <option value="N">Não</option>
                              
                            </select>
                          </div>

                        </div>


                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="ModalBg">
                    <div className="ModalFooter">
                      <button className="ModalButton" onClick={this.handleSave}>
                        Salvar
                      </button>
                      <button className="ModalButton" onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </div>
                  </Modal.Footer>
                </div>
              </Modal.Dialog>
            </div>


            <div
              style={{
                marginLeft: "30px",
                marginTop: "30px",
                marginRight: "30px",
              }}
            >
              <div className="divTabela">
                <ReactTable
                  data={this.state.data}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando..."
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  noDataText="Nenhum registro encontrado"
                  columns={[
                    {
                      Header: "",
                      width: 70,
                      Cell: (row) => {
                        return (
                          <div className="">
                            <Icon
                              size={20}
                              icon={edit}
                              className="iconButton"
                              onClick={(e) => {
                                this.showModal(e, row.row.pk_rle);
                              }}
                            />

                            <Icon size={20} icon={iosTrash} className="iconButtonRed"

                              onClick={(e) => {
                                this.handleDelete(e, row.row.pk_rle);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                    {
                      Header: "Código",
                      accessor: "pk_rle",
                      minWidth: 100,
                      show: false,
                    },

                    {
                      Header: "Data",
                      accessor: "datarle",
                      minWidth: 40,
                    },

                    {
                      Header: "Data Entrada na Lista de Espera",
                      accessor: "datamovrle",
                      minWidth: 80,
                    },

                    {
                      Header: "Local",
                      accessor: "nomeloc",
                      minWidth: 80,
                    },

                    {
                      Header: "Associado",
                      accessor: "nomeass",
                      minWidth: 200,
                    },

                    {
                      Header: "Ativo",
                      accessor: "strAtivo",
                      minWidth: 50,

                    },


                  ]}
                  defaultSorted={[
                    {
                      id: "data",
                      desc: false,
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Lista_Espera;
