import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import './css/Default.css';
import './css/Table.css';
import 'semantic-ui-css/semantic.min.css';
import Associados from './pages/Associados';
import RegistroAssociados from './pages/RegistroAssociados';
import Cidades from './pages/Cidades';
import Estados from './pages/Estados';
import Paises from './pages/Paises';
import Parametros from './pages/Parametros';
import Situacoes from './pages/Situacoes';
import Lotacoes from './pages/Lotacoes';
import Usuarios from './pages/Usuarios';
import Advogados from './pages/Advogados';
import Home from './pages/Home';
import Etiquetas from './pages/Etiquetas';
import CategoriasAssociados from './pages/CategoriasAssociados';
import GerarMensalidades from './pages/GerarMensalidades';
import GrauParentesco from './pages/GrauParentesco';
import Bancos from './pages/Bancos';
import TiposServicos from './pages/TiposServicos';
import Login from './pages/Login';
import Unimed from './pages/Unimed';
import Criticas from './pages/Criticas';
import Telefonia from './pages/Telefonia';
import ImportacaoDB from './pages/ImportacaoDB';
import ContasReceber from './pages/ContasReceber';
import RegistroContasReceber from './pages/RegistroContasReceber';
import RemessasTesouro from './pages/RemessasTesouro';
import RegistroRemessasTesouro from './pages/RegistroRemessasTesouro';
import RemessasBanrisul from './pages/RemessasBanrisul';
import RegistroRemessasBanrisul from './pages/RegistroRemessasBanrisul';
import RetornosBanrisul from './pages/RetornosBanrisul';
import Demonstrativos from './pages/Demonstrativos';
import VisualizarDemonstrativo from './pages/VisualizarDemonstrativo';
import DemonstrativosIR from './pages/DemonstrativosIR';
import VisualizarDemonstrativoIR from './pages/VisualizarDemonstrativoIR';
import Classes from './pages/Classes';
import Subclasses from './pages/Subclasses';
import Fornecedores from './pages/Fornecedores';
import RegistroFornecedores from './pages/RegistroFornecedores';
import ContasPagar from './pages/ContasPagar';
import RegistroContasPagar from './pages/RegistroContasPagar';
import LiquidarContasPagar from './pages/LiquidarContasPagar';
import ReabrirContasPagar from './pages/ReabrirContasPagar';
import RelatorioCobrancaServico from './pages/RelatorioCobrancaServico';
import TiposAcoes from './pages/TiposAcoes';
import RelatorioDebitosTesouro from './pages/RelatorioDebitosTesouro';
import RelatorioDebitosBanrisul from './pages/RelatorioDebitosBanrisul';
import RelatorioAcoesAndamento from './pages/RelatorioAcoesAndamento';
import RelatorioContasEmAbertoPorCompetenciaAssociado from './pages/RelatorioContasEmAbertoPorCompetenciaAssociado';
import { version } from '../package.json';
import Status_Eventos from './pages/Status_Eventos';
import Eventos from './pages/Eventos';
import Reservas from './pages/Reservas';
import Taxas from './pages/Taxas';
import Tipos_Itens_Eventos from './pages/Tipos_Itens_Eventos';
import Locais from './pages/Locais';
import Tipo_Evento from './pages/Tipo_Evento';
import Materiais from './pages/Materiais';
import Situacao_Reserva from './pages/Situacao_Reserva';
import Lista_Espera from './pages/Lista_Espera';
import Informativos from './pages/Informativos';
import Arquivos from './pages/Arquivos';
import Tipo_Locacao from './pages/Tipo_Locacao';
import Normativas from './pages/Normativas';
import RegistroNormativas from './pages/RegistroNormativas';

// export const versao = version


class App extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route exact path="/apergs/login" component={Login} />
					<ProtectedRoute path="/apergs/home" component={Home} />
					<ProtectedRoute exact path="/apergs/associados" component={Associados} />
					<ProtectedRoute exact path="/apergs/etiquetas" component={Etiquetas} />
					<ProtectedRoute exact path="/apergs/normativas" component={Normativas} />
					<ProtectedRoute exact path="/apergs/associados/registro" component={RegistroAssociados} />
					<ProtectedRoute exact path="/apergs/cidades" component={Cidades} />
					<ProtectedRoute exact path="/apergs/classes" component={Classes} />
					<ProtectedRoute exact path="/apergs/subclasses" component={Subclasses} />
					<ProtectedRoute exact path="/apergs/fornecedores" component={Fornecedores} />
					<ProtectedRoute exact path="/apergs/fornecedores/registro" component={RegistroFornecedores} />
					<ProtectedRoute exact path="/apergs/criticas-tesouro" component={Criticas} />
					<ProtectedRoute exact path="/apergs/remessas-tesouro" component={RemessasTesouro} />
					<ProtectedRoute exact path="/apergs/remessas-tesouro/registro" component={RegistroRemessasTesouro} />
					<ProtectedRoute exact path="/apergs/retornos-banrisul" component={RetornosBanrisul} />
					<ProtectedRoute exact path="/apergs/remessas-banrisul" component={RemessasBanrisul} />
					<ProtectedRoute exact path="/apergs/remessas-banrisul/registro" component={RegistroRemessasBanrisul} />
					<ProtectedRoute exact path="/apergs/estados" component={Estados} />
					<ProtectedRoute exact path="/apergs/paises" component={Paises} />
					<ProtectedRoute exact path="/apergs/parametros" component={Parametros} />
					<ProtectedRoute exact path="/apergs/lotacoes" component={Lotacoes} />
					<ProtectedRoute exact path="/apergs/categorias-associados" component={CategoriasAssociados} />
					<ProtectedRoute exact path="/apergs/grau-parentesco" component={GrauParentesco} />
					<ProtectedRoute exact path="/apergs/bancos" component={Bancos} />
					<ProtectedRoute exact path="/apergs/usuarios" component={Usuarios} />
					<ProtectedRoute exact path="/apergs/tipos-servicos" component={TiposServicos} />
					<ProtectedRoute exact path="/apergs/advogados" component={Advogados} />
					<ProtectedRoute exact path="/apergs/gerar-mensalidades" component={GerarMensalidades} />
					<ProtectedRoute exact path="/apergs/importacao-db" component={ImportacaoDB} />
					<ProtectedRoute exact path="/apergs/unimed" component={Unimed} />
					<ProtectedRoute exact path="/apergs/situacoes" component={Situacoes} />
					<ProtectedRoute exact path="/apergs/telefonia" component={Telefonia} />
					<ProtectedRoute exact path="/apergs/demonstrativos/visualizar" component={VisualizarDemonstrativo} />
					<ProtectedRoute exact path="/apergs/demonstrativos" component={Demonstrativos} />
					<ProtectedRoute exact path="/apergs/demonstrativosIR" component={DemonstrativosIR} />
					<ProtectedRoute exact path="/apergs/demonstrativosIR/visualizar" component={VisualizarDemonstrativoIR} />
					<ProtectedRoute exact path="/apergs/contas-receber" component={ContasReceber} />
					<ProtectedRoute exact path="/apergs/contas-receber/registro" component={RegistroContasReceber} />
					<ProtectedRoute exact path="/apergs/contas-pagar" component={ContasPagar} />
					<ProtectedRoute exact path="/apergs/contas-pagar/registro" component={RegistroContasPagar} />
					<ProtectedRoute exact path="/apergs/contas-pagar/liquidar" component={LiquidarContasPagar} />
					<ProtectedRoute exact path="/apergs/contas-pagar/reabrir" component={ReabrirContasPagar} />
					<ProtectedRoute exact path="/apergs/tipos-acoes/" component={TiposAcoes} />
					<ProtectedRoute exact path="/apergs/relatorio/cobranca-servicos" component={RelatorioCobrancaServico} />
					<ProtectedRoute exact path="/apergs/relatorio/acoes-andamento" component={RelatorioAcoesAndamento} />
					<ProtectedRoute exact path="/apergs/relatorio/debitos/tesouro" component={RelatorioDebitosTesouro} />
					<ProtectedRoute exact path="/apergs/normativas/registro" component={RegistroNormativas} />
					<ProtectedRoute exact path="/apergs/relatorio/debitos/banrisul" component={RelatorioDebitosBanrisul} />
					<ProtectedRoute exact path="/apergs/relatorio/relacao-contas-aberto" component={RelatorioContasEmAbertoPorCompetenciaAssociado} />
					<ProtectedRoute exact path="/apergs/status_eventos" component={Status_Eventos} />
					<ProtectedRoute exact path="/apergs/eventos" component={Eventos} />
					<ProtectedRoute exact path="/apergs/reservas" component={Reservas} />
					<ProtectedRoute exact path="/apergs/taxas" component={Taxas} />
					<ProtectedRoute exact path="/apergs/tipos_itens_eventos" component={Tipos_Itens_Eventos} />
					<ProtectedRoute exact path="/apergs/locais" component={Locais} />
					<ProtectedRoute exact path="/apergs/tipo_evento" component={Tipo_Evento} />
					<ProtectedRoute exact path="/apergs/materiais" component={Materiais} />
					<ProtectedRoute exact path="/apergs/lista_espera" component={Lista_Espera} />
					<ProtectedRoute exact path="/apergs/situacao_reserva" component={Situacao_Reserva} />
					<ProtectedRoute exact path="/apergs/informativos" component={Informativos} />
					<ProtectedRoute exact path="/apergs/arquivos" component={Arquivos} />
					<ProtectedRoute exact path="/apergs/tipo_locacao" component={Tipo_Locacao} />


					<ProtectedRoute component={Home} />
				</Switch>
			</Router>
		);
	}
}

export default App;
