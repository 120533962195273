import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import { populateForm, asyncForEach } from '../Utils';
import swal from '@sweetalert/with-react';

class RelatorioAcoesAndamento extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			relatorio: '',
			relatorioStats: { message: 'Not started', info: [] }
		};

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		const form = document.getElementById('gerarMensalidade');
		const data = new FormData(form);

		let tipoAcao = data.get('tipoAcao');

		swal({
			title: 'Atenção!',
			text: 'Deseja gerar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=acoesAndamento'+( Number(tipoAcao) > 0 ? '&tipoAcao='+tipoAcao : ''))
				.then((r) => r.json())
				.then(async (r) => {
					if (r.status === 'success') {
						this.setState({ relatorio: ''})
						this.setState({ relatorio: config.protocol + '://' + config.server + ':' + config.portBackend+'/static/relatorios/acoesAndamento/acoesAndamento_'+tipoAcao+'.pdf'})
					} else this.setState({ relatorio: ''})
					
				})
				
			}
			
		});
		
	}


	async componentDidMount() {
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let form = document.getElementById('gerarMensalidade');
					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTiposAcoes')
					.then((r) => r.json())
					.then(async (r) => {
						let combosTiposAcoes = this.state.combos;
						await asyncForEach(r, async (item) => {
							let tiposAcoes = {
								type: 'tiposAcoes',
								display: item.descricao,
								value: item.pk_obj
							};
							await combosTiposAcoes.push(tiposAcoes);
						});
						this.setState({ combos: combosTiposAcoes });
					});

					populateForm(form, r[0]);
				}
			});
	}

	populaTiposAcoes(item, id) {
		if (item.type === 'tiposAcoes') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}		
	}


	render() {
		let tiposAcoes = this.state.combos.map(this.populaTiposAcoes);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Relatório - Processos/Ações em Andamento</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="gerarMensalidade" name="gerarMensalidade" onSubmit={this.submitData}>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="tipoAcao">Tipo de Ação:</label>
									<select name="tipoAcao" className="form-control">
										<option value="">Todos</option>
										{tiposAcoes}
									</select>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar
							</button>
						</div>
					</div>
					<div className="boxRelatorio" hidden={this.state.relatorio === ''}>
						<iframe className="relatorio" title="Relatório" src={this.state.relatorio}></iframe>
					</div>
				</div>
			</div>
		);
	}
}

export default RelatorioAcoesAndamento;
