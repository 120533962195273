import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import { populateForm, asyncForEach } from '../Utils';
import swal from '@sweetalert/with-react';
// import $ from 'jquery'

class RelatorioDebitosBanrisul extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			relatorio: '',
			relatorioStats: { message: 'Not started', info: [] }
		};

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		const form = document.getElementById('gerarMensalidade');
		const data = new FormData(form);

		let competencia = '';
		let competencia_ano = '';
		let competencia_mes = '';

		if (Number(data.get('competencia_mes') < 10)) {
			competencia_mes = String('0' + data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		} else {
			competencia_mes = String(data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		}

		let strSituacao = document.getElementById('situacao').options[document.getElementById('situacao').selectedIndex].text;

		let querySituacao = (data.get('situacao') !== '' ? '&situacao='+data.get('situacao') : '')



		swal({
			title: 'Atenção!',
			text: 'Deseja gerar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=debitosBanrisul&competencia='+competencia+querySituacao+'&strSituacao='+strSituacao)
				.then((r) => r.json())
				.then(async (r) => {
					if (r.status === 'success') {
						this.setState({ relatorio: ''})
						this.setState({ relatorio: config.protocol + '://' + config.server + ':' + config.portBackend+'/static/relatorios/debitosBanrisul/debitosBanrisul_'+competencia+'.pdf'})
					} else this.setState({ relatorio: ''})
					
				})
				
			}
			
		});
		
	}

	populaSituacoes(item, id) {
		if (item.type === 'situacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}		
	}


	async componentDidMount() {
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let form = document.getElementById('gerarMensalidade');
					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSituacoes')
					.then((r) => r.json())
					.then(async (r) => {
						let combosSituacoes = this.state.combos;
						await asyncForEach(r, async (item) => {
							let situacao = {
								type: 'situacao',
								display: item.descricao,
								value: item.pk_sit
							};
							await combosSituacoes.push(situacao);
						});
						this.setState({ combos: combosSituacoes });
					});
					r[0].competencia_mes = Number(r[0].competencia.substring(0, 2));
					r[0].competencia_ano = Number(r[0].competencia.substring(2, 4));
					console.log(r[0].competencia_mes, r[0].competencia_ano);
					r[0].valor_anape = r[0].valor_anape.toFixed(2).replace('.', ',');
					populateForm(form, r[0]);
				}
			});
	}

	populaTiposAcoes(item, id) {
		if (item.type === 'tiposAcoes') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}		
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}


	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Relatório de Débitos - Banrisul</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="gerarMensalidade" name="gerarMensalidade" onSubmit={this.submitData}>
								<div className="col-sm-12" style={{ marginTop: '15px', marginBottom: '15px' }}>
									<label>Competência/Débito:</label>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_mes">Mês:</label>
									<select name="competencia_mes" className="form-control">
										<option value="">Selecione</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_ano">Ano:</label>
									<select name="competencia_ano" className="form-control">
										<option value="">Selecione</option>
										{anos}
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
								<label htmlFor="situacao">Situação</label>
									<select
										name="situacao"
										id="situacao"
										className="form-control"
									>
										<option value="">TODOS</option>
										<option value="N">DÉBITOS NÃO REALIZADOS</option>
										<option value="R">DÉBITOS REALIZADOS</option>
									</select>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar
							</button>
						</div>
					</div>
					<div className="boxRelatorio" hidden={this.state.relatorio === ''}>
						<iframe className="relatorio" title="Relatório" src={this.state.relatorio}></iframe>
					</div>
				</div>
			</div>
		);
	}
}

export default RelatorioDebitosBanrisul;
