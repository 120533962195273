import React, { Component } from 'react';
import NavBar from '../components/NavBar'
import '../css/Default.css';
import "react-table/react-table.css";
// import {LinkContainer} from 'react-router-bootstrap'
import { NavLink } from 'react-router-dom';
import associados from '../imgs/associados.png'
import contas_receber from '../imgs/contas_receber.png'
import apergs from '../imgs/ApergsPNG.png'


class Home extends Component {
    render() {
        return (
            
            <div className="boxSite colorSettings">
                {/***************** Barra de Navegação *******************/}
                <div className="boxNavBar">
                    <NavBar/>
                </div>
                {/***************** Tela do WebSite *******************/}
                <div className="boxTela">
                    {/*********************** Header ***********************/}
                    <div className="boxHeader">
                        {/* <h3 className="headerCadastro">Página Inicial</h3> */}
                    </div>
                    {/*********************** Filtros ***********************/}
                    <div style={{minHeight: '80vh'}}>
                        <div style={{ width: '80vw', marginLeft: '30px', marginRight: '30px'}}>
                        
                            <div className="buttonsHome">
                                <NavLink to="/apergs/associados">
                                
                                    <button className="buttonHomeDiv">
                                        <img alt="Associados" src={associados} className="buttonHome" title="Associados"/>
                                        <br/>
                                        Associados
                                    </button>
                                </NavLink>
                                <NavLink to="/apergs/contas-receber">
                                    <button className="buttonHomeDiv">
                                        {/* style={{height: '15vh', display:"block", width: 'auto'}} */}
                                        <img alt="Contas a Receber" src={contas_receber} className="buttonHome" title="Contas a Receber"/>
                                        <br/>
                                        Contas a Receber
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <img alt="Apergs" src={apergs} title="Apergs" className="logoHome"/>
                        </div>
                    </div>
                    
                    {/*********************** Tabela ***********************/}

                    {/*********************** Rodapé ***********************/}
                    
                </div>
            </div>
        );
    }
}

export default Home;
