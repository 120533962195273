import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { dateSql, ordenar, asyncForEach, getParameterByName, populateForm, onBlurCurrency } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/ionicons/edit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import $ from 'jquery';
import axios from 'axios';
import { ic_do_not_disturb_alt } from 'react-icons-kit/md/ic_do_not_disturb_alt'
import { ic_photo } from 'react-icons-kit/md/ic_photo'

const inputParsers = {
	date(input) {
		const [month, day, year] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class Eventos extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			modal: { show: false, confirmacoes: false, editaIev: false, showIev: false, showLotacao: false },
			edit: false,
			filterChanged: '',
			data: [],
			arquivo: {},
			dataIev: [],
			dataPev: [],
			arquivoIev: {},
			selected: [],
			selectedData: [],
			selectedDataLot: [],
			dataLot: [],



		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleDeleteIev = this.handleDeleteIev.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.showModal = this.showModal.bind(this);
		this.confirmacoes = this.confirmacoes.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.closeModalConfirmacoes = this.closeModalConfirmacoes.bind(this);
		this.closeModalEditaIev = this.closeModalEditaIev.bind(this);
		this.closeModalIev = this.closeModalIev.bind(this);
		this.closeModalLotacao = this.closeModalLotacao.bind(this);
		this.modalState = this.modalState.bind(this);
		this.classCancelado = this.classCancelado.bind(this);
		this.modalStateConfirmacoes = this.modalStateConfirmacoes.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSelectedFile = this.handleSelectedFile.bind(this)
		this.getDataIev = this.getDataIev.bind(this);
		this.insertIev = this.insertIev.bind(this);
		this.limpaIev = this.limpaIev.bind(this);
		this.getDataPev = this.getDataPev.bind(this);
		this.handleSelectedFileFolder = this.handleSelectedFileFolder.bind(this)
		this.showArquivo = this.showArquivo.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.toggleSelectRow = this.toggleSelectRow.bind(this);
		this.selectAllLot = this.selectAllLot.bind(this);
		this.selectRowLot = this.selectRowLot.bind(this);
		this.enviarConvites = this.enviarConvites.bind(this);
		this.editaIev = this.editaIev.bind(this);
		this.showModalIev = this.showModalIev.bind(this);
		this.showModalLotacao = this.showModalLotacao.bind(this);


	}


	toggleSelectAll(e) {
		if (!e.target.checked) {
			this.setState({
				selectedData: [],

			})
		} else {
			let arrayOfPks = this.state.data.map((item) => { return { codigo: item.pk_eve } })
			this.setState({
				selectedData: arrayOfPks,
			})
		}
	}

	toggleSelectRow(e, codigo, valor) {
		let { selectedData } = this.state
		if (!e.target.checked) {
			const index = selectedData.map((item) => item.codigo).indexOf(codigo);
			if (index > -1) {
				selectedData.splice(index, 1);
			}
			this.setState({
				...selectedData,

			})
		} else {
			selectedData.push({ codigo })
			this.setState({
				...selectedData,
			})
		}
	}


	selectAllLot(e) {
		if (!e.target.checked) {
			this.setState({
				selectedDataLot: [],

			})
		} else {
			let arrayOfPks = this.state.dataLot.map((item) => { return { codigo: item.pk_lot } })
			this.setState({
				selectedDataLot: arrayOfPks,
			})
		}
	}

	selectRowLot(e, codigo) {
		let { selectedDataLot } = this.state
		if (!e.target.checked) {
			const index = selectedDataLot.map((item) => item.codigo).indexOf(codigo);
			if (index > -1) {
				selectedDataLot.splice(index, 1);
			}
			this.setState({
				...selectedDataLot,

			})
		} else {
			selectedDataLot.push({ codigo })
			this.setState({
				...selectedDataLot,
			})
		}
	}


	async handleSelectedFile(e) {
		$('#arquivo').val(true);
		let file = e.target.files[0];
		let data = new FormData();
		data.append('arquivo', file, file.name);
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivoEvento',
			data
		);
		this.setState({
			arquivo: { name: file.name }
		})

	}

	async handleSelectedFileFolder(e) {
		$('#arquivoIev').val(true);
		let file = e.target.files[0];
		let data = new FormData();
		data.append('arquivoiev', file, file.name);
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivoIev',
			data
		);
		this.setState({
			arquivoIev: { name: file.name }
		})

	}

	populaTpe(item, id) {
		if (item.type === 'tipoEvento') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaSev(item, id) {
		if (item.type === 'statusEvento') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}


	populaTie(item, id) {
		if (item.type === 'itensEvento') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	closeModal() {
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao cancelar, todas as alterações serão descartadas. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) this.setState({ modal: { show: false } });
		});
	}

	closeModalConfirmacoes() {
		this.setState({ modal: { show: false, confirmacoes: false } });
	}

	closeModalEditaIev() {
		this.setState({ modal: { show: false, editaIev: false } });
	}

	closeModalIev() {
		this.setState({ modal: { showIev: false }, arquivoIev: {} });
	}

	closeModalLotacao() {
		this.setState({ modal: { showLotacao: false } });
	}

	async showArquivo(e, arquivo) {
		e.preventDefault();
		window.open(config.protocol + '://' + config.server + ':' + config.portBackend + '/static/eventos/itens_eventos/' + arquivo, '_blank')
	}


	async enviarConvites(e, iev) {
		e.preventDefault();

		if (iev > 0) {
			await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/enviarConvites?pk_iev=' + iev)
				.then((r) => {
					if (r.data.message === 'Sucesso!') {
						swal('E-mails Enviados', 'E-mails enviados com sucesso!', 'success')
					}
				})

		} else
			if (this.state.selectedData.length > 0) {
				await axios.post(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/enviarConvites?pk_lot=' + JSON.stringify(this.state.selectedDataLot), this.state.selectedData)
					.then((r) => {
						if (r.data.message === 'Sucesso!') {
							swal('E-mails Enviados', 'E-mails enviados com sucesso!', 'success')
						}
					})
			} else {
				swal('Nenhum evento selecionado!', 'Nenhum evento foi selecionado. Selecione pelo menos um evento para enviar os convites!', 'error');
			}

		this.setState({ modal: { showLotacao: false } });
	}

	async showModal(e, codigo) {
		e.preventDefault();
		let edicao = false;
		let pk = '0';
		if (Number(codigo) > 0) {
			edicao = true;
			pk = codigo;
			this.getDataIev(e, codigo)

			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getEventos?pk=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/eventos';
					} else {
						let form = document.getElementById('registroEventos');
						console.log(r[0]);
						r[0].auxdata = dateSql(r[0].data);
						r[0].auxdatalimite = dateSql(r[0].data_limite_cancelamento);

						this.setState({
							arquivo: { name: r[0].arquivo }
						})
						await populateForm(form, r[0]);
					}
				}

				);
		} else {
			edicao = false;
			document.getElementById('registroEventos').reset();
		}
		this.setState({ modal: { show: true }, edit: edicao, codigo: pk });
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	classCancelado(cancelado) {
		console.log(cancelado)
		if (cancelado == 'S') {
			return 'divCancelado';
		} else {
			return '';
		}
	}

	modalStateConfirmacoes() {
		if (this.state.modal.confirmacoes === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalStateEditaIev() {
		if (this.state.modal.editaIev === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalStateShowIev() {
		if (this.state.modal.showIev === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalStateShowLotacao() {
		if (this.state.modal.showLotacao === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	async confirmacoes(e, codigo) {
		e.preventDefault();
		let pk = '0';
		if (Number(codigo) > 0) {
			pk = codigo;
			this.getDataPev(e, codigo)

			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getEventos?pk=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/eventos';
					} else {
						let form = document.getElementById('registroConfirmacoes');
						console.log(r[0]);
						r[0].auxdata = dateSql(r[0].data);
						await populateForm(form, r[0]);
					}
				}

				);
		} else {

			document.getElementById('registroConfirmacoes').reset();
		}
		this.setState({ modal: { confirmacoes: true }, codigo: pk });
	}


	async editaIev(e, codigo) {
		e.preventDefault();
		let pk = '0';
		if (Number(codigo) > 0) {
			pk = codigo;
			this.getDataIev(e, codigo)

			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getEventos?pk=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/eventos';
					} else {
						let form = document.getElementById('editaIev');
						await populateForm(form, r[0]);
					}
				}

				);

		} else {

			document.getElementById('editaIev').reset();
		}
		this.setState({ modal: { editaIev: true }, codigo: pk });
	}


	async showModalIev(e, codigo) {
		e.preventDefault();
		let pk = '0';
		if (Number(codigo) > 0) {
			pk = codigo;

			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getIev?pk_iev=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/eventos';
					} else {
						this.setState({
							arquivoIev: { name: r[0].folder }
						})

						let form = document.getElementById('showIev');
						await populateForm(form, r[0]);
					}
				}

				);

		} else {

			document.getElementById('showIev').reset();
		}
		this.setState({ modal: { showIev: true }, codigo: pk });
	}

	async showModalLotacao(e) {
		e.preventDefault();
		await fetch(
			config.protocol +
			'://' +
			config.server +
			':' +
			config.portBackend +
			'/api/getLotacoes')
			.then((r) => r.json())
			.then(async (r) => {
				if (r.length > 0)
					this.setState({
						dataLot: r
					})

			})

		this.setState({ modal: { showLotacao: true } });
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: this.state.edit
				? 'As alterações no registro serão salvas. Confirma?'
				: 'O registro será incluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	async insertIev(e) {
		e.preventDefault();
		const form = document.getElementById('registroEventos');
		const data = new FormData(form);
		let fk_tie = ''
		let valor = ''
		let nometie = ''
		let participacao = ''
		let folder = this.state.arquivoIev.name

		for (let name of data.keys()) {
			const input = form.elements[name];

			console.log('name:' + name)

			const parserName = input.dataset.parse;
			if (parserName) {
				const parser = inputParsers[parserName];
				const parsedValue = parser(data.get(name));

				console.log('value:' + parsedValue)

				if (name == 'fk_tie') {
					fk_tie = parsedValue

					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTipos_Itens_Eventos?pk=' + fk_tie)
						.then((r) => r.json())
						.then(async (r) => {

							nometie = r[0].descricao

							console.log(nometie)
						});

				}
				if (name == 'valor')
					valor = parsedValue


				if (name == 'participacao')
					participacao = parsedValue + '%'

				data.set(name, parsedValue);
			}

		}

		let iev = this.state.dataIev

		iev.push({
			nometie: nometie,
			fk_tie: fk_tie,
			valor: valor,
			participacao: participacao,
			folder: folder
		});

		this.setState({ dataIev: iev });

		// this.state.dataIev.push(iev)

		console.log(this.state.dataIev)
	}


	async limpaIev(e) {
		e.preventDefault();
		this.setState({ dataIev: [] });
	}


	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroEventos');
		const data = new FormData(form);

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			// Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				data.set('arquivo', this.state.arquivo.name);
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function (value, key) {
			object[key] = value;
		});
		object.dataIev = this.state.dataIev
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/editEventos?pk=' +
				this.state.codigo,
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							this.setState({ modal: { show: false } });
							var event = new Event('build');
							this.getData(event);
						});


					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoEventos', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							this.setState({ modal: { show: false } });
							var event = new Event('build');
							this.getData(event);
						});


					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}


	async submitIev(e, arquivo) {
		e.preventDefault();
		//Pega valores do form
		let pk_iev = 0
		const form = document.getElementById('showIev');
		const data = new FormData(form);
		data.set('folder', arquivo)
		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			// Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
					if (name == 'pk_iev') {
						pk_iev = parsedValue
					}
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function (value, key) {
			object[key] = value;
		});

		var json = JSON.stringify(object);
		console.log(json)

		fetch(
			config.protocol +
			'://' +
			config.server +
			':' +
			config.portBackend +
			'/api/editIev?pk=' +
			1,
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			}
		)
			.then((r) => r.json())
			.then((r) => {
				console.log(r);
				if (r.message === 'Success!') {
					swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
						this.closeModalIev()

						if (this.state.arquivoIev.name !== '') {
							swal({
								title: 'Atenção!',
								text: 'Deseja enviar o convite aos associados?',
								buttons: ['Sim', 'Não']
							}).then((result) => {
								if (!result) this.enviarConvites(e, pk_iev);


							})

						}

					});


				} else {
					swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
				}
			});
	}

	async componentDidMount() {
		//Carregar Parâmetros de pesquisa

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTipoEvento')
			.then((r) => r.json())
			.then(async (r) => {
				let comboTpe = this.state.combos;
				await asyncForEach(r, async (item) => {
					let tipoEvento = {
						type: 'tipoEvento',
						display: item.descricao,
						value: item.pk_tpe
					};
					await comboTpe.push(tipoEvento);
				});
			});
		ordenar('comboTpe')


		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getStatus_Eventos')
			.then((r) => r.json())
			.then(async (r) => {
				let comboSev = this.state.combos;
				await asyncForEach(r, async (item) => {
					let statusEvento = {
						type: 'statusEvento',
						display: item.descricao,
						value: item.pk_sev
					};
					await comboSev.push(statusEvento);
				});
			});
		ordenar('comboSev')

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTipos_Itens_Eventos')
			.then((r) => r.json())
			.then(async (r) => {
				let comboIev = this.state.combos;
				await asyncForEach(r, async (item) => {
					let itensEvento = {
						type: 'itensEvento',
						display: item.descricao,
						value: item.pk_tie
					};
					await comboIev.push(itensEvento);
				});
			});


		let query = {};
		query.filtered = true;
		getParameterByName('filtered');
		query.descricao = getParameterByName('descricao');

		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/deleteEventos?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exclusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleDeleteIev(e, pk, pk_eve) {
		console.log(pk_eve)
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/deleteIev?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exclusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getDataIev(event, pk_eve);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}


	handleCancel(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será cancelado. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/cancelaItemEvento?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Cancelamento realizado', 'Registro cancelado com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getDataPev(event);
							});
						} else {
							swal('Cancelamento não realizado', 'Registro não foi cancelado!', 'error');
						}
					});
			}
		});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/eventos'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	selectFileFolder(e) {
		e.preventDefault();
		$('#pdf_btn').click();
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;
			let queryString = '?';

			if (filter.descricao) {
				if (queryString === '?') {
					queryString = queryString + 'descricao=' + filter.descricao;
				} else queryString = queryString + '&descricao=' + filter.descricao;
			}

			if (filter.local) {
				if (queryString === '?') {
					queryString = queryString + 'local=' + filter.local;
				} else queryString = queryString + '&local=' + filter.local;
			}

			if (filter.fk_tpe) {
				if (queryString === '?') {
					queryString = queryString + 'fk_tpe=' + filter.fk_tpe;
				} else queryString = queryString + '&fk_tpe=' + filter.fk_tpe;
			}

			if (filter.fk_sev) {
				if (queryString === '?') {
					queryString = queryString + 'fk_sev=' + filter.fk_sev;
				} else queryString = queryString + '&fk_sev=' + filter.fk_sev;
			}


			if (filter.permissao) {
				if (queryString === '?') {
					queryString = queryString + 'permissao=' + filter.permissao;
				} else queryString = queryString + '&permissao=' + filter.permissao;
			}


			if (filter.data_ini) {
				if (queryString === '?') {
					queryString = queryString + 'data_ini=' + filter.data_ini;
				} else queryString = queryString + '&data_ini=' + filter.data_ini;
			}


			if (filter.data_fim) {
				if (queryString === '?') {
					queryString = queryString + 'data_fim=' + filter.data_fim;
				} else queryString = queryString + '&data_fim=' + filter.data_fim;
			}
			//Monta Query URL
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/eventos' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/eventos?filtered=true');
			}

			// resolve(filtered);
			resolve(queryString)
		});
	}

	async getData(e) {
		e.preventDefault();
		let queryString = await this.filterData();
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getEventos' + queryString)
			.then((r) => r.json())
			.then(async (r) => {

				console.log(r)
				this.setState({ data: r });
			});
	}


	async getDataIev(e, codigo) {
		e.preventDefault();

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getIev?pk=' + codigo)
			.then((r) => r.json())
			.then(async (r) => {


				this.setState({ dataIev: r });
			});
	}

	async getDataPev(e, codigo) {
		e.preventDefault();

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getPev?pk=' + codigo)
			.then((r) => r.json())
			.then(async (r) => {


				this.setState({ dataPev: r });
			});
	}

	render() {
		let tipoEvento = this.state.combos.map(this.populaTpe);
		let statusEvento = this.state.combos.map(this.populaSev);
		let itensEvento = this.state.combos.map(this.populaTie);


		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="GrauParentesco" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Eventos</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Descrição</label>
									<input
										name="descricao"
										type="text"
										id="filtroDescricao"
										className="inputFiltro"
										style={{ width: '30vw' }}
										value={this.state.filter.descricao || ''}
										onChange={this.handleChange}
									/>
								</div>

								<div className="itemFiltro">
									<label className="labelFiltro">Período</label>
									<input
										name="data_ini"
										type="date"
										id="filtroDataIni"
										className="inputFiltro"
										style={{ width: '10vw' }}
										value={this.state.filter.data_ini || ''}
										onChange={this.handleChange}
									/>

									<input
										name="data_fim"
										type="date"
										id="filtroDataFim"
										className="inputFiltro"
										style={{ width: '10vw' }}
										value={this.state.filter.data_fim || ''}
										onChange={this.handleChange}
									/>
								</div>

								<div className="itemFiltro">
									<label className="labelFiltro">Local</label>
									<input
										name="local"
										type="text"
										id="filtroLocal"
										className="inputFiltro"
										style={{ width: '30vw' }}
										value={this.state.filter.local || ''}
										onChange={this.handleChange}
									/>
								</div>

								<div className="itemFiltro">
									<label className="labelFiltro">Tipo de Evento</label>
									<select
										name="fk_tpe"
										id="comboTpe"
										className="selectFiltro"
										value={this.state.filter.fk_tpe || ''}
										onChange={this.handleChange}
										style={{ width: '200px' }}
									>
										{tipoEvento}
										<option value="">TODOS</option>

									</select>
								</div>

								<div className="itemFiltro">
									<label className="labelFiltro">Permissão</label>
									<select
										name="permissao"
										id="comboPermissao"
										className="selectFiltro"
										value={this.state.filter.permissao || ''}
										onChange={this.handleChange}
										style={{ width: '200px' }}
									>


										<option value="">TODOS</option>
										<option value="A">AMBOS</option>
										<option value="D">SOMENTE DEPENDENTES</option>
										<option value="T">SOMENTE TITULARES</option>

									</select>
								</div>

								<div className="itemFiltro">
									<label className="labelFiltro">Situação</label>
									<select
										name="fk_sev"
										id="comboSev"
										className="selectFiltro"
										value={this.state.filter.fk_sev || ''}
										onChange={this.handleChange}
										style={{ width: '200px' }}
									>
										{statusEvento}
										<option value="">TODOS</option>

									</select>
								</div>

							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<button className="buttonNovo" onClick={this.showModal}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo Registro
								</button>

								<button className="buttonFiltroLimpar" onClick={this.showModalLotacao}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_email} />Enviar Convite
								</button>

								<button className="buttonNovo" onClick={this.showModal}>
									<Icon size={20} style={{ display: 'inline' }} icon={coinDollar} />Gerar Cobrança
								</button>

							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Registro de Evento</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form
												id="registroEventos"
												name="registroEventos"
												onSubmit={this.submitData}
											>
												<div>
													<label className="labelModal">Descrição</label>
													<input
														type="text"
														id="descricao"
														name="descricao"
														className="form-control"
														data-parse="uppercase"
													/>
												</div>

												<label className="labelModal">Data / Hora</label>

												<div className="row">

													<div className="col-sm-2" style={{ width: '160px' }}>

														<input
															type="date"
															id="auxdata"
															name="auxdata"
															className="form-control"

														/>

													</div>

													<div className="col-sm-2" style={{ width: '100px' }}>

														<input
															type="time"
															id="hora"
															name="hora"
															className="form-control"

														/>

													</div>

												</div>

												<label className="labelModal">Data Limite Cancelamento</label>
												<div className="row">

													<div className="col-sm-2" style={{ width: '160px' }}>

														<input
															type="date"
															id="auxdatalimite"
															name="auxdatalimite"
															className="form-control"

														/>

													</div>
												</div>
												<div>
													<label className="labelModal">Local</label>
													<input
														type="text"
														id="local"
														name="local"
														className="form-control"
														data-parse="uppercase"
													/>
												</div>

												<div className="row">

													<div className="col-sm-3" style={{ width: '200px' }}>

														<label className="labelModal">Permissão</label>
														<select
															data-parse="uppercase"
															id="permissao"
															name="permissao"
															className="form-control"
															defaultValue="A"

														>
															<option value="A">AMBOS</option>
															<option value="D">SOMENTE DEPENDENTES</option>
															<option value="T">SOMENTE TITULARES</option>
														</select>
													</div>


													<div className="col-sm-3" style={{ width: '200px' }}>
														<label className="labelModal">Tipo de Evento</label>
														<select
															data-parse="uppercase"
															id="fk_tpe"
															name="fk_tpe"
															className="form-control"
															defaultValue=""
														>
															{tipoEvento}
															<option value="">Selecione</option>
														</select>
													</div>

													<div className="col-sm-3" style={{ width: '200px' }}>
														<label className="labelModal">Situação</label>
														<select
															name="fk_sev"
															id="fk_sev"
															className="form-control"
															data-parse="uppercase"
															required="required"
															defaultValue=""
														>
															{statusEvento}
															<option value="">Selecione</option>
														</select>
													</div>

													<div className="col-sm-3" style={{ width: '200px'}}>
													<label className="labelModal">Exige Confirmação</label>
														<select
															name="exige_confirmacao"
															id="comboExige_confirmacao"
															className="selectFiltro"
															value={this.state.filter.exige_confirmacao || ''}
															onChange={this.handleChange}
															style={{ width: '200px' }}
													>
															<option value="N">Não</option>
															<option value="S">Sim</option>

														</select>
													</div>
												</div>

												<div>
													<label className="labelModal">Resumo</label>

													<textarea
														type="text"
														name="resumo"
														data-parse="uppercase"
														className="form-control"
														style={{ height: '100px' }}
													/>
												</div>


												<div style={{ textAlign: 'left', marginTop: '15px' }}>
													<label className="labelModal">Regras do Evento</label>

													<input

														type="file"
														name="arquivo"
														id="html_btn"
														accept=".pdf"
														onChange={this.handleSelectedFile}
													/>

													{this.state.arquivo.name || 'Nenhum arquivo selecionado'}

													<button
														className="ModalButton"
														type="file"
														onClick={this.selectFile}
														style={{ width: '110px' }}
													>
														Selecionar Arquivo
													</button>

												</div>
												<div>
													<br />
													<strong>Itens do Evento</strong>
													<hr width="100%" size="50" color=" #528b46" />

													<div className="row">
														<div className="col-xs-1" style={{ width: '180px' }}>
															<label className="labelModal">Item do Evento</label>
															<select
																name="fk_tie"
																id="fk_tie"
																className="form-control"
																data-parse="uppercase"
																required="required"
																defaultValue=""
															>
																{itensEvento}
																<option value="">Selecione</option>
															</select>
														</div>



														<div className="col-xs-1" style={{ width: '100px' }}>
															<label className="labelModal">Valor</label>

															<input
																type="text"
																id="valor"
																name="valor"
																className="form-control"
																data-parse="uppercase"
																onBlur={(e) => {
																	onBlurCurrency(e);
																}}
															/>
														</div>

														<div className="col-xs-1" style={{ width: '40px', paddingTop: '20px' }}>

															<button
																className="ModalButton"
																onClick={this.insertIev}
																style={{ width: '100px' }}
															>
																Adicionar
											              	</button>
															<div style={{ paddingTop: '10px' }}>
																<button
																	className="ModalButton"
																	onClick={this.limpaIev}
																	style={{ width: '100px' }}
																>
																	Limpar Registros
											              	</button>
															</div>
														</div>

													</div>

													<div style={{ marginLeft: '0px', marginTop: '10px', marginRight: '0px' }}>

														<div className="divTabela">
															<ReactTable
																showPagination={true}
																data={this.state.dataIev}
																previousText="Anterior"
																nextText="Próximo"
																loadingText="Carregando..."
																pageText="Página"
																ofText="de"
																rowsText="registros"
																noDataText="Nenhum item encontrado"
																columns={[

																	{
																		Header: 'Item',
																		accessor: 'nometie',
																		minWidth: 100
																	},

																	{
																		Header: 'Valor',
																		accessor: 'valor',
																		minWidth: 80
																	},

																	{
																		Header: 'Folder',
																		accessor: 'folder',
																		Cell: (row) => {
																			return (
																				<div >
																					{(!row.row.folder) ? <></> :
																						<Icon size={20} icon={ic_photo} className="iconButton" onClick={(e) => {
																							this.showArquivo(e, row.row.folder);
																						}} />}
																				</div>
																			)
																		}
																	}

																]}

																defaultPageSize={5}
															/>
														</div>
													</div>


												</div>
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSave}>
												Salvar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>


						<div style={{ paddingBottom: '30px' }}>
							<div className={this.modalStateConfirmacoes()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Confirmações do Evento</h3>

											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="confirmacoesEventos"
													name="confirmacoesEventos"
												>
													<div>
														<label className="labelModal">Descrição</label>
														<input
															type="text"
															id="descricao"
															name="descricao"
															className="form-control"
															data-parse="uppercase"
															disabled
														/>
													</div>
													<label className="labelModal">Data / Hora</label>

													<div className="row">

														<div className="col-sm-2" style={{ width: '150px' }}>

															<input
																type="date"
																id="auxdata"
																name="auxdata"
																className="form-control"
																disabled
															/>

														</div>

														<div className="col-sm-2" style={{ width: '100px' }}>

															<input
																type="time"
																id="hora"
																name="hora"
																className="form-control"
																disabled
															/>

														</div>
													</div>

													<div>
														<label className="labelModal">Local</label>
														<input
															type="text"
															id="local"
															name="local"
															className="form-control"
															data-parse="uppercase"
															disabled
														/>
													</div>

													<div>
														<br />
														<strong>Confirmações</strong>

														<hr width="100%" size="50" color=" #528b46" />

														<div style={{ marginLeft: '0px', marginTop: '10px', marginRight: '0px' }}>

															{/* <div className="divTabela"> */}
															<div>

																<ReactTable
																	showPagination={true}
																	data={this.state.dataPev}
																	previousText="Anterior"
																	nextText="Próximo"
																	loadingText="Carregando..."
																	pageText="Página"
																	ofText="de"
																	rowsText="registros"
																	noDataText="Nenhum item encontrado"
																	columns={[


																		{
																			Header: '',
																			width: 30,
																			Cell: (row) => {
																				return (
																					<div>
																						<Icon size={20} icon={ic_do_not_disturb_alt}
																							// className="iconButton"

																							onClick={(e) => {
																								this.handleCancel(e, row.row.pk_pev);
																							}} />
																					</div>
																				);
																			}
																		},
																		{
																			Header: 'Código',
																			accessor: 'pk_pev',
																			minWidth: 100,
																			show: false
																		},
																		{
																			Header: 'Código',
																			accessor: 'cancelado',
																			minWidth: 100,
																			show: false
																		},


																		{
																			Header: 'Associado',
																			accessor: 'nomeass',
																			minWidth: 100,

																			Cell: (row) => {
																				return (
																					<div className={this.classCancelado(row.row.cancelado)}>
																						{row.row.nomeass}
																					</div>
																				);
																			}

																		},

																		{
																			Header: 'Item',
																			accessor: 'nometie',
																			minWidth: 80,

																			Cell: (row) => {
																				return (
																					<div className={this.classCancelado(row.row.cancelado)}>
																						{row.row.nometie}
																					</div>
																				);
																			}

																		},

																		{
																			Header: 'Qtd.Deps.',
																			accessor: 'qtd_dep',
																			minWidth: 50,

																			Cell: (row) => {
																				return (
																					<div className={this.classCancelado(row.row.cancelado)}>
																						{row.row.qtd_dep}
																					</div>
																				);
																			}

																		},


																	]}

																	defaultPageSize={5}
																/>
															</div>

														</div>

														<div>Legenda:</div>

														<div className='divCancelado' style={{ width: '180px' }}>
															Participação Cancelada
                                                        </div>

													</div>
												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={this.closeModalConfirmacoes}>
													Fechar
											</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
						</div>


						<div style={{ paddingBottom: '30px' }}>
							<div className={this.modalStateEditaIev()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Itens do Evento</h3>

											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="editaIev"
													name="editaIev"
												>
													<div>
														<label className="labelModal">Descrição</label>
														<input
															type="text"
															id="descricao"
															name="descricao"
															className="form-control"
															data-parse="uppercase"
															disabled
														/>
													</div>

													<div>
														<div style={{ marginLeft: '0px', marginTop: '10px', marginRight: '0px' }}>

															<div>

																<ReactTable
																	showPagination={true}
																	data={this.state.dataIev}
																	previousText="Anterior"
																	nextText="Próximo"
																	loadingText="Carregando..."
																	pageText="Página"
																	ofText="de"
																	rowsText="registros"
																	noDataText="Nenhum item encontrado"
																	columns={[
																		{
																			Header: 'Código',
																			accessor: 'pk_iev',
																			minWidth: 100,
																			show: false
																		},

																		{
																			Header: 'Código',
																			accessor: 'fk_eve',
																			minWidth: 100,
																			show: false
																		},

																		{
																			Header: '',
																			width: 70,
																			Cell: (row) => {
																				return (
																					<div>
																						<Icon size={20} icon={edit} className="iconButton" onClick={(e) => {
																							this.showModalIev(e, row.row.pk_iev);
																						}} />

																						<Icon size={20} icon={iosTrash} className="iconButton"

																							onClick={(e) => {
																								this.handleDeleteIev(e, row.row.pk_iev, row.row.fk_eve);
																							}} />

																					</div>
																				);
																			}
																		},


																		{
																			Header: 'Item',
																			accessor: 'nometie',
																			minWidth: 100
																		},

																		{
																			Header: 'Valor',
																			accessor: 'valor',
																			minWidth: 80
																		},

																		{
																			Header: 'Folder',
																			accessor: 'folder',
																			Cell: (row) => {
																				return (
																					<div >
																						{(!row.row.folder) ? <></> :
																							<Icon size={20} icon={ic_photo} className="iconButton" onClick={(e) => {
																								this.showArquivo(e, row.row.folder);
																							}} />}
																					</div>
																				)
																			}
																		}

																	]}
																	defaultPageSize={5}
																/>
															</div>

														</div>
													</div>
												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={this.closeModalEditaIev}>
													Fechar
											</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
						</div>


						<div style={{ paddingBottom: '30px' }}>
							<div className={this.modalStateShowIev()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Edição de Item do Evento</h3>

											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="showIev"
													name="showIev"
												>
													<div className="row">

														<input
															type="text"
															id="pk_iev"
															name="pk_iev"
															className="form-control"
															data-parse="uppercase"
															hidden
														/>

														<div className="col-xs-1" style={{ width: '250px' }}>
															<label className="labelModal">Item do Evento</label>
															<select
																name="fk_tie"
																id="fk_tie"
																className="form-control"
																data-parse="uppercase"
																required="required"
																defaultValue=""
															>
																{itensEvento}
																<option value="">Selecione</option>
															</select>
														</div>



														<div className="col-xs-1" style={{ width: '100px' }}>
															<label className="labelModal">Valor</label>

															<input
																type="text"
																id="valor"
																name="valor"
																className="form-control"
																data-parse="uppercase"
																onBlur={(e) => {
																	onBlurCurrency(e);
																}}
															/>

														</div>
														<div style={{ width: '500px' }}>

															<strong>Folder Convite</strong>
															<br />
															<input

																type="file"
																name="folder"
																id="pdf_btn"
																accept="image/*"
																onChange={this.handleSelectedFileFolder}
															/>

															{this.state.arquivoIev.name || 'Nenhum arquivo selecionado'}

															<button
																className="ModalButton"
																type="file"
																onClick={this.selectFileFolder}
																style={{ width: '110px' }}
															>
																Selecionar Arquivo
					                                    </button>
														</div>


													</div>

												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={(e) => this.submitIev(e, this.state.arquivoIev.name)}>
													Confirma
											</button>

												<button className="ModalButton" onClick={this.closeModalIev}>
													Cancela
											</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
						</div>



						<div style={{ paddingBottom: '30px' }}>
							<div className={this.modalStateShowLotacao()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Seleção para envio de convites</h3>

											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="showLotacao"
													name="showLotacao"
												>

													<div >
														<ReactTable
															data={this.state.dataLot}
															previousText="Anterior"
															nextText="Próximo"
															loadingText="Carregando..."
															pageText="Página"
															ofText="de"
															rowsText="registros"
															noDataText="Nenhum registro encontrado"
															columns={[

																{
																	id: 'selection',
																	Header: (header) => {
																		header.column.sortable = false
																		return (
																			<div>
																				<input type="checkbox" onChange={this.selectAllLot} checked={this.state.selectedDataLot.length === this.state.dataLot.length} />
																			</div>
																		)
																	},
																	Cell: ({ row }) => (
																		<div>
																			<input type="checkbox" onChange={(e) => this.selectRowLot(e, row.pk_lot)} checked={this.state.selectedDataLot.map((item) => item.codigo).indexOf(row.pk_lot) > -1} />
																		</div>
																	),
																	width: 47,
																},

																{
																	Header: 'Código',
																	accessor: 'pk_lot',
																	minWidth: 100,
																	show: false
																},

																{
																	Header: 'Lotação',
																	accessor: 'descricao',
																	minWidth: 250,

																},

															]}

															defaultSorted={[
																{
																	id: 'descricao',
																	desc: false
																}
															]}
															defaultPageSize={10}
															className="-striped -highlight"
														/>
													</div>

												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={(e) => this.enviarConvites(e)}>
													Envia Convites
											</button>

												<button className="ModalButton" onClick={this.closeModalLotacao}>
													Cancela
											</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
						</div>


						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[

										{
											id: 'selection',
											Header: (header) => {
												header.column.sortable = false
												return (
													<div>
														<input type="checkbox" onChange={this.toggleSelectAll} checked={this.state.selectedData.length === this.state.data.length} />
													</div>
												)
											},
											Cell: ({ row }) => (
												<div>
													<input type="checkbox" onChange={(e) => this.toggleSelectRow(e, row.pk_eve)} checked={this.state.selectedData.map((item) => item.codigo).indexOf(row.pk_eve) > -1} />
												</div>
											),
											width: 47,
										},

										{
											Header: '',
											width: 70,
											Cell: (row) => {
												return (
													<div>
														<Icon size={20} icon={edit} className="iconButton" onClick={(e) => {
															this.showModal(e, row.row.pk_eve);
														}} />

														<Icon size={20} icon={iosTrash} className="iconButton"

															onClick={(e) => {
																this.handleDelete(e, row.row.pk_eve);
															}} />

													</div>
												);
											}
										},
										{
											Header: 'Código',
											accessor: 'pk_eve',
											minWidth: 100,
											show: false
										},

										{
											Header: 'Data',
											accessor: 'dataeve',
											minWidth: 100
										},

										{
											Header: 'Descrição',
											accessor: 'descricao',
											minWidth: 250,

										},


										{
											Header: 'Situação',
											accessor: 'nomesev',
											minWidth: 100
										},

										{

											Cell: (row) => {
												return (
													<div >

														<button className="buttonGrid"
															onClick={(e) => {
																this.confirmacoes(e, row.row.pk_eve);
															}}
														>
															Confirmações
                  									  </button>

														<button className="buttonGrid"
															onClick={(e) => {
																this.editaIev(e, row.row.pk_eve);
															}}
														>
															Editar Itens
                  									  </button>

													</div>
												)
											}

										},


									]}

									defaultSorted={[
										{
											id: 'descricao',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

export default Eventos;
