import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import { getParameterByName } from '../Utils';


class VisualizarDemonstrativoIR extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			competencia: '',
			arquivo: ''
		};


		this.handleBack = this.handleBack.bind(this);
	}

	handleBack(e) {
		e.preventDefault();
		window.history.back();
	}




	async componentDidMount() {
		this.setState({competencia: getParameterByName('competencia'), arquivo: getParameterByName('arquivo')})
	}

	render() {
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Visualizar Demonstrativo IR</h3>
					</div>
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="gerarRegistroDemonstrativos" name="gerarRegistroDemonstrativos">
								<iframe 
									title='demonstrativo'
									src={config.protocol + '://' + config.server + ':' + config.portBackend + '/static/demonstrativosIR/'+this.state.competencia+'/'+this.state.arquivo}
									style={{ width: '90%', height: '70vh', marginLeft: '5%' }}
								/>
							</form>
						</div>
					</form>

					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '10px' }}
								onClick={this.handleBack}
							>
								Voltar
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default VisualizarDemonstrativoIR;
