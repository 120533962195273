import React, { Component } from 'react'
import config from '../config'
import NavBar from '../components/NavBar'
import '../css/Default.css'
import { LinkContainer } from 'react-router-bootstrap'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Modal } from 'react-bootstrap'
import { garanteDate, asyncForEach, getParameterByName, ordenar } from '../Utils'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import { Icon } from 'react-icons-kit'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import { edit } from 'react-icons-kit/ionicons/edit'
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash'
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle'
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong'
import { ic_clear } from 'react-icons-kit/md/ic_clear'
import { card } from 'react-icons-kit/ionicons/card'
import MultiSelect from "@khanacademy/react-multi-select"


class Associados extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			items: [],
			modal: { show: false },
			filterChanged: '',
			selected: [],
			columns: [],
			filterSituacao: [],
			selectedItems: [],
			data: [],
			filterLotacao: [],
			filterCategorias: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modalState = this.modalState.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.toggleColumn = this.toggleColumn.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.populaSelectedColumns = this.populaSelectedColumns.bind(this);
		this.populaUnselectedColumns = this.populaUnselectedColumns.bind(this);
		this.exportacaoSelectAll = this.exportacaoSelectAll.bind(this);
	}

	populaLotacao(item, id) {
		if (item.type === 'lotacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	toggleColumn(e, name) {
		e.preventDefault();
		let columns = this.state.columns;
		asyncForEach(columns, (item, index) => {
			if (item.value === name) item.selected = !item.selected
			if (index + 1 === columns.length) this.setState({ columns: columns })
		})
	}

	populaSelectedColumns(item, id) {
		if (item.selected) {
			return (
				<li name={item.value} key={id} onClick={(e) => this.toggleColumn(e, item.value)}>
					{item.value}
				</li>
			);
		}
	}

	populaUnselectedColumns(item, id) {
		if (!item.selected) {
			return (
				<li name={item.value} key={id} onClick={(e) => this.toggleColumn(e, item.value)}>
					{item.value}
				</li>
			);
		}

	}

	async handleExport() {
		let columns = this.state.columns;
		let selected = columns.filter((item) => { return item.selected === true }).length > 0;
		let data = this.state.data;
		let haveData = data.length > 0;
		console.log(data)
		let exportData = { columns: columns, data: data };
		if (!selected) swal('Nenhuma coluna selecionada!!', 'Nenhuma coluna foi selecionada. Selecione pelo menos uma para exportar os registros!', 'error');
		if (!haveData) swal('Nenhum registro encontrado!!', 'Nenhum registro filtrado!! Filtre ao menos um registro para gerar a exportação.', 'error');
		if (haveData && selected) {
			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/exportacaoLivre?table=associados',
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(exportData)
				}
			).then((r) => r.blob())
				.then((r) => {
					var url = window.URL.createObjectURL(r);
					var a = document.createElement('a');
					a.href = url;
					a.download = "exportacao.xlsx";
					document.body.appendChild(a);
					a.click();
					a.remove();
				});
		}
	}


	closeModal() {
		this.setState({ modal: { show: false } });
	}

	async showModal(e, codigo) {
		// e.stopPropagation()
		e.preventDefault();
		this.setState({ modal: { show: true } });
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	populaSituacoes(item, id) {
		if (item.type === 'situacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaCategorias(item, id) {
		if (item.type === 'categorias') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		//Lotação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getLotacoes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosLotacao = this.state.combos;
				await asyncForEach(r, async (item) => {
					let lotacao = {
						type: 'lotacao',
						display: item.descricao,
						value: item.pk_lot,
						label: item.descricao,
					};
					await combosLotacao.push(lotacao);
				});
			});
			let comboCobranca = [{
						type: 'cobranca',
						display: 'Banco',
						value: 'B',
						label: 'Banco',
					},
					{
						type: 'cobranca',
						display: 'Tesouro',
						value: 'T',
						label: 'Tesouro',
					},
					{
						type: 'cobranca',
						display: 'Portabilidade',
						value: 'P',
						label: 'Portabilidade',
				},
					{
						type: 'cobranca',
						display: 'Nenhum',
						value: 'N',
						label: 'Nenhum',
					},
				]
		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSituacoes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosSituacao = this.state.combos;
				await asyncForEach(r, async (item) => {
					let situacao = {
						type: 'situacao',
						display: item.descricao,
						value: item.pk_sit,
						label: item.descricao,
					};
					await combosSituacao.push(situacao);
				});
			});

		//Categorias
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let combosCategorias = this.state.combos;
				await asyncForEach(r, async (item) => {
					let categoria = {
						type: 'categorias',
						display: item.descricao,
						value: item.pk_cat,
						label: item.descricao,
					};
					await combosCategorias.push(categoria);
				});
			});

		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = false;
		query.geral = getParameterByName('geral');
		query.nome = getParameterByName('nome');
		query.lotacao = getParameterByName('lotacao');
		query.data_nasc_max = getParameterByName('data_nasc_max');
		query.data_nasc_min = getParameterByName('data_nasc_min');
		query.situacao = getParameterByName('situacao');
		query.ativo = getParameterByName('ativo');
		query.celular = getParameterByName('celular');
		query.dependente = getParameterByName('dependente');
		query.categoria = getParameterByName('categoria');

		this.setState({
			filter: query,
			filterSituacao: JSON.parse(query.situacao || '[]'),
			filterLotacao: JSON.parse(query.lotacao || '[]'),
			filterCategorias: JSON.parse(query.categorias || '[]'),
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
		ordenar('filtroLotacao')
		ordenar('filtroSituacao')
		ordenar('filtroCategoria')
	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/deleteAssociado?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		console.log(reg)
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/associados'); //Apaga QueryURL
		this.setState({ filter: [], filterSituacao: [], filterLotacao: [], filterCategorias: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_nasc = garanteDate(item.data_nasc);
			});
			resolve(data);
		});
	}

	async filterGeral(data, queryString) {
		return new Promise(async (resolve) => {
			let filter = this.state.filter;
			let filtered = await data.filter((item) => {
				//Filtro: Geral
				let valores = JSON.stringify(Object.values(item));
				let geral = true;
				if (filter.geral) {
					geral = (valores || '').includes((filter.geral || '').toUpperCase());
				}

				return (geral);
			})

			if (filter.geral) {
				if (queryString === '?') {
					queryString = queryString + 'geral=' + filter.geral;
				} else queryString = queryString + '&geral=' + filter.geral;
			}

			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/associados' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/associados?filtered=true');
			}

			resolve(filtered);
		})
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			// Filtro Especial: Celulares (Lista de FK)
			let listafkcel = [];
			if (filter.celular) {
				let celulares = [];

				//Celulares
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCelulares')
					.then((r) => r.json())
					.then(async (r) => {
						celulares = r.filter((item) => {
							return item.numero.includes(filter.celular);
						});
						await asyncForEach(celulares, async (item) => {
							listafkcel.push(item.fk_ass);
						});
					});
			}

			// Filtro Especial: Dependentes (Lista de FK)
			let listadep = [];
			if (filter.dependente) {
				let dependentes = [];

				//Dependentes
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getDependentes')
					.then((r) => r.json())
					.then(async (r) => {
						dependentes = r.filter((item) => {
							return item.nome.includes(filter.dependente);
						});
						await asyncForEach(dependentes, async (item) => {
							listadep.push(item.fk_asstitular);
						});
					});
			}


			// 	//Filtro: Nome
			// 	let nome = (item.nome || '').includes((filter.nome || '').toUpperCase());

			// 	//Filtro: Lotação
			// 	let lotacao = Number(item.fk_lot) === Number(filter.lotacao) || (filter.lotacao || 'T') === 'T';

			// 	//Filtro: Lotação
			// 	let situacao =  Number(item.fk_sit) === Number(filter.situacao) || (filter.situacao || 'T') === 'T';

			// 	//Filtro: Categoria
			// 	let categoria = Number(item.fk_cat) === Number(filter.categoria) || (filter.categoria || 'T') === 'T';

			// 	//Filtro: Data de Nascimento
			// 	let data_nasc_min = true;
			// 	// console.log(item.data_nasc);
			// 	if (!isNaN(new Date((filter.data_nasc_min || '') + 'T00:00:00').getTime())) {
			// 		if (
			// 			moment(
			// 				new Date(((item.data_nasc || '0000-00-00T00:00:00.000Z').split('T')[0] || '') + 'T12:00:00')
			// 			).isSameOrAfter(new Date((filter.data_nasc_min || '') + 'T00:00:00'))
			// 		) {
			// 			data_nasc_min = true;
			// 		} else data_nasc_min = false;
			// 	}
			// 	if (filter.data_nasc_min) {
			// 		if (queryString === '?') {
			// 			queryString = queryString + 'data_nasc_min=' + filter.data_nasc_min;
			// 		} else queryString = queryString + '&data_nasc_min=' + filter.data_nasc_min;
			// 	}
			// 	let data_nasc_max = true;
			// 	if (!isNaN(new Date((filter.data_nasc_max || '') + 'T00:00:00').getTime())) {
			// 		if (
			// 			moment(
			// 				new Date(((item.data_nasc || '0000-00-00T00:00:00.000Z').split('T')[0] || '') + 'T12:00:00')
			// 			).isSameOrBefore(new Date((filter.data_nasc_max || '') + 'T00:00:00'))
			// 		) {
			// 			data_nasc_max = true;
			// 		} else data_nasc_max = false;
			// 	}

			// 	//Filtro: Ativo
			// 	let ativo = item.ativo === filter.ativo || (filter.ativo || 'T') === 'T';

			// 	// Filtro Especial: Celulares (Filtra PK com lista de FK)
			// 	let celular = true;
			// 	if (filter.celular) {
			// 		celular = listafkcel.includes(item.pk_ass);
			// 	}

			// 	// Filtro Especial: Celulares (Filtra PK com lista de FK)
			// 	let dependente = true;
			// 	if (filter.dependente) {
			// 		dependente = listadep.includes(item.pk_ass);
			// }

			// Filtra

			// &&
			// nome &&
			// lotacao &&
			// ativo &&
			// data_nasc_max &&
			// data_nasc_min &&
			// celular &&
			// situacao &&
			// categoria &&
			// dependente

			// });

			let queryString = '?';

			if (filter.nome) {
				if (queryString === '?') {
					queryString = queryString + 'nome=' + filter.nome;
				} else queryString = queryString + '&nome=' + filter.nome;
			}

			if (this.state.filterLotacao) {
				if (queryString === '?') {
					queryString = queryString + 'lotacao=' + JSON.stringify(this.state.filterLotacao);
				} else queryString = queryString + '&lotacao=' + JSON.stringify(this.state.filterLotacao);
			}

			if (this.state.filterSituacao) {
				if (queryString === '?') {
					queryString = queryString + 'situacao=' + JSON.stringify(this.state.filterSituacao);
				} else queryString = queryString + '&situacao=' + JSON.stringify(this.state.filterSituacao);
			}

			if (filter.diaIni) {
				if (queryString === '?') {
					queryString = queryString + 'diaIni=' + filter.diaIni;
				} else queryString = queryString + '&diaIni=' + filter.diaIni;
			}

			if (filter.mesIni) {
				if (queryString === '?') {
					queryString = queryString + 'mesIni=' + filter.mesIni;
				} else queryString = queryString + '&mesIni=' + filter.mesIni;
			}

			if (filter.diaFim) {
				if (queryString === '?') {
					queryString = queryString + 'diaFim=' + filter.diaFim;
				} else queryString = queryString + '&diaFim=' + filter.diaFim;
			}

			if (filter.mesFim) {
				if (queryString === '?') {
					queryString = queryString + 'mesFim=' + filter.mesFim;
				} else queryString = queryString + '&mesFim=' + filter.mesFim;
			}

			if (filter.ativo) {
				if (queryString === '?') {
					queryString = queryString + 'ativo=' + filter.ativo;
				} else queryString = queryString + '&ativo=' + filter.ativo;
			}

			if (filter.celular) {
				if (queryString === '?') {
					queryString = queryString + 'celular=' + filter.celular;
				} else queryString = queryString + '&celular=' + filter.celular;
			}

			if (filter.dependente) {
				if (queryString === '?') {
					queryString = queryString + 'dependente=' + filter.dependente;
				} else queryString = queryString + '&dependente=' + filter.dependente;
			}

			if (this.state.filterCategorias) {
				if (queryString === '?') {
					queryString = queryString + 'categorias=' + JSON.stringify(this.state.filterCategorias);
				} else queryString = queryString + '&categorias=' + JSON.stringify(this.state.filterCategorias);
			}

			if (filter.cobranca_unimed) {
				if (queryString === '?') {
					queryString = queryString + 'cobranca_unimed=' + filter.cobranca_unimed;
				} else queryString = queryString + '&cobranca_unimed=' + filter.cobranca_unimed;
			}


			if (filter.cobranca_servicos) {
				if (queryString === '?') {
					queryString = queryString + 'cobranca_servicos=' + filter.cobranca_servicos;
				} else queryString = queryString + '&cobranca_servicos=' + filter.cobranca_servicos;
			}


			if (filter.cobranca_mensalidade) {
				if (queryString === '?') {
					queryString = queryString + 'cobranca_mensalidade=' + filter.cobranca_mensalidade;
				} else queryString = queryString + '&cobranca_mensalidade=' + filter.cobranca_mensalidade;
			}


			if (filter.cobranca_telefonia) {
				if (queryString === '?') {
					queryString = queryString + 'cobranca_telefonia=' + filter.cobranca_telefonia;
				} else queryString = queryString + '&cobranca_telefonia=' + filter.cobranca_telefonia;
			}


			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/associados' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/associados?filtered=true');
			}

			resolve(queryString);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca

		let queryString = await this.filterData();
		await fetch(
			config.protocol +
			"://" +
			config.server +
			":" +
			config.portBackend +
			"/api/getAssociados" +
			queryString
		)

			// await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let colunasExportacao = [
					{ value: 'Código', field: 'pk_ass', fk: {}, selected: false, width: 50, align: 3 },
					{ value: 'Nome', field: 'nome', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'Matrícula', fk: {}, field: 'matricula', selected: false, width: 100, align: 3 },
					{ value: 'Categoria', fk: { fkField: 'fk_cat', tablePk: 'pk_ass', tableField: 'descricao', table: 'categorias' }, field: 'nomecat', selected: false, width: 150, align: 1 },
					{ value: 'Situação', fk: { fkField: 'fk_sit', tablePk: 'pk_ass', tableField: 'descricao', table: 'situacoes' }, field: 'nomesit', selected: false, width: 150, align: 1 },
					{ value: 'Lotação', fk: { fkField: 'fk_lot', tablePk: 'pk_ass', tableField: 'descricao', table: 'lotacoes' }, field: 'nomelot', selected: false, width: 150, align: 1 },
					{ value: 'Telefone Residencial', field: 'telefone_resid', fk: {}, selected: false, width: 100, align: 1 },
					{ value: 'Celulares', fk: {}, field: 'celulares', selected: false, width: 100, align: 1 },
					{ value: 'Associado', field: 'ativo', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'Habilitado Whatsapp', field: 'whatsapp', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'RG', field: 'rg', fk: {}, selected: false, width: 70, align: 1 },
					{ value: 'Data de Nascimento', field: 'data_nasc', fk: {}, selected: false, width: 70, align: 1 },
					{ value: 'Cpf', field: 'cpf', fk: {}, selected: false, width: 800, align: 1 },
					{ value: 'Email Pessoal', field: 'email_resid', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'Email Funcional', field: 'email_comerc', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'Sexo', field: 'sexo', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'Estado Civil', field: 'estado_civil', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'Endereço', field: 'endereco_resid', fk: {}, selected: false, width: 200, align: 1 },
					{ value: 'Bairro', field: 'bairro_resid', fk: {}, selected: false, width: 100, align: 1 },
					{ value: 'CEP', field: 'cep_resid', fk: {}, selected: false, width: 50, align: 1 },
					{ value: 'Cidade', fk: { fkField: 'fk_cid_resid', tablePk: 'pk_ass', tableField: 'nome', table: 'cidades' }, field: 'nomecidresid', selected: false, width: 200, align: 1 },
					{ value: 'ANAPE', field: 'anape', fk: {}, selected: false, width: 70, align: 1 },
					{ value: 'Cobrança Mensalidade', field: 'cobranca_mensalidade', fk: {}, selected: false, width: 130, align: 1 },
					{ value: 'Cobrança Unimed', field: 'cobranca_unimed', fk: {}, selected: false, width: 130, align: 1 },
					{ value: 'Cobrança Telefonia', field: 'cobranca_telefonia', fk: {}, selected: false, width: 130, align: 1 },
					{ value: 'Cobrança Outros Serviços', field: 'cobranca_servicos', fk: {}, selected: false, width: 130, align: 1 }



				];
				//Filtra
				let items = await this.filterGeral(r, queryString);
				//Trata
				items = await this.modelingData(items);
				this.setState({ data: items, columns: colunasExportacao });
			});
	}

	exportacaoSelectAll(e, select) {
		e.preventDefault();
		let columns = this.state.columns
		asyncForEach(columns, (item, index) => {
			item.selected = select
			if (index + 1 === columns.length) this.setState({ columns: columns })
		})
	}

	render() {
		let lotacoes = this.state.combos.filter((item) => {
			return item.type === 'lotacao';
		});
		let categorias = this.state.combos.filter((item) => {
			return item.type === 'categorias';
		});
		// let situacoes = this.state.combos.map(this.populaSituacoes);
		let selectedColumns = this.state.columns.map(this.populaSelectedColumns)
		let unselectedColumns = this.state.columns.map(this.populaUnselectedColumns)
		let situacoes = this.state.combos.filter((item) => {
			return item.type === 'situacao';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Associados" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Associados</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Geral</label>
									<input
										name="geral"
										type="text"
										id="filtroGeral"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.geral || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Nome</label>
									<input
										name="nome"
										type="text"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.nome || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Dependente</label>
									<input
										name="dependente"
										type="text"
										id="filtroDependente"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.dependente || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '20vw' }}>
									<label className="labelFiltro">Lotação</label>
									<MultiSelect
										options={lotacoes}
										selected={this.state.filterLotacao}
										onSelectedChanged={selected => this.setState({ filterLotacao: selected })}
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								{/* <div className="itemFiltro">
									<label className="labelFiltro">Situação</label>
									<select
										name="situacao"
										id="filtroSituacao"
										className="selectFiltro"
										value={this.state.filter.situacao || 'T'}
										onChange={this.handleChange}
									>

										{situacoes}
										<option value="">TODOS</option>
									</select>
								</div> */}
								<div className="itemFiltro" style={{ width: '20vw' }}>
									<label className="labelFiltro">Situação</label>
									<MultiSelect
										options={situacoes}
										selected={this.state.filterSituacao}
										onSelectedChanged={selected => this.setState({ filterSituacao: selected })}
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '20vw' }}>
									<label className="labelFiltro">Categoria</label>
									<MultiSelect
										options={categorias}
										selected={this.state.filterCategorias}
										onSelectedChanged={selected => this.setState({ filterCategorias: selected })}
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>
								<br />
								
								<div className="itemFiltro">
									<label className="labelFiltro">Cobrança Unimed </label>
									
									<select
										name="cobranca_unimed"
										id="cobranca_unimed"
										className="selectFiltro"
										value={this.state.filter.cobranca_unimed || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o Tipo</option>
										<option value="B">Banco</option>
										<option value="T">Tesouro</option>
										<option value="P">Portabilidade</option>
										<option value="N">Nenhum</option>

									</select>
								</div><div className="itemFiltro">
									<label className="labelFiltro">Cobrança Mensalidade </label>
									
									<select
										name="cobranca_mensalidade"
										id="cobranca_mensalidade"
										className="selectFiltro"
										value={this.state.filter.cobranca_mensalidade || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o Tipo</option>
										<option value="B">Banco</option>
										<option value="T">Tesouro</option>
										<option value="P">Portabilidade</option>
										<option value="N">Nenhum</option>

									</select>
								</div><div className="itemFiltro">
									<label className="labelFiltro">Cobrança Serviços </label>
									
									<select
										name="cobranca_servicos"
										id="cobranca_servicos"
										className="selectFiltro"
										value={this.state.filter.cobranca_servicos || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o Tipo</option>
										<option value="B">Banco</option>
										<option value="T">Tesouro</option>
										<option value="P">Portabilidade</option>
										<option value="N">Nenhum</option>

									</select>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Cobrança Telefonia </label>
									
									<select
										name="cobranca_telefonia"
										id="cobranca_telefonia"
										className="selectFiltro"
										value={this.state.filter.cobranca_telefonia || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o Tipo</option>
										<option value="B">Banco</option>
										<option value="T">Tesouro</option>
										<option value="P">Portabilidade</option>
										<option value="N">Nenhum</option>
										

									</select>
								</div>
								<br/>
								<div className="itemFiltro">
									<label className="labelFiltro">Dia/Mês Inicial Aniversariantes </label>
									<input type="number" id="diaIni" name="diaIni" min="1" max="31" className="selectFiltro"
										value={this.state.filter.diaIni || ''}
										onChange={this.handleChange}
									/>
									<select
										name="mesIni"
										id="mesIni"
										className="selectFiltro"
										value={this.state.filter.mesIni || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o mês</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>

									</select>
								</div>
								<div className="itemFiltro">

									<label className="labelFiltro"> Dia/Mês Final Aniversariantes </label>

									<input type="number" id="diaFim" name="diaFim" min="1" max="31" className="selectFiltro"
										value={this.state.filter.diaFim || ''}
										onChange={this.handleChange}
									/>
									<select
										name="mesFim"
										id="mesFim"
										className="selectFiltro"
										value={this.state.filter.mesFim || ''}
										onChange={this.handleChange}
									>
										<option value="">Selecione o mês</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>

									</select>

									{/* <input
										type="date"
										name="data_nasc_min"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '140px' }}
										value={this.state.filter.data_nasc_min || ''}
										onChange={this.handleChange}
									/>
									<input
										type="date"
										name="data_nasc_max"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '140px' }}
										value={this.state.filter.data_nasc_max || ''}
										onChange={this.handleChange}
									/> */}
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Celular</label>
									<input
										name="celular"
										type="text"
										id="filtroCelular"
										className="inputFiltro"
										style={{ width: '100px' }}
										value={this.state.filter.celular || ''}
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<LinkContainer to={'/apergs/associados/registro'}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo
										Registro
									</button>
								</LinkContainer>
								<button className="buttonExportacao" onClick={this.showModal}>
									<Icon size={16} style={{ display: 'inline' }} icon={fileExcel} />Exportação Livre
								</button>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className={this.modalState()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Exportação Livre - Associados</h3>
											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="exportacaoAssociados"
													name="exportacaoAssociados"
													onSubmit={this.submitData}
												>
													<div className="containerColumns rowColumns">
														<div className="itemColumns">
															<label className="labelColumns">Colunas disponíveis</label>
															<ul name='unselected' className='ListSelect'>
																{unselectedColumns}
															</ul>
														</div>
														<div name='selected' className="itemColumns">
															<label className="labelColumns">Colunas selecionadas</label>
															<ul className='ListSelect'>
																{selectedColumns}
															</ul>
														</div>
													</div>
													<div className="containerColumns rowColumns">
														<div className="itemColumns">
															<button className="ModalButtonColumns" onClick={(e) => this.exportacaoSelectAll(e, true)}>Selecionar Todos</button>
														</div>
														<div className="itemColumns">
															<button className="ModalButtonColumns" onClick={(e) => this.exportacaoSelectAll(e, false)}>Limpar seleção</button>
														</div>
													</div>
												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={this.handleExport}>
													Exportar
												</button>
												<button className="ModalButton" onClick={this.closeModal}>
													Fechar
												</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: '',
											width: 93,
											Cell: (row) => {
												return (
													<div>
														<LinkContainer to={'/apergs/associados/registro?pk=' + row.row.pk_ass}>
															<Icon
																size={20}
																icon={edit}
																className="iconButton"
															/>
														</LinkContainer>
														<LinkContainer to={'/apergs/contas-receber/?filtered=true&fk_ass=' + row.row.pk_ass}>
															<Icon
																size={20}
																icon={card}
																className="iconButton"
															/>
														</LinkContainer>
														<Icon
															size={20}
															icon={iosTrash}
															name={row.row.pk_ass}
															className="iconButton"
															onClick={(e) => {
																this.handleDelete(e, row.row.pk_ass);
															}}
														/>
													</div>
												);
											}
										},
										{
											Header: 'Código',
											accessor: 'pk_ass',
											// show: false
											width: 50
										},

										{
											Header: 'Associado',
											accessor: 'nome',
											width: 420
										},
										{
											Header: 'Anape',
											accessor: 'stranape',
											width: 80
										},
										{
											Header: 'Celulares',
											accessor: 'celulares',
											width: 120
										},
										// {
										// 	Header: 'CPF',
										// 	accessor: 'cpf',
										// 	width: 110
										// },
										// {
										// 	Header: 'RG',
										// 	accessor: 'rg',
										// 	width: 110
										// },
										{
											Header: 'Categoria de Associado',
											accessor: 'nomecat',
											width: 150
										},
										{
											Header: 'Lotação',
											accessor: 'nomelot',
											width: 100
										},
										// {
										// 	Header: 'Dt Nasc',
										// 	accessor: 'data_nasc',
										// 	width: 90
										// },
										{
											Header: 'Situação',
											accessor: 'nomesit'
										},
										{
											Header: 'Aniversário',
											accessor: 'aniversario',
											width: 100
										},

									]}
									defaultSorted={[
										{
											id: 'nome',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
							<p style={{ float: 'right' }}>{this.state.data.length + ' registros encontrados'}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Associados;
