import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { garanteDate, asyncForEach, getParameterByName } from '../Utils';
import swal from '@sweetalert/with-react';


class ReabrirContasPagar extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			valorTotal: 0,
			selecionados: [],
			modal: { show: false },
			edit: false,
			filterChanged: '',
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	handleSave(e) {
		e.preventDefault();
		if (this.state.selecionados.length>0) {
			swal({
				title: 'Atenção!',
				text: 'O(s) registro(s) de baixa selecionado(s) será(ão) excluído(s). Confirma?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) this.submitData(e);
			});
		} else {
			swal('Erro!', 'Nenhum registro selecionado!', 'error')
		}
	}

	submitData(e) {
		e.preventDefault();
		let selecionados = this.state.selecionados
		let valorTotal = this.state.valorTotal
		let json = {selecionados: selecionados, valorTotal: valorTotal, pk_tit: Number(getParameterByName('pk'))}
		json = JSON.stringify(json)

		//Excluir
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/reabrirContasPagar', {
			method: 'POST',
			headers: {
				'Content-type': 'application/json'
			},
			body: json
		})
			.then((r) => r.json())
			.then((r) => {
				console.log(r);
				if (r.message === 'Success!') {
					swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
						window.history.back();
					});
				} else {
					swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
				}
			});

	}

	async componentDidMount() {
		var event = new Event('build');
		this.getData(event);
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_liq = garanteDate(item.data_liq);
				item.data_lancto = garanteDate(item.data_lancto);
			});
			resolve(data);
		});
	}

	async handleCheck(e, valor) {
		let selecionados = this.state.selecionados
		let valorTotal = this.state.valorTotal
		let index = selecionados.indexOf(e.target.name)
		if (index > -1) {
			if (!e.target.checked) {
				selecionados.splice(index,1);
				valorTotal = valorTotal - valor
			}
		} else {
			if (e.target.checked) {
				selecionados.push(e.target.name)
				valorTotal = valorTotal + valor
			}
		}
		// console.log(e.target.name, e.target.checked)
		this.setState({
			selecionados: selecionados,
			valorTotal: valorTotal
		})
		console.log(selecionados, valor, valorTotal)
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getBaixaTit?fk_tit='+getParameterByName('pk'))
			.then((r) => r.json())
			.then(async (r) => {
				if (!r[0]) swal('Erro!', 'Registro não possui liquidações.', 'error').then(r=>window.location.href = '/apergs/contas-pagar')
				//Trata
				let items = await this.modelingData(r);
				this.setState({ data: items });
			});
	}

	render() {
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="ReabrirContasPagar" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Reabrir Contas a Pagar</h3>
					</div>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<form id="reabrirContasPagar" name="reabrirContasPagar" onSubmit={this.submitData}>
								<div className="divTabela">
									<ReactTable
										data={this.state.data}
										previousText="Anterior"
										nextText="Próximo"
										loadingText="Carregando..."
										pageText="Página"
										ofText="de"
										rowsText="registros"
										noDataText="Nenhum registro encontrado"
										columns={[
											{
												Header: 'Selecionar',
												width: 65,
												Cell: (row) => {
													return (
														<div className="buttonsDetailColumn">
															<input 
																type="checkbox" 
																name={row.row.pk_bti}
																onChange={(e)=>this.handleCheck(e, row.row.valor)}
															/>
														</div>
													);
												}
											},
											{
												Header: 'Código',
												accessor: 'pk_bti',
												show: false
											},
											{
												Header: 'Data Lançamento',
												accessor: 'data_lancto',
												width: 110
											},
											{
												Header: 'Data Pagamento',
												accessor: 'data_liq',
												width: 110
											},
											{
												Header: 'Valor Pago',
												accessor: 'valor',
												width: 100,
												Cell: (row) => {
													return (
														<div>
															{Number(row.original.valor).toLocaleString('pt-BR', {
																style: 'currency',
																currency: 'BRL'
															})}
														</div>
													);
												}
											},
											{
												Header: 'Observação',
												accessor: 'observacao',
												width: 400
											},
											
										]}
										defaultSorted={[
											{
												id: 'numero',
												desc: true
											}
										]}
										defaultPageSize={10}
										className="-striped -highlight"
									/>
								</div>
							</form>
							<div className="buttonsCadastro" style={{marginTop: '30px'}}>
								<button className="buttonSalvar" style={{width: '150px'}} onClick={this.handleSave}>
									Excluir selecionado(s)
								</button>
								<button className="buttonVoltar" onClick={this.handleBack}>
									Voltar
								</button>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ReabrirContasPagar;
