import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import ReactLoading from 'react-loading';
import { populateForm, delay } from '../Utils';
import swal from '@sweetalert/with-react';

class RegistroRemessasBanrisul extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			modal: { show: false },
			edit: false,
			remessaBanrisulStatus: { message: 'Not started', info: [] },
			filterChanged: '',
			data: [],
			filteredData: []
		};

		this.handleSave = this.handleSave.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.submitData = this.submitData.bind(this);
		this.filterData = this.filterData.bind(this);
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	handleSave(e) {
		e.preventDefault();
		const form = document.getElementById('gerarRemessasBanrisul');
		const data = new FormData(form);
		let competencia = '';
		let competencia_ano = '';
		let competencia_mes = '';

		if (Number(data.get('competencia_mes') < 10)) {
			competencia_mes = String('0' + data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		} else {
			competencia_mes = String(data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		}

		// let orgao = data.get('orgao')

		if (
			competencia
		) {
			swal({
				title: 'Atenção!',
				text: 'Deseja gerar o arquivo de remessa do Banrisul de ' + competencia_mes + '/20' + competencia_ano + '?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) this.submitData(e, data);
			});
		} else {
			swal('Rotina não executada', 'Rotina não foi executada. Competência/Débito ou órgão inválido(a).', 'error');
		}
	}

	async submitData(e, data) {
		let strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 });
		let auth = JSON.parse(strAuth);

		this.setState({ remessaBanrisulStatus: { message: 'Started', info: [] } });
		fetch(
			config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/gerarRemessasBanrisul?competencia=' +
				data.get('competencia') +
				'&data_debito='+
				data.get('data_debito')+
				// '&orgao=' +
				// data.get('orgao') +
				'&fk_usu=' +
				auth.user,
			{
				method: 'GET',
				headers: {
					'Content-type': 'application/json'
				}
			}
		)
			.then(async (r) => r.json())
			.then(async (r) => {
				console.log(r);
				if (r.message === 'Success!') {
					while (this.state.remessaBanrisulStatus.message !== 'Done') {
						await fetch(
							config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/api/checkRemessasBanrisul',
							{
								method: 'GET',
								headers: {
									'Content-type': 'application/json'
								}
							}
						)
							.then(async (r) => r.json())
							.then(async (r) => {
								this.setState({ remessaBanrisulStatus: r });
								swal({
									title: 'Gerando remessa do Banrisul',
									text: this.state.remessaBanrisulStatus.message,
									// icon: "info",
									closeOnClickOutside: false,
									buttons: false,
									// showLoaderOnConfirm: true,
									content: (
										<div style={{ width: '100%' }}>
											<div style={{ display: 'table', margin: '0 auto' }}>
												<ReactLoading
													type={'bubbles'}
													color={'#004136'}
													height={100}
													width={100}
												/>
											</div>
										</div>
									)
								});
							});
						if (this.state.remessaBanrisulStatus.message === 'Error') {
							break;
						}
						await delay(1000);
					}
					if (this.state.remessaBanrisulStatus.message === 'Error') {
						swal('Geração não concluída', this.state.remessaBanrisulStatus.info[0], 'error');
					} else {
						const link = document.createElement('a');
						link.href = config.protocol + '://' + config.server + ':' +	config.portBackend + '/api/download/banrisul/remessa?competencia='+data.get('competencia')
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						swal('Geração concluída', 'As informações foram geradas com sucesso!', 'success').then((res) => {
							window.history.back();
						});
						this.setState({data: this.state.remessaBanrisulStatus.info, filteredData: this.state.remessaBanrisulStatus.info})
					}
				} else {
					swal('Geração não concluída', 'Houve um problema na geração.', 'error');
				}
			});
	}

	async componentDidMount() {
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let selectedCidade = this.state.combos.filter((item) => {
						return item.type === 'cidades' && item.value === r[0].fk_cid;
					});
					this.setState({
						selected: {
							cidade: selectedCidade[0]
						}
					});
					let form = document.getElementById('gerarRemessasBanrisul');
					r[0].competencia_mes = Number(r[0].competencia.substring(0, 2));
					r[0].competencia_ano = Number(r[0].competencia.substring(2, 4));
					console.log(r[0].competencia_mes, r[0].competencia_ano);
					populateForm(form, r[0]);
				}
			});
	}

	filterData(e){
		// e.preventDefault();
		if (e.target.checked) {
			this.setState({filteredData: this.state.data.filter((value)=>{ return value.valor_incluir > 0 || value.valor_excluir > 0 })})
		} else {
			this.setState({filteredData: this.state.data})
		}
	}


	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}

	populaVencto(item, id) {
		return <option value={item}>{item}</option>;
	}

	listaVencto() {
		let listaVencto = [];
		for (let i = 1; i <= 31; i++) {
			listaVencto.push(i);
		}
		return listaVencto;
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Gerar Remessas - Banrisul</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="gerarRemessasBanrisul" name="gerarRemessasBanrisul" onSubmit={this.submitData}>
								<div className="col-sm-12" style={{ marginTop: '15px', marginBottom: '15px' }}>
									<label>Competência/Débito:</label>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_mes">Mês:</label>
									<select name="competencia_mes" className="form-control">
										<option value="">Selecione</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_ano">Ano:</label>
									<select name="competencia_ano" className="form-control">
										<option value="">Selecione</option>
										{anos}
									</select>
								</div>
								<div>
									<label className="labelModal">
										Data para débito:
									</label>
									<input
										type="date"
										name="data_debito"
										id="data_debito"
										className="form-control"
										style={{ width: '120px' }}
									/>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar
							</button>
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '10px' }}
								onClick={this.handleBack}
							>
								Voltar
							</button>
						</div>
					</div>
					{/* <input type = 'checkbox' onChange={this.filterData}/>
					<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.filteredData}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: 'Associado',
											accessor: 'nome',
											width: 400
										},
										{
											Header: 'Matrícula',
											accessor: 'matricula',
											width: 100
										},
										{
											Header: 'Contrato',
											accessor: 'contrato',
											width: 300
										},
										{
											Header: 'Valor Anterior',
											accessor: 'valor_anterior',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_anterior).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Atual',
											accessor: 'valor_atual',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_atual).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Incluir',
											accessor: 'valor_incluir',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_incluir).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Excluir',
											accessor: 'valor_excluir',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_excluir).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},										
										{
											Header: 'Reajuste',
											accessor: 'reajuste',
											width: 80,

										}							
									]}
									defaultSorted={[
										{
											id: 'pk_bco',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div> */}
				</div>
			</div>
		);
	}
}

export default RegistroRemessasBanrisul;
