import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import $ from 'jquery';
import '../css/Default.css';
import { Modal, ListGroupItem } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import MultiSelect from "@khanacademy/react-multi-select";
import {  asyncForEach, populateForm, getParameterByName } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import {email} from 'react-icons-kit/ionicons/email'
import {view} from 'react-icons-kit/ikons/view'
import ReactLoading from 'react-loading';
import Autocomplete from '../components/Autocomplete';

class Demonstrativos extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			file: '',
			associados: [],
			filter: [],
			modal: { show: false },
			modalSend: { show: false },
			registro: {},
			edit: false,
			defaultFilePath: '',
			filterChanged: '',
			data: []
		};
		this.handleSelectedFile = this.handleSelectedFile.bind(this);
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.showModal = this.showModal.bind(this);
		this.showModalSend = this.showModalSend.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.closeModalSend = this.closeModalSend.bind(this);
		this.modalState = this.modalState.bind(this);
		this.modalSendState = this.modalSendState.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSendAll = this.handleSendAll.bind(this);
		this.filterAssociado = this.filterAssociado.bind(this);
	}

	closeModal() {
		this.setState({ modal: { show: false } });
	}

	closeModalSend() {
		this.setState({ modalSend: { show: false } });
	}

	filterAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ass = item.value;
		newFilter.associado = item;
		this.setState({ filter: newFilter });
	}


	async showModal(e, associados) {
		e.preventDefault();
		let form = document.getElementById('gerarDemonstrativos');
		form.reset();
		let values = []
		asyncForEach(associados, async (item, index) => {
			values.push(item.value) 
			if (index+1 === associados.length) {
				this.setState({ modal: { show: true }, modalSend: { show: false }, associados: values });
			}
		})
		
	
	}

	async showModalSend(e, registro) {
		e.preventDefault();
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados?pk='+registro.fk_ass)
		.then((r) => r.json())
		.then(async (r) => {
			if (typeof r[0] === 'undefined') {
				window.location.href = '/home';
			} else {
				let form = document.getElementById('enviarDemonstrativos');
				form.reset();
				r[0].fk_ass = registro.fk_ass
				r[0].competencia = registro.competencia
				r[0].arquivo = registro.arquivo
				if (r[0].envia_email_resid === 'S') {
					r[0].email = r[0].email_resid
				} else if (r[0].envia_email_com === 'S') {
					r[0].email = r[0].email_comerc
				}

				populateForm(form, r[0]);
			}
		});

		this.setState({ modalSend: { show: true }, modal: { show: false }, registro: registro });
	}

	

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalSendState() {
		if (this.state.modalSend.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	handleSave(e) {
		e.preventDefault();
		let competencia =
		String(
			Number($('#competencia_mes').val()) < 10
				? '0' + $('#competencia_mes').val()
				: $('#competencia_mes').val()
		) + String($('#competencia_ano').val());
		console.log(this.state.associados.length, competencia.length)
		if (this.state.associados.length>0 && competencia.length===4) {
			swal({
				title: 'Atenção!',
				text: 'Deseja iniciar a geração?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) this.submitData(e, competencia, this.state.associados);
			});
		} else {
			swal({
				title: 'Erro!',
				text: 'Nenhum associado informado ou Competência/Débito inválida!',
				icon: 'error'
			});
		}
	}

	handleSendAll(e) {
		e.preventDefault();
		let competencia =
		String(
			Number($('#competencia_mes').val()) < 10
				? '0' + $('#competencia_mes').val()
				: $('#competencia_mes').val()
		) + String($('#competencia_ano').val());
		console.log(this.state.associados.length, competencia.length)
		if (this.state.associados.length>0 && competencia.length===4) {
			swal({
				title: 'Atenção!',
				text: 'Deseja iniciar o envio geral?',
				buttons: [ 'Sim', 'Não' ],
				// content: "input",
			}).then((result) => {
				console.log(result)
				if (!result) {
					swal({
						title: 'Informe de qual registro começar!',
						// text: 'Deseja iniciar o envio geral?',
						content: "input",
					}).then((result) => {
						console.log(result+' start')
						if ((Number(result) > 0) && (result !== null)) this.sendEmail(e, competencia, this.state.associados, result);
					})	
				}
			});
		} else {
			swal({
				title: 'Erro!',
				text: 'Nenhum associado informado ou Competência/Débito inválida!',
				icon: 'error'
			});
		}
	}

	async handleSend(e) {
		e.preventDefault();
		swal({
			title: 'Enviando email',
			text: 'Preparando envio do demonstrativo...',
			closeOnClickOutside: false,
			buttons: false,
			// showLoaderOnConfirm: true,
			content: (
				<div style={{ width: '100%' }}>
					<div style={{ display: 'table', margin: '0 auto' }}>
						<ReactLoading
							type={'bubbles'}
							color={'#004136'}
							height={100}
							width={100}
						/>
					</div>
				</div>
			)
		});
		console.log('a')
		let form = new FormData(document.getElementById('enviarDemonstrativos'));

		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/enviarDemonstrativo?fk_ass='+form.get('fk_ass')+'&competencia='+form.get('competencia')+'&contas=true&email='+form.get('email'))
		.then((r) => r.json())
		.then(async (r) => {
			console.log('b')
			if (r) {
				if (r.rejected && r.rejected[0]) {
					swal('Email não enviado', 'Houve um problema no envio do email!', 'error');
				} else {
					swal('Email enviado', 'Demonstrativo enviado por email com sucesso!', 'success');
					this.closeModalSend();
				}
			} else {
				swal('Email não enviado', 'Houve um problema no envio do email!', 'error');	
			}
		});
	}

	async sendEmail(e, competencia, associados, start) {
		// e.preventDefault();
		this.setState({ modal: { show: false } });
		try {
			await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/enviarDemonstrativosGerados?start='+start+'&competencia='+competencia+'&contas=true', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: JSON.stringify(associados)
			})
			.then((r) => r.json())
			.then(async (r) => {
				swal({
					title: 'Enviando Demonstrativos',
					text: 'Enviando emails',
					closeOnClickOutside: false,
					buttons: false,
					// showLoaderOnConfirm: true,
					content: (
						<div style={{ width: '100%' }}>
							<div style={{ display: 'table', margin: '0 auto' }}>
								<ReactLoading
									type={'bubbles'}
									color={'#004136'}
									height={100}
									width={100}
								/>
							</div>
						</div>
					)
				});
			})
		} catch (err) {
			swal('Geração não concluída', 'Ocorreu um erro na geração!', 'error');
		}
		

	}

	async submitData(e, competencia, associados) {
		// e.preventDefault();
		this.setState({ modal: { show: false } });
		try {
			asyncForEach(associados, async (item, index) => {
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/gerarDemonstrativo?fk_ass='+item+'&competencia='+competencia)
				.then((r) => r.json())
				.then(async (r) => {
		
					if (r.status !== 'success') throw r
					swal({
						title: 'Gerando Demonstrativos',
						text: 'Gerando arquivos ('+(index+1)+'/'+associados.length+')',
						closeOnClickOutside: false,
						buttons: false,
						// showLoaderOnConfirm: true,
						content: (
							<div style={{ width: '100%' }}>
								<div style={{ display: 'table', margin: '0 auto' }}>
									<ReactLoading
										type={'bubbles'}
										color={'#004136'}
										height={100}
										width={100}
									/>
								</div>
							</div>
						)
					});
	
				})

				if (index+1 === associados.length) { 
					console.log($('#enviar_email').val())
					if ($('#enviar_email').val() === 'S') {
						asyncForEach(associados, async (item, index) => {
							// if (index>45) {
								await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/enviarDemonstrativo?fk_ass='+item+'&competencia='+competencia+'&contas=true')
								.then((r) => r.json())
								.then(async (r) => {
									swal({
										title: 'Enviando Demonstrativos',
										text: 'Enviando emails ('+(index+1)+'/'+associados.length+')',
										closeOnClickOutside: false,
										buttons: false,
										// showLoaderOnConfirm: true,
										content: (
											<div style={{ width: '100%' }}>
												<div style={{ display: 'table', margin: '0 auto' }}>
													<ReactLoading
														type={'bubbles'}
														color={'#004136'}
														height={100}
														width={100}
													/>
												</div>
											</div>
										)
									});

									
								})
							// }
							if (index+1 === associados.length) { 
								swal('Geração concluída', 'Os Demonstrativos foram gerados e enviados com sucesso!', 'success');
							}
						})
					} else {
						console.log('concluiu')
						swal('Geração concluída', 'Os Demonstrativos foram gerados com sucesso!', 'success');
					}
				}
			})
		} catch (err) {
			swal('Geração não concluída', 'Ocorreu um erro na geração!', 'error');
		}
		

	}

	async componentDidMount() {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let comboAssociados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let associados = {
						type: 'associados',
						display: item.nome,
						label: item.nome,
						value: item.pk_ass
					};
					await comboAssociados.push(associados);
				});
				this.setState({ combos: this.state.combos });
			});

		// await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros')
		// 	.then((r) => r.json())
		// 	.then(async (r) => {
		// 		this.setState({ defaultFilePath: r[0].pasta_retorno_demonstrativos });
		// 	});

		//Carregar Parâmetros de pesquisa
		let query = {};
		// query.filtered = true;
		query.filtered = getParameterByName('filtered');
		query.matricula = getParameterByName('matricula');
		query.competenciaAno = getParameterByName('competenciaAno');
		query.competenciaMes = getParameterByName('competenciaMes');
		query.tipo = getParameterByName('tipo');
		query.fk_ass = Number(getParameterByName('fk_ass'));
		query.associado = this.state.combos.filter((item) => {
			return item.type === 'associados' && Number(item.value) === Number(query.fk_ass);
		})[0];


		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/demonstrativos'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.competenciastr = item.competencia.substr(0, 2) + '/20' + item.competencia.substr(2, 2);
			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let filtered = await data.filter((item) => {

				// //Filtro: Competencia - Ano
				let competenciaAno = ((item.competencia || '').substr(2, 2) || '')
					.includes((filter.competenciaAno || '').toUpperCase());

				// //Filtro: Competencia - Mes
				let competenciaMes = ((item.competencia || '').substr(0, 2) || '')
					.includes((filter.competenciaMes || '').toUpperCase());

				//Filtro: Associado
				let fk_ass = Number(item.fk_ass) === Number(filter.fk_ass) || (filter.fk_ass || '') === '';


				//Filtro: Matrícula
				let matricula = (item.matricula || '').includes((filter.matricula || '').toUpperCase());

				//Filtro: Espécie
				let especie = (item.especie || '').includes((filter.especie || '').toUpperCase());

				//Filtra
				return fk_ass && competenciaMes && competenciaAno && matricula && especie;
			});

			let queryString = '?';


			if (filter.competenciaAno) {
				if (queryString === '?') {
					queryString = queryString + 'competenciaAno=' + filter.competenciaAno;
				} else queryString = queryString + '&competenciaAno=' + filter.competenciaAno;
			}

			if (filter.competenciaMes) {
				if (queryString === '?') {
					queryString = queryString + 'competenciaMes=' + filter.competenciaMes;
				} else queryString = queryString + '&competenciaMes=' + filter.competenciaMes;
			}

			if (filter.fk_ass) {
				if (queryString === '?') {
					queryString = queryString + 'fk_ass=' + filter.fk_ass;
				} else queryString = queryString + '&fk_ass=' + filter.fk_ass;
			}

		
			if (filter.matricula) {
				if (queryString === '?') {
					queryString = queryString + 'matricula=' + filter.matricula;
				} else queryString = queryString + '&matricula=' + filter.matricula;
			}


			//Monta Query URL
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/demonstrativos' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/demonstrativos?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();

		if (this.state.filter.competenciaMes && this.state.filter.competenciaAno) {
			let competencia =
			Number(this.state.filter.competenciaMes) < 10
				? '0' + this.state.filter.competenciaMes + this.state.filter.competenciaAno
				: this.state.filter.competenciaMes + this.state.filter.competenciaAno;
			//Busca
			await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getDemonstrativos?competencia='+competencia)
			.then((r) => r.json())
			.then(async (r) => {
				//Filtra
				console.log(r)
				let items = await this.filterData(r);
				//Trata
				items = await this.modelingData(items);
				this.setState({ data: items });
			});
		} else {
			swal('Pesquisa não realizada', 'Informe a Competência/Débito!', 'error');
		}
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	handleSelectedFile(e) {
		$('#nome').val(e.target.files[0].name);
		this.setState({ file: e.target.files[0] });
		console.log(e.target.files[0]);
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaNaoEncontrados(item, id) {
		return (
			<ListGroupItem id={'list' + id}>
				Matricula:{' '}
				{item.matricula+',  '}
				Contrato:{' '}
				{item.contrato}
				<br />
				{/* <label className="labelSwal">Carteira:</label>
                <input name="codigo" type="text" id={id} className="inputSwal" style={{width: '40%'}} defaultValue='0'></input> */}
			</ListGroupItem>
		);
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});

		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Demonstrativos" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Remessas - demonstrativos</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
							<div className="itemFiltro">
									<label className="labelFiltro">Competência/Débito</label>
									<select
										name="competenciaMes"
										id="filtroCompetenciaMes"
										className="selectFiltro"
										value={this.state.filter.competenciaMes || ''}
										onChange={this.handleChange}
									>
										<option value="">Mês</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>
									</select>
									<select
										name="competenciaAno"
										id="filtroCompetenciaAno"
										className="selectFiltro"
										value={this.state.filter.competenciaAno || ''}
										onChange={this.handleChange}
									>
										<option value="">Ano</option>
										{anos}
									</select>
								</div>
								<div className="itemFiltro" style={{ width: '250px' }}>
									<label className="labelFiltro">Associado</label>
									<Autocomplete
										field="filtroFk_ass"
										items={associados}
										inModal=""
										selected={this.state.filter.associado || { display: '' }}
										select={this.filterAssociado}
									/>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								
									<button className="buttonNovo" onClick = {(e)=>this.showModal(e, associados)}>
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Gerar Demonstrativos
									</button>

							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Gerar Demonstrativos</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="gerarDemonstrativos" name="gerarDemonstrativos">
												<div>
													<label htmlFor="enviar_email" className="labelModal">
														Associados:
													</label>
													<MultiSelect
														options={associados}
														selected={this.state.associados}
														onSelectedChanged={selected => this.setState({associados: selected})}
														className="multiSelectFiltro"
														overrideStrings={{
															selectSomeItems: "Selecione um ou mais itens...",
															allItemsAreSelected: "Todos",
															selectAll: "SELECIONAR TODOS",
															search: "Pesquisar",
														}}
													/>
												</div>
												<div>
													<label htmlFor="competencia_mes" className="labelModal">
														Competência/Débito - Mês:
													</label>
													<select
														name="competencia_mes"
														id="competencia_mes"
														className="form-control"
														style={{ width: '150px' }}
													>
														<option value="">Selecione</option>
														<option value="1">JANEIRO</option>
														<option value="2">FEVEREIRO</option>
														<option value="3">MARÇO</option>
														<option value="4">ABRIL</option>
														<option value="5">MAIO</option>
														<option value="6">JUNHO</option>
														<option value="7">JULHO</option>
														<option value="8">AGOSTO</option>
														<option value="9">SETEMBRO</option>
														<option value="10">OUTUBRO</option>
														<option value="11">NOVEMBRO</option>
														<option value="12">DEZEMBRO</option>
													</select>
												</div>
												<div>
													<label htmlFor="competencia_ano" className="labelModal">
														Competência/Débito - Ano:
													</label>
													<select
														name="competencia_ano"
														id="competencia_ano"
														className="form-control"
														style={{ width: '100px' }}
													>
														<option value="">Selecione</option>
														{anos}
													</select>
												</div>
												<div>
													<label htmlFor="enviar_email" className="labelModal">
														Enviar email:
													</label>
													<select
														name="enviar_email"
														id="enviar_email"
														className="form-control"
														style={{ width: '100px' }}
													>
														<option value="">Não</option>
														<option value="S">Sim</option>
													</select>
												</div>
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSendAll}>
												Enviar Gerados *
											</button>
											<button className="ModalButton" onClick={this.handleSave}>
												Gerar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Fechar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div className={this.modalSendState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Enviar Demonstrativo</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="enviarDemonstrativos" name="enviarDemonstrativos">
												<div>
													<label className="labelModal">Email</label>
													<input
														type="text"
														id="email"
														name="email"
														className="form-control"
														data-parse="uppercase"
													/>
													<input
														type="text"
														id="competencia"
														name="competencia"
														className="form-control"
														data-parse="uppercase"
														hidden
													/>
													<input
														type="text"
														id="fk_ass"
														name="fk_ass"
														className="form-control"
														data-parse="uppercase"
														hidden
													/>
												</div>											
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSend}>
												Enviar
											</button>
											<button className="ModalButton" onClick={this.closeModalSend}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: 'Arquivo',
											accessor: 'arquivo',
											width: 100,
											show: false
										},
										{
											Header: 'Associado',
											accessor: 'nomeAss',
											width: 700
										},
										{
											Header: 'Competência/Débito',
											accessor: 'competenciastr',
											width: 250
										},
										{
											Header: 'Competência/Débito',
											accessor: 'competencia',
											width: 100,
											show: false
										},		
										{
											Header: 'Código Associado',
											accessor: 'fk_ass',
											width: 100,
											show: false
										},						
										{
											Header: 'Opções',
											minWidth: 300,
											maxWidth: 300,
											Cell: (row) => {
												return (
													<div className="buttonsDetailColumn">
														<LinkContainer to={'/apergs/demonstrativos/visualizar?arquivo='+ row.row.arquivo+'&competencia='+ row.row.competencia}>
															<button
																className="buttonDetailColumn"
															>
																<Icon size={20} icon={view} style={{ marginRight:'5px' }}/>
																Visualizar
															</button>
														</LinkContainer>
														<button
															className="buttonDetailColumn"
															onClick={(e) => {
																this.showModalSend(e, row.row);
															}}
														>
															<Icon size={20} icon={email} />
															Enviar Email
														</button>
													</div>
												);
											}
										}														
									]}
									defaultSorted={[
										{
											id: 'pk_bco',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Demonstrativos;
