import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import $ from 'jquery';
// import {LinkContainer}  from 'react-router-bootstrap'
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName, populateForm, onBlurCurrency, dateSql, now, ordenar } from '../Utils';
import swal from '@sweetalert/with-react';
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroContasReceber extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			recebido: 0,
			automatico: false,
			filter: [],
			selected: {
				cidadeComerc: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.submitData = this.submitData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleSetValor = this.handleSetValor.bind(this);
		this.selectAssociado = this.selectAssociado.bind(this);
		this.populaTipoServicos = this.populaTipoServicos.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.selectCidadeComerc = this.selectCidadeComerc.bind(this);
		this.selectCidadeResid = this.selectCidadeResid.bind(this);
	}

	selectCidadeComerc(item) {
		let selected = this.state.selected;
		selected.cidadeComerc = item;
		this.setState({ selected: selected });
	}

	selectCidadeResid(item) {
		let selected = this.state.selected;
		selected.cidadeResid = item;
		this.setState({ selected: selected });
	}

	populaTipoServicos(item, id) {
		if (item.type === 'tipoServicos') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	handleSetValor(value) {
		console.log(value)
		let item = this.state.combos.filter((item) => {
			return item.type === 'tipoServicos' && Number(item.value||0) === Number(value||0)
		})
		let associado = this.state.selected.associado

		if (item[0]) $('#valor').val(item[0].valor > 0 ? item[0].valor.toFixed(2).replace('.', ',') : '')

		let forma_cobranca = ''
		if (associado && value) {
			console.log('a')
			if (Number(value||0) === 1) {
				forma_cobranca = associado.cobrancaMensalidade||''
			} else if (Number(value||0) === 2) {
				forma_cobranca = associado.cobrancaTelefonia||''
			} else if (Number(value||0) === 3 || Number(value||0) === 5) {
				forma_cobranca = associado.cobrancaUnimed||''
			} else forma_cobranca = associado.cobrancaOutros||''
		}

		$('#forma_cobranca').val(forma_cobranca)

	}

	async componentDidMount() {
		let competenciaMes = ''
		let competenciaAno = ''
		//Busca valores para combo de filtro
		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let comboAssociados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let associados = {
						type: 'associados',
						cobrancaTelefonia: item.cobranca_telefonia,
						cobrancaUnimed: item.cobranca_unimed,
						cobrancaMensalidade: item.cobranca_mensalidade,
						cobrancaOutros: item.cobranca_servicos,
						display: item.nome,
						value: item.pk_ass
					};
					await comboAssociados.push(associados);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
		.then((r) => r.json())
		.then(async (r) => {
			if (typeof r[0] === 'undefined') {
				window.location.href = '/home';
			} else {
				competenciaMes = Number(r[0].competencia.substring(0, 2));
				competenciaAno = Number(r[0].competencia.substring(2, 4));
			}
		});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTiposServicos')
			.then((r) => r.json())
			.then(async (r) => {
				let comboTipoServicos = this.state.combos;
				await asyncForEach(r, async (item) => {
					let tipoServico = {
						type: 'tipoServicos',
						valor: item.valor,
						display: item.descricao,
						value: item.pk_ser
					};
					await comboTipoServicos.push(tipoServico);
				});
				this.setState({ combos: this.state.combos });
			});

		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));
		query.fk_ass = Number(getParameterByName('fk_ass'));

		ordenar('fk_ser')

		if (query.pk > 0) {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/getContasReceber?pk=' +
					query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/contas-receber';
					} else {
						let form = document.getElementById('registroContasReceber');
						let selectedAssociado = this.state.combos.filter((item) => {
							return item.type === 'associados' && item.value === r[0].fk_ass;
						});
						this.setState({
							selected: {
								associado: selectedAssociado[0]
							}
						});
						r[0].competenciaAno = r[0].competencia.substring(2, 4);
						r[0].competenciaMes = Number(r[0].competencia.substring(0, 2));
						r[0].data_venc = dateSql(r[0].data_venc);
						r[0].data_emis = dateSql(r[0].data_emis);
						r[0].data_geracao = dateSql(r[0].data_geracao);
						r[0].data_liq = dateSql(r[0].data_liq);
						this.setState({ recebido: Number(r[0].valor_recebido) });
						r[0].valor_pendente = (Number(r[0].valor) - Number(r[0].valor_recebido))
							.toFixed(2)
							.replace('.', ',');
						r[0].valor = r[0].valor.toFixed(2).replace('.', ',');
						$('#valor_pendente').val(r[0].valor_pendente);
						if (r[0].automatico === 'S') {
							$('input[name=valor]').prop('disabled', true);
							$('#inputfk_ass').prop('disabled', true);
							$('input[name=data_venc]').prop('disabled', true);
							$('input[name=data_emis]').prop('disabled', true);
							$('select[name=fk_ser]').prop('disabled', true);
							$('select[name=competenciaAno]').prop('disabled', true);
							$('select[name=competenciaMes]').prop('disabled', true);
							$('select[name=forma_cobranca]').prop('disabled', true);
						}
						console.log(r[0]);
						populateForm(form, r[0]);
					}
				});
			this.setState({ edit: true });
		} else {
			let form = document.getElementById('registroContasReceber');
			if (query.fk_ass) {
				let selectedAssociado = this.state.combos.filter((item) => {
					return item.type === 'associados' && Number(item.value) === Number(query.fk_ass);
				});
				this.setState({
					selected: {
						associado: selectedAssociado[0]
					}
				});
				
				
			}
			let fk_ser = '' 
			if (Number(localStorage.getItem("ultimoFk_ser")||0) > 0) {
				fk_ser = localStorage.getItem("ultimoFk_ser")
				this.handleSetValor(fk_ser)
			}
			populateForm(form, {fk_ass: Number(query.fk_ass), fk_ser: fk_ser, competenciaMes: competenciaMes, competenciaAno: competenciaAno})
			
		}

		

	}

	

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
	}

	async modelingData(data) {
		//Trata os campos
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	validateData(data) {
		let valid = { isValid: true };
		//Consistências

		//Associado
		if (Number(data.get('fk_ass') || 0) === 0) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo ASSOCIADO.' };
		}

		//Competência/Débito
		if (Number(data.get('competenciaMes' || 0)) === 0 || Number(data.get('competenciaAno' || 0)) === 0) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo Competência/Débito.' };
		}

		// //Data de Emissão
		// if (!data.get('data_emis')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DATA DE EMISSÃO.' };
		// }

		// //Data de Vencimento
		// if (!data.get('data_venc')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DATA DE VENCIMENTO.' };
		// }

		//Valor
		if (Number(data.get('valor' || 0)) === 0) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo VALOR.' };
		}

		//Tipo de Serviço
		if (Number(data.get('fk_ser' || 0)) === 0) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo TIPO DE SERVIÇO.' };
		}

		//Forma de Cobrança
		// if (!data.get('forma_cobranca')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo FORMA DE COBRANÇA.' };
		// }

		console.log(valid);
		//Retorna resultado
		return valid;
	}

	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroContasReceber');
		const data = new FormData(form);
		let strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 });
		let auth = JSON.parse(strAuth);
		let competencia = '';
		let competencia_ano = '';
		let competencia_mes = '';
		let forma_cobranca = this.state.selected.associado.cobrancaOutros
		data.set('forma_cobranca', forma_cobranca);

		if (Number(data.get('competenciaMes') < 10)) {
			competencia_mes = String('0' + data.get('competenciaMes'));
			competencia_ano = String(data.get('competenciaAno'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		} else {
			competencia_mes = String(data.get('competenciaMes'));
			competencia_ano = String(data.get('competenciaAno'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		}

		let validate = this.validateData(data);

		if (!validate.isValid) {
			swal(validate.title, validate.message, 'error');
			throw validate;
		}

		data.delete('competenciaMes');
		data.delete('competenciaAno');
		data.delete('valor_pendente');

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			//console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof (input || []).type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			object[key] = value;
		});
		object.valor = Number(object.valor.replace(',', '.'))
		object.automatico = 'N';
		object.data_geracao = now();
		object.fk_usugeracao = auth.user;

		localStorage.setItem("ultimoFk_ser", String(object.fk_ser));

		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {

			//Editar
			console.log(json);
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editContasReceber?pk=' +
					getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success', {
							buttons: false,
							timer: 1000,
						  }).then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoContasReceber', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success', {
							buttons: false,
							timer: 1000,
						  }).then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	calculaPendente(e) {
		e.preventDefault();
		let valor = Number(e.target.value.replace(',', '.'));
		$('#valor_pendente').val((valor - this.state.recebido).toFixed(2).replace('.', ','));
	}

	selectAssociado(item) {
		this.setState({
			selected: {
				associado: item
			}
		});
		this.handleSetValor($('forma_cobranca').val())
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let tipoServicos = this.state.combos.map(this.populaTipoServicos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});
		console.log(this.state.combos);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="ContasReceber" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Contas a Receber</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroContasReceber" name="registroContasReceber" onSubmit={this.submitData}>
								<div style={{ height: 'auto' }}>
									{/* <Tabs defaultActiveKey={1} animation={false} id="uncontrolled-tab-example"> */}
									<div className="boxRegistro">
										<div
											style={{
												paddingTop: '30px'
											}}
										>
											<div className="row">
												<div
													className="col-sm-4"
													style={{ height: 'auto', marginBottom: '-10px' }}
												>
													<label>Associado:</label>
													<Autocomplete
														field="fk_ass"
														items={associados}
														inModal=""
														selected={this.state.selected.associado}
														select={this.selectAssociado}
													/>
												</div>
												<div className="col-sm-4" style={{ width: '200px' }}>
													<label>Competência/Débito:</label>
													<br />
													<select
														name="competenciaMes"
														id="competenciaMes"
														className="selectFiltro"
														// value={this.state.filter.competenciaMes || ''}
														onChange={this.handleChange}
													>
														<option value="">Mês</option>
														<option value="1">JANEIRO</option>
														<option value="2">FEVEREIRO</option>
														<option value="3">MARÇO</option>
														<option value="4">ABRIL</option>
														<option value="5">MAIO</option>
														<option value="6">JUNHO</option>
														<option value="7">JULHO</option>
														<option value="8">AGOSTO</option>
														<option value="9">SETEMBRO</option>
														<option value="10">OUTUBRO</option>
														<option value="11">NOVEMBRO</option>
														<option value="12">DEZEMBRO</option>
													</select>
													<select
														name="competenciaAno"
														id="competenciaAno"
														className="selectFiltro"
														// value={this.state.filter.competenciaAno || ''}
														onChange={this.handleChange}
														style={{ marginLeft: '5px' }}
													>
														<option value="">Ano</option>
														{anos}
													</select>
												</div>
												<div className="col-sm-3" style={{ width: '230px' }}>
													<label>Tipo de Serviço:</label>
													<select
														name="fk_ser"
														id="fk_ser"
														className="form-control"
														data-parse="uppercase"
														required="required"
														onChange={(e) => this.handleSetValor(e.target.value)}
													>
														{tipoServicos}
														<option value="">Selecione</option>
													</select>
												</div>
												{/* <div className="col-sm-2" style={{ width: '180px' }}>
													<label>Data Emissão:</label>
													<input type="date" name="data_emis" className="form-control" />
												</div> */}
												{/* <div className="col-sm-2" style={{ width: '180px' }}>
													<label>Data Vencimento:</label>
													<input type="date" name="data_venc" className="form-control" />
												</div> */}
												<div className="col-sm-2" style={{ width: '140px' }}>
													<label>Valor (R$):</label>
													<input
														type="text"
														id="valor"
														name="valor"
														className="form-control"
														onBlur={(e) => {
															onBlurCurrency(e);
															this.calculaPendente(e);
														}}
													/>
												</div>
												<div className="col-sm-2" style={{ width: '180px' }}>
													<label>Data Recebimento:</label>
													<input
														type="date"
														name="data_liq"
														className="form-control"
														disabled
													/>
												</div>
												<div className="col-sm-2" style={{ width: '140px' }}>
													<label>Valor Pendente (R$):</label>
													<input
														type="text"
														id="valor_pendente"
														name="valor_pendente"
														className="form-control"
														onBlur={onBlurCurrency}
														disabled
													/>
												</div>
												<div className="col-sm-3" style={{ width: '230px' }} hidden>
													<label>Forma de Cobrança:</label>
													<input
														name="forma_cobranca"
														id="forma_cobranca"
														className="form-control"
														data-parse="uppercase"
													/>
												</div>
												<div className="col-sm-12">
													<label>Observações:</label>
													<textarea
														type="text"
														name="observacao"
														data-parse="uppercase"
														className="form-control"
														style={{ height: '100px' }}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroContasReceber;
