import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getParameterByName, populateForm, asyncForEach, ordenar, onBlurCurrency } from '../Utils';
import swal from '@sweetalert/with-react';
import Autocomplete from '../components/Autocomplete';
import { Icon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/ionicons/edit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import { ic_photo } from 'react-icons-kit/md/ic_photo'

const inputParsers = {
	date(input) {
		const [month, day, year] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class Locais extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			dataLva: [],
			selected: {
				local: [],
				tipo_locacao: []
			},
			modal: { show: false },
			edit: false,
			filterChanged: '',
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleDeleteLva = this.handleDeleteLva.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modalState = this.modalState.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.selectLocal = this.selectLocal.bind(this);
		this.selectTipo = this.selectTipo.bind(this);
		this.getDataLva = this.getDataLva.bind(this);
		this.insertLva = this.insertLva.bind(this);
		this.limpaLva = this.limpaLva.bind(this);
		this.editaLva = this.editaLva.bind(this);
		this.showModalLva = this.showModalLva.bind(this);
	}

	selectTipo(item) {
		this.setState({
			selected: {
				tipo_locacao: item
			}
		});
	}

	populaTipo(item, id) {
		if (item.type === 'tipo_locacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	selectLocal(item) {
		this.setState({
			selected: {
				local: item
			}
		});
	}

	populaLocais(item, id) {
		if (item.type === 'locais') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}


	closeModal() {
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao cancelar, todas as alterações serão descartadas. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) this.setState({ modal: { show: false } });
		});
	}

	async showModal(e, codigo) {
		// e.stopPropagation()
		e.preventDefault();
		let edicao = false;
		let pk = '0';
		if (Number(codigo) > 0) {
			edicao = true;
			pk = codigo;
			this.getDataLva(e, codigo)
			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getLocais?pk=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {

					if (typeof r[0] === 'undefined') {
						window.location.href = '/locais';
					} else {
						let form = document.getElementById('registroLocais');
						await populateForm(form, r[0]);
					}
				});
		} else {
			edicao = false;
			document.getElementById('registroLocais').reset();
		}
		this.setState({ modal: { show: true }, edit: edicao, codigo: pk });
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: this.state.edit
				? 'As alterações no registro serão salvas. Confirma?'
				: 'O registro será incluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroLocais');
		const data = new FormData(form);

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			// Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function (value, key) {
			object[key] = value;
		});
		object.dataLva = this.state.dataLva
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/editLocais?pk=' +
				this.state.codigo,
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							this.setState({ modal: { show: false } });
							var event = new Event('build');
							this.getData(event);
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/insertLocais', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							this.setState({ modal: { show: false } });
							var event = new Event('build');
							this.getData(event);
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	populaSer(item, id) {
		if (item.type === 'tiposServicos') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	async componentDidMount() {
		//Carregar Parâmetros de pesquisa
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getLocais')
			.then((r) => r.json())
			.then(async (r) => {
				let combosLocais = this.state.combos;
				await asyncForEach(r, async (item) => {
					let locais = {
						type: 'locais',
						display: item.descricao,
						value: item.pk_loc
					};
					await combosLocais.push(locais);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTipo_locacao')
			.then((r) => r.json())
			.then(async (r) => {
				let combosLocais = this.state.combos;
				await asyncForEach(r, async (item) => {
					let locais = {
						type: 'tipo_locacao',
						display: item.descricao,
						value: item.pk_tlo
					};
					await combosLocais.push(locais);
				});
				this.setState({ combos: this.state.combos });
			});
		let query = {};
		query.filtered = true;
		getParameterByName('filtered');
		query.descricao = getParameterByName('descricao');

		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);


		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTiposServicos')
			.then((r) => r.json())
			.then(async (r) => {
				let comboSer = this.state.combos;
				await asyncForEach(r, async (item) => {
					let tiposServicos = {
						type: 'tiposServicos',
						display: item.descricao,
						value: item.pk_ser
					};
					await comboSer.push(tiposServicos);
				});
			});
		ordenar('comboSer')


	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/deleteLocais?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exclusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleDeleteLva(e, pk, pk_eve) {
		console.log(pk_eve)
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/deleteLva?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exclusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getDataLva(event, pk_eve);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	async showModalLva(e, codigo) {
		e.preventDefault();
		let pk = '0';
		if (Number(codigo) > 0) {
			pk = codigo;

			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getLva?pk_lva=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/locais';
					} else {
						this.setState({
							arquivoIev: { name: r[0].folder }
						})

						let form = document.getElementById('showLva');
						await populateForm(form, r[0]);
					}
				}

				);

		} else {

			document.getElementById('showLva').reset();
		}
		this.setState({ modal: { showLva: true }, codigo: pk });
	}

	async insertLva(e) {
		e.preventDefault();
		const form = document.getElementById('registroLocais');
		const data = new FormData(form);
		let fk_tlo = ''
		let valor = ''
		let nometlo = ''
		let ativo = ''
		let exclusiva = ''

		for (let name of data.keys()) {
			const input = form.elements[name];

			console.log('name:' + name)

			const parserName = input.dataset.parse;
			if (parserName) {
				const parser = inputParsers[parserName];
				const parsedValue = parser(data.get(name));

				console.log('value:' + parsedValue)

				if (name == 'fk_tlo') {
					fk_tlo = parsedValue

					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTipo_locacao?pk=' + fk_tlo)
						.then((r) => r.json())
						.then(async (r) => {

							nometlo = r[0].descricao

							console.log(nometlo)
						});

				}
				if (name == 'valor')
					valor = parsedValue

				if (name == 'ativo')
					ativo = parsedValue

				if (name == 'exclusiva')
					exclusiva = parsedValue
				

				data.set(name, parsedValue);
			}

		}

		let lva = this.state.dataLva

		lva.push({
			nometlo: nometlo,
			fk_tlo: fk_tlo,
			valor: valor,
			exclusiva: exclusiva,
			ativo: ativo,
			// fk_loc: fk_loc,
			// ativo: ativo,
			// exclusivo: exclusivo
		});

		this.setState({ dataLva: lva });

		// this.state.dataIev.push(iev)

		console.log(this.state.dataLva)
	}

	async editaLva(e, codigo) {
		e.preventDefault();
		let pk = '0';
		if (Number(codigo) > 0) {
			pk = codigo;
			this.getDataLva(e, codigo)

			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getLocaisValores?pk=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/locais';
					} else {
						let form = document.getElementById('editaLva');
						await populateForm(form, r[0]);
					}
				}

				);

		} else {

			document.getElementById('editaLva').reset();
		}
		this.setState({ modal: { editaLva: true }, codigo: pk });
	}

	async getDataLva(e, codigo) {
		e.preventDefault();

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getLva?pk=' + codigo)
			.then((r) => r.json())
			.then(async (r) => {


				this.setState({ dataLva: r });
			});
	}

	async limpaLva(e) {
		e.preventDefault();
		this.setState({ dataLva: [] });
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/locais'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let queryString = '?';

			if (filter.descricao) {
				if (queryString === '?') {
					queryString = queryString + 'descricao=' + filter.descricao;
				} else queryString = queryString + '&descricao=' + filter.descricao;
			}

			//Monta Query URL
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/locais' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/locais?filtered=true');
			}

			resolve(queryString);
		});
	}

	async getData(e) {
		e.preventDefault();
		let queryString = await this.filterData();
		await fetch(
			config.protocol +
			"://" +
			config.server +
			":" +
			config.portBackend +
			"/api/getLocais" +
			queryString
		)

			.then((r) => r.json())
			.then(async (r) => {
				this.setState({ data: r });
			});
	}

	render() {
		let tiposServicos = this.state.combos.map(this.populaSer);
		let locais = this.state.combos.filter((item) => {
			return item.type === 'locais';
		});
		let tipo_locacao = this.state.combos.map(this.populaTipo);

		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="GrauParentesco" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Locais</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Descrição</label>
									<input
										name="descricao"
										type="text"
										id="filtroDescricao"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.descricao || ''}
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<button className="buttonNovo" onClick={this.showModal}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo Registro
								</button>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Registro de Local</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form
												id="registroLocais"
												name="registroLocais"
												onSubmit={this.submitData}
											>
												<div>
													<label className="labelModal">Descrição</label>
													<input
														type="text"
														id="descricao"
														name="descricao"
														className="form-control"
														data-parse="uppercase"
													/>

													<label className="labelModal">Detalhamento</label>
													<textarea
														type="text"
														name="detalhamento"
														data-parse="uppercase"
														className="form-control"
														style={{ height: '100px' }}
													/>

												</div>

												<div class="row">
													<div className="col-sm-2" style={{ width: "100px" }}>

														<label className="labelModal">Capacidade</label>
														<input
															type="text"
															id="capacidade"
															name="capacidade"
															className="form-control"
															data-parse="uppercase"
														/>
													</div>
													{/* <div className="col-sm-2" style={{ width: "100px" }}>
														<label className="labelModal">Ativo</label>
														<select
															data-parse="uppercase"
															id="ativo"
															name="ativo"
															className="form-control"
															defaultValue="S"
															style={{ width: '100px' }}
														>
															<option value="N">Não</option>
															<option value="S">Sim</option>
														</select>
													</div> */}


													<div className="col-sm-2" style={{ width: "140px", marginLeft:'20px' }}>
														<label className="labelModal">Taxa Reserva Exclusiva
														</label>
						
														<input
															type="text"
															id="taxa_reserva_exclusiva"
															name="taxa_reserva_exclusiva"
															className="form-control"
															data-parse="uppercase"
															onBlur={(e) => {
																onBlurCurrency(e);
															}}
														/>

													</div>													
												</div>												
												<br />
												<div class="row">
												<div className="col-sm-2" style={{ width: "120px" }}>
														<label className="labelModal">Primeiro Horário
														</label>
						
														<input
															type="text"
															id="primeiro_horario"
															name="primeiro_horario"
															className="form-control"
															data-parse="uppercase"
															
														/>

													</div>				
													<div className="col-sm-2" style={{ width: "120px" }}>
														<label className="labelModal">Último Horário
														</label>
						
														<input
															type="text"
															id="ultimo_horario"
															name="ultimo_horario"
															className="form-control"
															data-parse="uppercase"
															
														/>

													</div>				
												</div>
												<br/>
												<div>
													<br />
													<strong>Tipos de Locação</strong>
													<hr width="100%" size="50" color=" #528b46" />

													<div className="row">
														
														<div className="col-sm-9" style={{ width: '350px' }}>
														<label className="labelModal">Tipo Locacação</label>
															<select
																name="fk_tlo"
																id="fk_tlo"
																className="form-control"
																data-parse="uppercase"
																required="required"
																defaultValue=""
															>
																{tipo_locacao}
																<option value="">Selecione</option>
															</select>
														</div>
													
														<div className="col-xs-2" style={{ width: "80px" }}>
															<label className="labelModal">Ativo</label>
															<select
																data-parse="uppercase"
																id="ativo"
																name="ativo"
																className="form-control"
																defaultValue="S"
																style={{ width: '80px' }}
															>
																<option value="N">Não</option>
																<option value="S">Sim</option>
															</select>
														</div>
													</div>
													<div className="row">	
														<div className="col-xs-2" style={{ width: "130px" }}>
															<label className="labelModal">Reserva Exclusiva</label>
															<select
																data-parse="uppercase"
																id="exclusiva"
																name="exclusiva"
																className="form-control"
																defaultValue="S"
																style={{ width: '100px' }}
															>
																<option value="N">Não</option>
																<option value="S">Sim</option>
															</select>
														</div>

														<div className="col-xs-1" style={{ width: '100px' }}>
															<label className="labelModal">Valor</label>

															<input
																type="text"
																id="valor"
																name="valor"
																className="form-control"
																data-parse="uppercase"
																onBlur={(e) => {
																	onBlurCurrency(e);
																}}
															/>
														</div>

														<div className="col-xs-1" style={{ width: '200px', paddingTop: '20px', float: 'right'}}>

															<button
																className="ModalButton"
																onClick={this.insertLva}
																style={{ width: '100px' }}
															>
																Adicionar
											              	</button>
															<div style={{ paddingTop: '10px' }}>
																<button
																	className="ModalButton"
																	onClick={this.limpaLva}
																	style={{ width: '100px' }}
																>
																	Limpar Registros
											              	</button>
															</div>
														</div>

													

													</div>

													<div style={{ marginLeft: '0px', marginTop: '10px', marginRight: '0px' }}>

														<div className="divTabela">
															<ReactTable
																showPagination={true}
																data={this.state.dataLva}
																previousText="Anterior"
																nextText="Próximo"
																loadingText="Carregando..."
																pageText="Página"
																ofText="de"
																rowsText="registros"
																noDataText="Nenhum item encontrado"
																columns={[

																	{
																		Header: 'Item',
																		accessor: 'nometlo',
																		minWidth: 100
																	},

																	{
																		Header: 'Valor',
																		accessor: 'valor',
																		minWidth: 80
																	},
																	{
																		Header: 'Exclusiva',
																		accessor: 'exclusiva',
																		minWidth: 80
																	},
																	{
																		Header: 'Ativo',
																		accessor: 'ativo',
																		minWidth: 80
																	},

																	

																]}

																defaultPageSize={5}
															/>
														</div>
													</div>


												</div>


												

											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSave}>
												Salvar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: '',
											width: 70,
											Cell: (row) => {
												return (
													<div className="">
														<Icon size={20} icon={edit} className="iconButton" onClick={(e) => {
															this.showModal(e, row.row.pk_loc);
														}} />
														<Icon size={20} icon={iosTrash} className="iconButton"

															onClick={(e) => {
																this.handleDelete(e, row.row.pk_loc);
															}} />
													</div>
												);
											}
										},
										{
											Header: 'Código',
											accessor: 'pk_loc',

											show: false
										},
										{
											Header: 'Descrição',
											accessor: 'descricao',
											minWidth: 400
										}
									]}
									defaultSorted={[
										{
											id: 'pk_loc',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Locais;
