import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { garanteDate, asyncForEach, getParameterByName, ordenar } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/ionicons/edit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import Autocomplete from '../components/Autocomplete';
import {fileExcel} from 'react-icons-kit/icomoon/fileExcel'
import { Modal } from 'react-bootstrap';

class ContasReceber extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			total: 0, 
			totalRecebido: 0, 
			totalPendente: 0,
			totalSelecionado: 0,
			filterChanged: '',
			selected: [],
			selectedData: [],
			columns: [],
			modal: { show: false },
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.filterAssociado = this.filterAssociado.bind(this);
		this.filterDependente = this.filterDependente.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modalState = this.modalState.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.populaSelectedColumns = this.populaSelectedColumns.bind(this);
		this.populaUnselectedColumns = this.populaUnselectedColumns.bind(this);
		this.exportacaoSelectAll = this.exportacaoSelectAll.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.toggleSelectRow = this.toggleSelectRow.bind(this);
	}

	closeModal() {
		this.setState({ modal: { show: false } });
	}

	async showModal(e, codigo) {
		// e.stopPropagation()
		e.preventDefault();
		this.setState({ modal: { show: true }});
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	
	toggleColumn(e, name) {
		e.preventDefault();
		let columns = this.state.columns;
		asyncForEach(columns, (item, index) => {
			if (item.value === name) item.selected = !item.selected
			if (index+1 === columns.length) this.setState({columns: columns})
		})	
	}

	populaSelectedColumns(item, id) {
		if (item.selected) {
			return (
				<li name={item.value} onClick={(e)=>this.toggleColumn(e,item.value)} key={id}> 
					{item.value}
				</li>
			);
		}
	}

	populaUnselectedColumns(item, id) {
		if (!item.selected) {
			return (
				<li name={item.value} onClick={(e)=>this.toggleColumn(e,item.value)} key={id}>
					{item.value}
				</li>
			);
		}
		
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let comboAssociados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let associados = {
						type: 'associados',
						display: item.nome,
						value: item.pk_ass
					};
					await comboAssociados.push(associados);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getDependentes')
			.then((r) => r.json())
			.then(async (r) => {
				let comboDependentes = this.state.combos;
				await asyncForEach(r, async (item) => {
					let dependentes = {
						type: 'dependentes',
						display: item.nome,
						value: item.pk_ass
					};
					await comboDependentes.push(dependentes);
				});
				this.setState({ combos: this.state.combos });
			});
		
		let competenciaMesAtual, competenciaAnoAtual = 0;
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					competenciaMesAtual = Number(r[0].competencia.substring(0, 2));
					competenciaAnoAtual = Number(r[0].competencia.substring(2, 4))
				}
			});

		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTiposServicos')
			.then((r) => r.json())
			.then(async (r) => {
				let combosTiposServico = this.state.combos;
				await asyncForEach(r, async (item) => {
					let tipoServico = {
						type: 'tiposServico',
						display: item.descricao,
						value: item.pk_ser
					};
					await combosTiposServico.push(tipoServico);
				});
				this.setState({ combos: this.state.combos });
			});

		ordenar('filtroTipoServico');
		ordenar('filtroSituacao');

		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = getParameterByName('filtered');
		getParameterByName('filtered');
		query.fk_ass = getParameterByName('fk_ass');
		query.associado = this.state.combos.filter((item) => {
			return item.type === 'associados' && Number(item.value) === Number(query.fk_ass);
		})[0];
		query.data_venc_max = getParameterByName('data_venc_max');
		query.data_venc_min = getParameterByName('data_venc_min');
		query.competenciaAno = getParameterByName('competenciaAno') || competenciaAnoAtual;
		query.competenciaMes = getParameterByName('competenciaMes') || competenciaMesAtual;
		query.situacao = getParameterByName('situacao') || 'A';
		query.tipoServico = getParameterByName('tipoServico');

		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);

	}


	exportacaoSelectAll(e,select) {
		e.preventDefault();
		let columns = this.state.columns
		asyncForEach(columns, (item, index)=>{
			item.selected = select
			if (index+1 === columns.length) this.setState({columns: columns})
		})
	}

	async handleExport () {
		let columns = this.state.columns;
		let selected = columns.filter((item) => {return item.selected === true}).length > 0;
		let data = this.state.data;
		let haveData = data.length > 0;
		// console.log(columns, selected)
		console.log(data)
		let exportData = {columns: columns, data: data};
		if (!selected) swal('Nenhuma coluna selecionada!!', 'Nenhuma coluna foi selecionada. Selecione pelo menos uma para exportar os registros!', 'error');
		if (!haveData) swal('Nenhum registro encontrado!!', 'Nenhum registro filtrado!! Filtre ao menos um registro para gerar a exportação.', 'error');
		if (haveData && selected) {
			await fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/exportacaoLivre?table=associados',
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(exportData)
				}
			).then((r) => r.blob())
			.then((r) => {
				var url = window.URL.createObjectURL(r);
				var a = document.createElement('a');
				a.href = url;
				a.download = "exportacao.xlsx";
				document.body.appendChild(a);
				a.click();    
				a.remove();
			});	
		}
	}

	handleDelete(e, pk, automatico, liquidado) {
		e.preventDefault();
		if (automatico === 'S')
			return swal('Erro!!', 'Exclusão não realizada. Registro foi gerado automaticamente.', 'error');
		if (liquidado > 0) return swal('Erro!!', 'Exclusão não realizada. Registro possui valor recebido.', 'error');
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteContasReceber?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/contas-receber'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			let total = 0, totalRecebido = 0, totalPendente = 0
			await asyncForEach(data, async (item, index) => {
				total = Number(item.valor) + Number(total);
				totalRecebido = Number(item.valor_recebido) + Number(totalRecebido);

				item.data_venc = garanteDate(item.data_venc);
				item.data_emis = garanteDate(item.data_emis);
				item.data_liq = garanteDate(item.data_liq);
				item.competenciastr = item.competencia.substr(0, 2) + '/20' + item.competencia.substr(2, 2);
				item.strforma_cobranca = item.forma_cobranca === 'B' ? 'BANCO' : item.forma_cobranca === 'T' ? 'TESOURO' : 'OUTRO';
				if (item.plano) {
					switch (item.plano) {
						case '12668':
							item.nomeuni = ' - GLOBAL';
						break;
						case '06533':
							item.nomeuni = ' - HOSPITALAR';
						break;
						case '00560':
						case '00561':
						case '06891':
							item.nomeuni = ' - ODONTO';
						break;
						case '02061':
							item.nomeuni = ' - AMBULATORIAL';
						break;
						default:
							item.nomeunie = '';
						break;
					}
				}
				if (item.tipouni) {
					switch (item.tipouni) {
						case 'P':
							item.nomeuni = item.nomeuni + ' - COPARTICIPAÇÃO';
						break;
						default:
							item.nomeuni = item.nomeuni + ' - MENSALIDADE';
						break;
					}
				}
				if (item.tipotel) {
					switch (item.tipotel) {
						case 'V':
							item.nometel = ' - VIVO';
						break;
						case 'C':
								item.nometel = ' - CLARO';
						break;
						default:
							item.nometel = '';
						break;
					}
				}

				console.log(item.nomeser, item.nomeuni, item.nometel )
				item.strServico = item.nomeser+(item.nomeuni||'')+(item.nometel||'')
				if (data.length === index+1) {
					totalPendente = Number(total) - Number(totalRecebido);
					this.setState({ total: total, totalRecebido: totalRecebido, totalPendente: totalPendente })
					resolve(data);
				}
			});
			
		});
	}

	async filterData() {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let queryString = '?';

			if (filter.competenciaAno) {
				if (queryString === '?') {
					queryString = queryString + 'competenciaAno=' + filter.competenciaAno;
				} else queryString = queryString + '&competenciaAno=' + filter.competenciaAno;
			}

			if (filter.competenciaMes) {
				if (queryString === '?') {
					queryString = queryString + 'competenciaMes=' + filter.competenciaMes;
				} else queryString = queryString + '&competenciaMes=' + filter.competenciaMes;
			}

			if (filter.data_venc_min) {
				if (queryString === '?') {
					queryString = queryString + 'data_venc_min=' + filter.data_venc_min;
				} else queryString = queryString + '&data_venc_min=' + filter.data_venc_min;
			}

			if (filter.data_venc_max) {
				if (queryString === '?') {
					queryString = queryString + 'data_venc_max=' + filter.data_venc_max;
				} else queryString = queryString + '&data_venc_max=' + filter.data_venc_max;
			}

			if (filter.tipoServico) {
				if (queryString === '?') {
					queryString = queryString + 'tipoServico=' + filter.tipoServico;
				} else queryString = queryString + '&tipoServico=' + filter.tipoServico;
			}

			if (filter.fk_ass) {
				if (queryString === '?') {
					queryString = queryString + 'fk_ass=' + filter.fk_ass;
				} else queryString = queryString + '&fk_ass=' + filter.fk_ass;
			}

			if (filter.dependente) {
				if (queryString === '?') {
					queryString = queryString + 'dependente=' + filter.dependente;
				} else queryString = queryString + '&dependente=' + filter.dependente;
			}

			if (filter.situacao) {
				if (queryString === '?') {
					queryString = queryString + 'situacao=' + filter.situacao;
				} else queryString = queryString + '&situacao=' + filter.situacao;
			}


			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/contas-receber' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/contas-receber?filtered=true');
			}

			resolve(queryString);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();

		this.setState({ data: [] });

		let query = await this.filterData();
		console.log(query)
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getContasReceber'+query)
			.then((r) => r.json())
			.then(async (r) => {
				let colunasExportacao = [
					{value: 'Código', field: 'pk_ctr', fk: {}, selected: false, width: 50, align: 3},
					{value: 'Titular', fk: {fkField: 'fk_ass', tablePk: 'pk_ctr', tableField: 'nome',table: 'associados'}, field: 'titular', selected: false, width: 200, align: 1},
					{value: 'Referente', fk: {fkField: 'fk_ass', tablePk: 'pk_ctr', tableField: 'nome',table: 'associados'}, field: 'nomeass', selected: false, width: 200, align: 1},
					{value: 'Serviço', fk: {fkField: 'fk_ser', tablePk: 'pk_ctr', tableField: 'descricao',table: 'tipo_servico'}, field: 'strServico', selected: false, width: 200, align: 1},
					{value: 'Competência', fk: {}, field: 'competencia', selected: false, width: 130, align: 1},
					{value: 'Valor', fk: {}, field: 'valor', selected: false, width: 100, align: 3},
					{value: 'Valor Recebido', fk: {}, field: 'valor_recebido', selected: false, width: 130, align: 3},
					{value: 'Forma Cobrança', fk: {}, field: 'strforma_cobranca', selected: false, width: 130, align: 1},
					{value: 'Data Emissão', fk: {}, field: 'data_emis', selected: false, width: 100, align: 1},
					{value: 'Automático', fk: {}, field: 'automatico', selected: false, width: 100, align: 1},
					{value: 'Data Vencimento', fk: {}, field: 'data_venc', selected: false, width: 100, align: 1}
				];
				//Filtra
				// let items = await this.filterData(r);
				//Trata
				let items = await this.modelingData(r);
				this.setState({ data: items, columns: colunasExportacao });
			});
	}


	populaTiposServico(item, id) {
		if (item.type === 'tiposServico') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	filterAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ass = item.value;
		newFilter.associado = item;
		this.setState({ filter: newFilter });
	}

	filterDependente(item) {
		let newFilter = this.state.filter;
		newFilter.dependente = item.value;
		this.setState({ filter: newFilter });
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}

	toggleSelectAll(e) {
		if (!e.target.checked) {
			this.setState({
				selectedData: [],
				totalSelecionado: 0
			})
		} else {
			let arrayOfPks = this.state.data.map((item) => { return { codigo: item.pk_ctr, valor: item.valor} } )
			this.setState({
				selectedData: arrayOfPks,
				totalSelecionado: arrayOfPks.reduce((a, b) => a + b.valor, 0)
			})
		}
	}

	toggleSelectRow(e, codigo, valor) {
		let { selectedData } = this.state
		if (!e.target.checked) {
			const index = selectedData.map((item) => item.codigo).indexOf(codigo);
			if (index > -1) {
				selectedData.splice(index, 1);
			}
			this.setState({
				...selectedData,
				totalSelecionado: selectedData.reduce((a,b) => a + b.valor, 0)
			})
		} else {
			selectedData.push({ codigo, valor })
			this.setState({
				...selectedData,
				totalSelecionado: selectedData.reduce((a,b) => a + b.valor, 0)
			})
		}
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});
		let dependentes = this.state.combos.filter((item) => {
			return item.type === 'dependentes';
		});
		let tiposServico = this.state.combos.map(this.populaTiposServico);
		let selectedColumns = this.state.columns.map(this.populaSelectedColumns)
		let unselectedColumns = this.state.columns.map(this.populaUnselectedColumns)
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="ContasReceber" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Contas a Receber</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro" style={{ width: '300px' }}>
									<label className="labelFiltro">Associado</label>
									<Autocomplete
										field="filtroFk_ass"
										items={associados}
										inModal=""
										selected={this.state.filter.associado || { display: '' }}
										select={this.filterAssociado}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '300px' }}>
									<label className="labelFiltro">Dependente</label>
									<Autocomplete
										field="filtroDependente"
										items={dependentes}
										inModal=""
										selected={this.state.filter.dependente || { display: '' }}
										select={this.filterDependente}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Competência/Débito</label>
									<select
										name="competenciaMes"
										id="filtroCompetenciaMes"
										className="selectFiltro"
										value={this.state.filter.competenciaMes || ''}
										onChange={this.handleChange}
									>
										<option value="">Mês</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>
									</select>
									<select
										name="competenciaAno"
										id="filtroCompetenciaAno"
										className="selectFiltro"
										value={this.state.filter.competenciaAno || ''}
										onChange={this.handleChange}
									>
										<option value="">Ano</option>
										{anos}
									</select>
								</div>

								
								<div className="itemFiltro">
									<label className="labelFiltro">Tipo de Serviço</label>
									<select
										name="tipoServico"
										id="filtroTipoServico"
										className="selectFiltro"
										value={this.state.filter.tipoServico || 'T'}
										onChange={this.handleChange}
									>
										<option value="T">TODOS</option>
										{tiposServico}
									</select>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Situação</label>
									<select
										name="situacao"
										id="filtroSituacao"
										className="selectFiltro"
										value={this.state.filter.situacao || 'A'}
										onChange={this.handleChange}
									>
										<option value="A">EM ABERTO</option>
										<option value="R">RECEBIDAS</option>
										<option value="T">TODAS</option>
									</select>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<button className="buttonExportacao"  onClick={this.showModal}>
									<Icon size={16} style={{ display: 'inline' }} icon={fileExcel} /> Exportação Livre
								</button>
								<LinkContainer to={'/apergs/contas-receber/registro'+(getParameterByName('fk_ass')?'?fk_ass='+getParameterByName('fk_ass'):'')}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} /> Novo
										Registro
									</button>
								</LinkContainer>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Exportação Livre - Associados</h3>
											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="exportacaoAssociados"
													name="exportacaoAssociados"
													onSubmit={this.submitData}
												>
													<div className="containerColumns rowColumns">
														<div className="itemColumns">
															<label className="labelColumns">Colunas disponíveis</label>
															<ul name='unselected' className='ListSelect'>
																{unselectedColumns}
															</ul>																												
														</div>
														<div name='selected' className="itemColumns">
															<label className="labelColumns">Colunas selecionadas</label>
															<ul className='ListSelect'>
																{selectedColumns}
															</ul>
														</div>
													</div>
													<div className="containerColumns rowColumns">
														<div className="itemColumns">
															<button className="ModalButtonColumns" onClick={(e) => this.exportacaoSelectAll(e,true)}>Selecionar Todos</button>
														</div>
														<div className="itemColumns">
															<button className="ModalButtonColumns" onClick={(e) => this.exportacaoSelectAll(e,false)}>Limpar seleção</button>
														</div>
													</div>
												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={this.handleExport}>
													Exportar
												</button>
												<button className="ModalButton" onClick={this.closeModal}>
													Fechar
												</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											id: 'selection',
											Header: (header) => {
												header.column.sortable = false
												return (
												<div>
													<input type="checkbox" onChange={this.toggleSelectAll} checked={this.state.selectedData.length === this.state.data.length}/>
												</div>
											)},
											Cell: ({ row }) => (
												<div>
													<input type="checkbox" onChange={(e) => this.toggleSelectRow(e, row.pk_ctr, row.valor)} checked={this.state.selectedData.map((item) => item.codigo).indexOf(row.pk_ctr) > -1} />
												</div>
											),
											width: 47,
										},
										{
											Header: '',
											width: 65,
											Cell: (row) => {
												return (
													<div>
														<LinkContainer to={'/apergs/contas-receber/registro?pk=' + row.row.pk_ctr}>
																<Icon
																	size={20}
																	icon={edit}
																	className="iconButton"
																/>
														</LinkContainer>
														<Icon
															size={20}
															icon={iosTrash}
															name={row.row.pk_ctr}
															className="iconButton"
															onClick={(e) => {
																this.handleDelete(e, row.row.pk_ctr);
															}}
														/>
													</div>
												);
											}	
										},
										{
											Header: 'Associado',
											accessor: 'titular',
											width: 250
										},
										{
											Header: 'Referente',
											accessor: 'nomeass',
											width: 250
										},
										{
											Header: 'Competência/Débito',
											accessor: 'competenciastr',
											width: 100
										},
										{
											Header: 'Valor',
											accessor: 'valor',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Recebido',
											accessor: 'valor_recebido',
											width: 100,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_recebido).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Dt Liquidação',
											accessor: 'data_liq',
											width: 110
										},
										{
											Header: 'Tipo Serviço',
											accessor: 'strServico',
											width: 350
										},
										{
											Header: 'Forma Cobrança',
											accessor: 'strforma_cobranca',
											width: 130
										},
										{
											Header: 'Código',
											accessor: 'pk_ctr',
											show: false
										},
										{
											Header: 'Automático',
											accessor: 'automatico',
											show: false
										},
									]}
									defaultSorted={[
										{
											id: 'data_venc',
											desc: true
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
							<p style={{float: 'right'}}>{this.state.data.length+' registros encontrados'}</p>
							<p>{
								'Total: '+this.state.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
								+' | '+
								'Total Recebido: '+this.state.totalRecebido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
								+' | '+
								'Total Pendente: '+this.state.totalPendente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
								+' | '+
								'Total Selecionado: '+this.state.totalSelecionado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
							}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ContasReceber;
