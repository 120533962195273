import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
// import {LinkContainer}  from 'react-router-bootstrap'
import 'react-table/react-table.css';
import { asyncForEach, dateSql, getParameterByName, populateForm } from '../Utils';
import swal from '@sweetalert/with-react';
import axios from 'axios';
import $ from 'jquery'
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroNormativas extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			arquivo: {},
			arquivo: {},
			novoArquivo: false,
			recebido: 0,
			automatico: false,
			filter: [],
			selected: {
				subclasse: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.submitData = this.submitData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSelectedFile = this.handleSelectedFile.bind(this)
		this.handleBack = this.handleBack.bind(this);
	}



	async componentDidMount() {
		//Busca valores para combo de filtro
		//Situação
		
		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));

		if (query.pk > 0) {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/getNormativas?pk=' +
					query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/normativas';
					} else {
						let form = document.getElementById('registroArquivos');
						r[0].data = dateSql(r[0].data);
						this.setState({
							arquivo: { name: r[0].nome_arquivo },
							novoArquivo: false,
							enderecoArquivo: r[0].nome_arquivo ?
								config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/static/arquivos/' + getParameterByName('pk') + '/' +
								r[0].nome : null
						});

						// console.log(selectedAdvogado)
						await populateForm(form, r[0]);
					}
				});
			this.setState({ edit: true });
		} else {
			this.setState({
				selected: {
					advogado: []
				},
				arquivo: {},
				novoArquivo: false,
				enderecoArquivo: null
			});
		}
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
	}

	async modelingData(data) {
		//Trata os campos
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	validateData(data) {
		let valid = { isValid: true };
		//Consistências



		//Razão Social
		if (!data.get('descricao')) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DESCRIÇÃO.' };
		}

		//Nome Fantasia
		if (!data.get('data')) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DATA.' };
		}

		// //Endereço
		// if (!data.get('endereco')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo ENDEREÇO.' };
		// }

		// //Bairro
		// if (!data.get('bairro')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo BAIRRO.' };
		// }

		// //CEP
		// if (!data.get('cep')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo CEP.' };
		// }

		// //Subclasse
		// if (Number(data.get('fk_sub') || 0) === 0) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo SUBCLASSE.' };
		// }

		// //Cidade
		// if (Number(data.get('fk_cid' || 0)) === 0) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo CIDADE.' };
		// }

		console.log(valid);
		//Retorna resultado
		return valid;
	}

	submitData(e) {
		e.preventDefault();
		let strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 });
		let auth = JSON.parse(strAuth);
		//Pega valores do form
		const form = document.getElementById('registroNormativas');
		const data = new FormData(form);

	
		let validate = this.validateData(data);

		if (!validate.isValid) {
			swal(validate.title, validate.message, 'error');
			throw validate;
		}

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			//console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof (input || []).type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			console.log(key)
			if (key != 'pdf')
				object[key] = value;
		});
		object.nome_arquivo = this.state.arquivo.name
		object.fk_usu = auth.user
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editNormativas?pk=' +
					getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoNormativas', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}


	async handleSelectedFile(e) {
		$('#arquivo').val(true);
		let file = e.target.files[0];
		let data = new FormData();
		console.log(file)
		data.append('arquivo', file, file.name);
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivoNormativas',
			data
		);
		this.setState({
			arquivo: file,
			novoArquivo: true,
			temArquivo: 'S',
			enderecoArquivo:
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/static/normativas/arquivos/temp.pdf'
		});
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}


	render() {
		let subclasses = this.state.combos.filter((item) => {
			return item.type === 'subclasses';
		});		
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidades';
		});
		console.log(this.state.combos);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Normativas" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Normativas</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroNormativas" name="registroNormativas" onSubmit={this.submitData}>
								<div style={{ height: 'auto' }}>
									{/* <Tabs defaultActiveKey={1} animation={false} id="uncontrolled-tab-example"> */}
									<div className="boxRegistro">
										<div
											style={{
												paddingTop: '30px'
											}}
										>
											<div className="row">
												<div className="col-sm-4">
													<label>Descrição:</label>
													<input type="text" name="descricao" className="form-control" data-parse="uppercase" />
												</div>
												<div className="col-sm-1">
													<label>Ordenação:</label>
													<input type="text" name="ordenacao" className="form-control" data-parse="uppercase" />
												</div>
												<div className="col-sm-2" style={{ width: '180px' }}>
													<label>Data:</label>
													<input type="date" name="data" className="form-control" />
												</div>
												<br/>
											</div>
											<div className="row">
												<div style={{ textAlign: 'left', marginTop: '15px' }}>
													<input type="file" name="pdf"
														id="html_btn"
														accept=".pdf"
														onChange={this.handleSelectedFile}
													/>
													{this.state.arquivo.name || 'Nenhum arquivo selecionado'}
													<button
														className="ModalButton"
														type="file"
														onClick={this.selectFile}
														style={{ width: '110px' }}
													>
														Selecionar Arquivo
													</button>
												</div>

											</div>
										</div>
									</div>
								</div>
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroNormativas;
