import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName, populateForm, onBlurCurrency, dateSql } from '../Utils';
import swal from '@sweetalert/with-react';
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroContasPagar extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			recebido: 0,
			automatico: false,
			filter: [],
			selected: {
				cidadeComerc: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.submitData = this.submitData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.selectFornecedor = this.selectFornecedor.bind(this);
		this.selectSubclasse = this.selectSubclasse.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getFornecedores')
			.then((r) => r.json())
			.then(async (r) => {
				let comboFornecedores = this.state.combos;
				await asyncForEach(r, async (item) => {
					let fornecedores = {
						type: 'fornecedores',
						display: item.razao_social,
						value: item.pk_for
					};
					await comboFornecedores.push(fornecedores);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSubclasses')
			.then((r) => r.json())
			.then(async (r) => {
				let comboSubclasses = this.state.combos;
				await asyncForEach(r, async (item) => {
					let subclasse = {
						type: 'subclasses',
						display: item.descricao,
						value: item.pk_sub
					};
					await comboSubclasses.push(subclasse);
				});
				this.setState({ combos: this.state.combos });
			});

		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));

		if (query.pk > 0) {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/getContasPagar?pk=' +
					query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/contas-pagar';
					} else {
						let form = document.getElementById('registroContasPagar');
						let selectedFornecedor = this.state.combos.filter((item) => {
							return item.type === 'fornecedores' && item.value === r[0].fk_for;
						});
						let selectedSubclasse = this.state.combos.filter((item) => {
							return item.type === 'subclasses' && item.value === r[0].fk_sub;
						});
						this.setState({
							selected: {
								subclasse: selectedSubclasse[0],
								fornecedor: selectedFornecedor[0]
							}
						});
						r[0].data_venc = dateSql(r[0].data_venc);
						r[0].data_emis = dateSql(r[0].data_emis);
						r[0].data_liq = dateSql(r[0].data_liq);
						this.setState({ recebido: Number(r[0].liquidado) });
						r[0].liquidado = (Number(r[0].valor) - Number(r[0].liquidado))
							.toFixed(2)
							.replace('.', ',');
						r[0].valor = r[0].valor.toFixed(2).replace('.', ',');
						// $('#valor_pendente').val(r[0].liquidado);
						console.log(r[0]);
						populateForm(form, r[0]);
					}
				});
			this.setState({ edit: true });
		}
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
	}

	async modelingData(data) {
		//Trata os campos
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	validateData(data) {
		let valid = { isValid: true };
		//Consistências

		//Fornecedor
		if (Number(data.get('fk_for') || 0) === 0) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo FORNECEDOR.' };
		}

		//Numdoc
		if (!data.get('numdoc')) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo NUMDOC.' };
		}

		//Data de Emissão
		if (!data.get('data_emis')) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DATA DE EMISSÃO.' };
		}

		//Data de Vencimento
		if (!data.get('data_venc')) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DATA DE VENCIMENTO.' };
		}

		//Valor
		if (Number(data.get('valor' || 0)) === 0) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo VALOR.' };
		}

		//Subclasse
		if (Number(data.get('fk_sub') || 0) === 0) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo SUBCLASSE.' };
		}



		console.log(valid);
		//Retorna resultado
		return valid;
	}

	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroContasPagar');
		const data = new FormData(form);



		let validate = this.validateData(data);

		if (!validate.isValid) {
			swal(validate.title, validate.message, 'error');
			throw validate;
		}


		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			//console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof (input || []).type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			object[key] = value;
		});
		object.valor = Number(object.valor.replace(',', '.'))
		object.automatico = 'N'
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editContasPagar?pk=' +
					getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoContasPagar', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	selectFornecedor(item) {
		this.setState({
			selected: {
				fornecedor: item
			}
		});
	}

	selectSubclasse(item) {
		this.setState({
			selected: {
				subclasse: item
			}
		});
	}


	render() {
		let fornecedores = this.state.combos.filter((item) => {
			return item.type === 'fornecedores';
		});
		let subclasses = this.state.combos.filter((item) => {
			return item.type === 'subclasses';
		});
		console.log(this.state.combos);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="ContasPagar" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Contas a Pagar</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroContasPagar" name="registroContasPagar" onSubmit={this.submitData}>
								<div style={{ height: 'auto' }}>
									{/* <Tabs defaultActiveKey={1} animation={false} id="uncontrolled-tab-example"> */}
									<div className="boxRegistro">
										<div
											style={{
												paddingTop: '30px'
											}}
										>
											<div className="row">
											<div
													className="col-sm-4"
													style={{ height: 'auto', marginBottom: '-10px' }}
												>
													<label>Fornecedor:</label>
													<Autocomplete
														field="fk_for"
														items={fornecedores}
														inModal=""
														selected={this.state.selected.fornecedor}
														select={this.selectFornecedor}
													/>
												</div>
												<div className="col-sm-2">
													<label>Número do Documento:</label>
													<input
														type="text"
														id="numdoc"
														name="numdoc"
														className="form-control"
														data-parse='uppercase'
													/>
												</div>
												<div className="col-sm-2" style={{ width: '180px' }}>
													<label>Data Emissão:</label>
													<input type="date" name="data_emis" className="form-control" />
												</div>
												<div className="col-sm-2" style={{ width: '180px' }}>
													<label>Data Vencimento:</label>
													<input type="date" name="data_venc" className="form-control" />
												</div>
												<div className="col-sm-2" style={{ width: '140px' }}>
													<label>Valor (R$):</label>
													<input
														type="text"
														id="valor"
														name="valor"
														className="form-control"
														onBlur={(e) => {
															onBlurCurrency(e);
														}}
													/>
												</div>
												<div
													className="col-sm-3"
													style={{ height: 'auto', marginBottom: '-10px' }}
												>
													<label>Subclasse:</label>
													<Autocomplete
														field="fk_sub"
														items={subclasses}
														inModal=""
														selected={this.state.selected.subclasse}
														select={this.selectSubclasse}
													/>
												</div>
												<div className="col-sm-12">
													<label>Observações:</label>
													<textarea
														type="text"
														name="observacao"
														data-parse="uppercase"
														className="form-control"
														style={{ height: '100px' }}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroContasPagar;
