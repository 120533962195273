import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import { populateForm, asyncForEach } from '../Utils';
import Autocomplete from '../components/Autocomplete';
import swal from '@sweetalert/with-react';
// import $ from 'jquery'

class RelatorioContasEmAbertoPorCompetenciaAssociado extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: { 
				categoriaAssociado: {value: null, display: ""},
				associado: {value: null, display: ""},
				servico: {value: null, display: ""}
			},
			relatorio: '',
			relatorioStats: { message: 'Not started', info: [] }
		};
		this.filterAssociado = this.filterAssociado.bind(this);
		this.filterServico = this.filterServico.bind(this);
		this.filterCategoriaAssociado = this.filterCategoriaAssociado.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	filterAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ass = item.value;
		newFilter.associado = item;
		this.setState({ filter: newFilter });
	}

	filterServico(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ser = item.value;
		newFilter.servico = item;
		this.setState({ filter: newFilter });
	}

	filterCategoriaAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_cat = item.value;
		newFilter.categoriaAssociado = item;
		this.setState({ filter: newFilter });
	}

	handleSave(e) {
		e.preventDefault();
		const form = document.getElementById('gerarMensalidade');
		const data = new FormData(form);

		let competencia = '';
		let competencia_ano = '';
		let competencia_mes = '';
		let strCompetencia = '';

		if (Number(data.get('competencia_mes') < 10)) {
			competencia_mes = String('0' + data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			strCompetencia = competencia_mes + '/20' + competencia_ano;
			data.set('competencia', competencia);
		} else {
			competencia_mes = String(data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			strCompetencia = competencia_mes + '/20' + competencia_ano;
			data.set('competencia', competencia);
		}

		let strSituacao = document.getElementById('situacao').options[document.getElementById('situacao').selectedIndex].text;
		let situacao = data.get('situacao')

		let associado = this.state.filter.associado.value
		let strAssociado = this.state.filter.associado.display || 'Todos'
		
		let categoriaAssociado = this.state.filter.categoriaAssociado.value
		let strCategoriaAssociado = this.state.filter.categoriaAssociado.display || 'Todas'

		let strFormaPagamento = document.getElementById('formaPagamento').options[document.getElementById('formaPagamento').selectedIndex].text;
		let formaPagamento = data.get('formaPagamento')

		let servico = this.state.filter.servico.value
		let strServico = this.state.filter.servico.display || 'Todos'

		let strOrdenacao = document.getElementById('ordenacao').options[document.getElementById('ordenacao').selectedIndex].text;
		let ordenacao = data.get('ordenacao')

		let options = {
			filter: {
				competencia: competencia,
				situacao: situacao,
				associado: associado,
				categoriaAssociado: categoriaAssociado,
				formaPagamento: formaPagamento,
				servico: servico
			},
			filterDesc: {
				competencia: strCompetencia,
				situacao: strSituacao,
				associado: strAssociado,
				categoriaAssociado: strCategoriaAssociado,
				formaPagamento: strFormaPagamento,
				servico: strServico
			},
			ordenacao: ordenacao,
			ordenacaoDesc: strOrdenacao
		}

		swal({
			title: 'Atenção!',
			text: 'Deseja gerar o relatório?',
			buttons: [ 'Sim', 'Não' ]
		}).then(async (result) => {
			if (!result) {
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/relatorio?nome=contasEmAbertoPorCompetenciaAssociado&options='+JSON.stringify(options))
				.then((r) => r.json())
				.then(async (r) => {
					if (r.status === 'success') {
						this.setState({ relatorio: ''})
						this.setState({ relatorio: config.protocol + '://' + config.server + ':' + config.portBackend+'/static/relatorios/contasEmAbertoPorCompetenciaAssociado/contasEmAbertoPorCompetenciaAssociado_'+competencia+'.pdf'})
					} else this.setState({ relatorio: ''})
					
				})
				
			}
			
		});
		
	}

	populaSituacoes(item, id) {
		if (item.type === 'situacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}		
	}


	async componentDidMount() {
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let form = document.getElementById('gerarMensalidade');
					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSituacoes')
					.then((r) => r.json())
					.then(async (r) => {
						let combosSituacoes = this.state.combos;
						await asyncForEach(r, async (item) => {
							let situacao = {
								type: 'situacao',
								display: item.descricao,
								value: item.pk_sit
							};
							await combosSituacoes.push(situacao);
						});
						this.setState({ combos: combosSituacoes });
					});

					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTiposServicos')
					.then((r) => r.json())
					.then(async (r) => {
						let combosServicos = this.state.combos;
						await asyncForEach(r, async (item) => {
							let situacao = {
								type: 'servicos',
								display: item.descricao,
								value: item.pk_ser
							};
							await combosServicos.push(situacao);
						});
						this.setState({ combos: combosServicos });
					});

					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
					.then((r) => r.json())
					.then(async (r) => {
						let combosCategoriasAssociados = this.state.combos;
						await asyncForEach(r, async (item) => {
							let situacao = {
								type: 'categoriasAssociados',
								display: item.descricao,
								value: item.pk_cat
							};
							await combosCategoriasAssociados.push(situacao);
						});
						this.setState({ combos: combosCategoriasAssociados });
					});

					await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
					.then((r) => r.json())
					.then(async (r) => {
						let comboAssociados = this.state.combos;
						await asyncForEach(r, async (item) => {
							let associados = {
								type: 'associados',
								display: item.nome,
								value: item.pk_ass
							};
							await comboAssociados.push(associados);
						});
						this.setState({ combos: this.state.combos });
					});


					console.log(this.state.combos)
					r[0].competencia_mes = Number(r[0].competencia.substring(0, 2));
					r[0].competencia_ano = Number(r[0].competencia.substring(2, 4));
					console.log(r[0].competencia_mes, r[0].competencia_ano);
					r[0].valor_anape = r[0].valor_anape.toFixed(2).replace('.', ',');
					populateForm(form, r[0]);
				}
			});
	}

	populaTiposAcoes(item, id) {
		if (item.type === 'tiposAcoes') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}		
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}


	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});
		let servicos = this.state.combos.filter((item) => {
			return item.type === 'servicos';
		});
		let categoriasAssociados = this.state.combos.filter((item) => {
			return item.type === 'categoriasAssociados';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Relação de Contas em Aberto</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="gerarMensalidade" name="gerarMensalidade" onSubmit={this.submitData}>
								<div className="col-sm-12" style={{ marginTop: '15px', marginBottom: '15px' }}>
									<label>Competência/Débito:</label>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_mes">Mês:</label>
									<select name="competencia_mes" className="form-control">
										<option value="">Selecione</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_ano">Ano:</label>
									<select name="competencia_ano" className="form-control">
										<option value="">Selecione</option>
										{anos}
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="associado">Associado</label>
									<Autocomplete
										field="filtroAssociado"
										items={associados}
										inModal="-inModal"
										selected={this.state.filter.associado || { display: '' }}
										select={this.filterAssociado}
									/>
								</div>
								<div className="col-sm-3" style={{ marginBottom: '15px' }}>
									<label htmlFor="categoriaAssociado">Categoria de Associado</label>
									<Autocomplete
										field="filtroCategoriaAssociado"
										items={categoriasAssociados}
										inModal="-inModal"
										selected={this.state.filter.categoriaAssociado || { display: '' }}
										select={this.filterCategoriaAssociado}
									/>
								</div>
								<div className="col-sm-3" style={{ marginBottom: '15px' }}>
									<label htmlFor="associado">Tipo de Serviço</label>
									<Autocomplete
										field="filtroServico"
										items={servicos}
										inModal="-inModal"
										selected={this.state.filter.servico || { display: '' }}
										select={this.filterServico}
									/>
								</div>
								<div className="col-sm-3" style={{ marginBottom: '15px' }}>
								<label htmlFor="situacao">Situação</label>
									<select
										name="situacao"
										id="situacao"
										className="form-control"
									>
										<option value="">Todas</option>
										<option value="N">DÉBITOS NÃO REALIZADOS</option>
										<option value="R">DÉBITOS REALIZADOS</option>
									</select>
								</div>
								<div className="col-sm-3" style={{ marginBottom: '15px' }}>
								<label htmlFor="formaPagamento">Forma de Pagamento</label>
									<select
										name="formaPagamento"
										id="formaPagamento"
										className="form-control"
									>
										<option value="">Todas</option>
										<option value="T">TESOURO</option>
										<option value="B">BANRISUL</option>
										<option value="P">PORTABILIDADE</option>
									</select>
								</div>
								<br/>
								<div className="col-sm-7" style={{ marginBottom: '15px' }}>
								<label htmlFor="ordenacao">Ordenação</label>
									<select
										name="ordenacao"
										id="ordenacao"
										className="form-control"
									>
										<option value="C">Competência > Nome Associado > Tipo Serviço</option>
										<option value="A">Nome Associado > Competência > Tipo Serviço</option>
										<option value="S">Tipo Serviço > Competência > Nome Associado</option>
									</select>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar
							</button>
						</div>
					</div>
					<div className="boxRelatorio" hidden={this.state.relatorio === ''}>
						<iframe className="relatorio" title="Relatório" src={this.state.relatorio}></iframe>
					</div>
				</div>
			</div>
		);
	}
}

export default RelatorioContasEmAbertoPorCompetenciaAssociado;
