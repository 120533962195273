import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import axios from 'axios';
import $ from 'jquery';
import { Tabs, Tab } from 'react-bootstrap';
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName, garanteDate, populateForm, dateSql, ordenar } from '../Utils';
import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap'
import ReactTable from "react-table";
import { Icon } from 'react-icons-kit'
import { edit } from 'react-icons-kit/ionicons/edit'
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash'
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle'
import { ic_file_download } from 'react-icons-kit/md/ic_file_download'
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [month, day, year] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroAssociados extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			arquivo: {},
			novoArquivo: false,
			arquivoAssociado: {},
			novoArquivoAssociado: false,
			modalDependentes: { show: false },
			modalProcessos: { show: false },
			modalArquivos: { show: false },
			selected: {
				cidadeComerc: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.submitData = this.submitData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.submitDataDependentes = this.submitDataDependentes.bind(this);
		this.submitDataProcessos = this.submitDataProcessos.bind(this);
		this.submitDataArquivos = this.submitDataArquivos.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSaveDependentes = this.handleSaveDependentes.bind(this);
		this.handleSaveProcessos = this.handleSaveProcessos.bind(this);
		this.handleSaveArquivos = this.handleSaveArquivos.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.showModalDependentes = this.showModalDependentes.bind(this);
		this.showModalProcessos = this.showModalProcessos.bind(this);
		this.showModalArquivos = this.showModalArquivos.bind(this);
		this.selectCidadeComerc = this.selectCidadeComerc.bind(this);
		this.selectCidadeResid = this.selectCidadeResid.bind(this);
		this.selectAdvogado = this.selectAdvogado.bind(this);
		this.selectTipoAcao = this.selectTipoAcao.bind(this);
		this.modalStateDependentes = this.modalStateDependentes.bind(this)
		this.modalStateProcessos = this.modalStateProcessos.bind(this)
		this.handleSelectedFile = this.handleSelectedFile.bind(this)
		this.handleSelectedFileAssociado = this.handleSelectedFileAssociado.bind(this)
		this.modalStateArquivos = this.modalStateArquivos.bind(this)
		this.showIframeArquivo = this.showIframeArquivo.bind(this)
		this.handleSituacao = this.handleSituacao.bind(this);
	}


	populaGrau(item, id) {
		if (item.type === 'grau') {
			return (
				<option value={item.value} key={id}>{item.display}</option>
			)
		}
	}

	async showModalDependentes(e, codigo) {
		// e.stopPropagation()
		e.preventDefault();
		console.log(e.target)
		let edicao = false
		let pk = '0'
		if (Number(codigo) > 0) {
			edicao = true
			pk = codigo
			await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getDependentes?pk=' + (Number(codigo)).toString()).then(r => r.json()).then(async r => {
				// await fetch(config.backend+'/getDependentes?pk='+(Number(e.target.id)).toString()).then(r => r.json()).then(async r => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/associados'
				} else {
					let form = document.getElementById('registroDependentes');
					console.log(r[0]);
					r[0].data_nasc = dateSql(r[0].data_nasc)
					console.log(r[0].data_nasc)
					r[0].unimed_sos_dep = r[0].unimed_sos
					r[0].taxa_adm_dep = r[0].taxa_adm
					await populateForm(form, r[0])
				}
			})
		} else {
			edicao = false
			document.getElementById("registroDependentes").reset();
		}
		this.setState({ modalDependentes: { show: true }, edit: edicao, codigo: pk })
	}

	modalStateDependentes() {
		if (this.state.modalDependentes.show === true) {
			return 'ModalShow'
		} else {
			return 'ModalHide'
		}
	}

	selectCidadeComerc(item) {
		let selected = this.state.selected;
		selected.cidadeComerc = item;
		this.setState({ selected: selected });
	}

	selectCidadeResid(item) {
		let selected = this.state.selected;
		selected.cidadeResid = item;
		this.setState({ selected: selected });
	}

	populaLotacao(item, id) {
		if (item.type === 'lotacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}


	populaSituacao(item, id) {
		if (item.type === 'situacao') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaCategoria(item, id) {
		if (item.type === 'categoria') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaBanco(item, id) {
		if (item.type === 'banco') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	populaCidade(item, id) {
		if (item.type === 'cidade') {
			return (
				<option value={item.value} key={id}>
					{item.display}
				</option>
			);
		}
	}

	closeModal(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: "Atenção!",
			text: "Ao cancelar, todas as alterações serão descartadas. Confirma?",
			buttons: ["Sim", "Não"],
		}).then((result) => {
			if (!result) this.setState({ modalDependentes: { show: false }, modalProcessos: { show: false }, modalArquivos: { show: false } })
		});

	}


	async showModalProcessos(e, codigo) {
		// e.stopPropagation()
		e.preventDefault();
		// console.log(e.target);
		let edicao = false;
		let pk = '0';
		if (Number(codigo) > 0) {
			edicao = true;
			pk = codigo;
			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getProcessos?pk=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					// await fetch(config.backend+'/getProcessos?pk='+(Number(e.target.id)).toString()).then(r => r.json()).then(async r => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/associados';
					} else {
						let form = document.getElementById('registroProcessos');
						let selectedAdvogado = this.state.combos.filter((item) => {
							return item.type === 'advogados' && item.value === r[0].fk_adv;
						});
						r[0].data = dateSql(r[0].data);
						this.setState({
							selected: {
								advogado: selectedAdvogado[0]
							},
							arquivo: { name: r[0].arquivo },
							novoArquivo: false,
							enderecoArquivo: r[0].arquivo ?
								config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/static/processos/arquivos/' +
								r[0].arquivo : null
						});
						// console.log(selectedAdvogado)
						await populateForm(form, r[0]);
					}
				});
		} else {
			this.setState({
				selected: {
					advogado: []
				},
				arquivo: {},
				novoArquivo: false,
				enderecoArquivo: null
			});
			edicao = false;
			document.getElementById('registroProcessos').reset();
		}
		this.setState({ modalProcessos: { show: true }, edit: edicao, codigo: pk });
	}

	async showModalArquivos(e, codigo) {
		// e.stopPropagation()
		e.preventDefault();
		// console.log(e.target);
		let edicao = false;
		let pk = '0';
		if (Number(codigo) > 0) {
			edicao = true;
			pk = codigo;
			await fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/getArquivos?pk=' +
				Number(codigo).toString()
			)
				.then((r) => r.json())
				.then(async (r) => {
					// await fetch(config.backend+'/getProcessos?pk='+(Number(e.target.id)).toString()).then(r => r.json()).then(async r => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/associados';
					} else {
						let form = document.getElementById('registroArquivos');
						this.setState({
							arquivoAssociado: { name: r[0].nome },
							novoArquivoAssociado: false,
							enderecoArquivoAssociado: r[0].nome ?
								config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/static/arquivos/' + getParameterByName('pk') + '/' +
								r[0].nome : null
						});
						// console.log(selectedAdvogado)
						await populateForm(form, r[0]);
					}
				});
		} else {
			this.setState({
				arquivoAssociado: {},
				novoArquivoAssociado: false,
				enderecoArquivoAssociado: null
			});
			edicao = false;
			document.getElementById('registroArquivos').reset();
		}
		this.setState({ modalArquivos: { show: true }, edit: edicao, codigo: pk });
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	selectFileAssociado(e) {
		e.preventDefault();
		$('#html_btnAssociado').click();
	}

	showIframeArquivo() {
		if (this.state.arquivoAssociado.name && ('doc|docx').indexOf(this.state.arquivoAssociado.name.substr(this.state.arquivoAssociado.name.indexOf('.') + 1, this.state.arquivoAssociado.name.length)) >= 0)
			return (<div>Baixe o arquivo para visualizá-lo.
				<button className="ModalButton" onClick={(e) => {
					e.preventDefault();
					const link = document.createElement('a');
					link.target = "_blank"
					link.href = config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/static/arquivos/' + getParameterByName('pk') + '/' +
						this.state.arquivoAssociado.name;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}} style={{ width: '10%' }}>
					<Icon size={20} icon={ic_file_download} />
				</button>
			</div>
			)
		else
			return <iframe
				src={this.state.enderecoArquivoAssociado}
				style={{
					border: 'none',
					width: '100%',
					height: this.state.enderecoArquivoAssociado ? '400px' : '10px',
					marginTop: '15px'
				}}
				title="Arquivo"
			/>
	}

	selectAdvogado(item) {
		this.setState({
			selected: {
				advogado: item
			}
		});
	}

	selectTipoAcao(item) {
		this.setState({
			selected: {
				tipoAcao: item
			}
		});
	}

	async handleSelectedFile(e) {
		$('#arquivo').val(true);
		let file = e.target.files[0];
		let data = new FormData();
		data.append('arquivo', file, file.name);
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivoProcesso',
			data
		);
		this.setState({
			arquivo: file,
			novoArquivo: true,
			temArquivo: 'S',
			enderecoArquivo:
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/static/processos/arquivos/temp.pdf'
		});
	}

	async handleSelectedFileAssociado(e) {
		$('#arquivoAssociado').val(true);
		let file = e.target.files[0];
		console.log(file)
		let data = new FormData();
		data.append('arquivoAssociado', file, file.name);
		let format = file.name.substr(file.name.indexOf('.') + 1, file.name.length)
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivoAssociado?format=' + format + '&fk_ass=' + getParameterByName('pk'),
			data
		);
		this.setState({
			arquivoAssociado: file,
			novoArquivoAssociado: true,
			temArquivoAssociado: 'S',
			enderecoArquivoAssociado:
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/static/arquivos/' + getParameterByName('pk') + '/temp.' + format
		});
	}

	modalStateProcessos() {
		if (this.state.modalProcessos.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalStateArquivos() {
		if (this.state.modalArquivos.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		//Lotação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getLotacoes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosLotacao = [];
				await asyncForEach(r, async (item) => {
					let lotacao = {
						type: 'lotacao',
						display: item.descricao,
						value: item.pk_lot
					};
					await combosLotacao.push(lotacao);
				});

				combosLotacao.sort((a, b) => {
					a = a.display.toLowerCase();
					b = b.display.toLowerCase();
					return a < b ? -1 : a > b ? 1 : 0;
				});
				this.setState({ combos: combosLotacao });
			});

		//Advogados
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAdvogados')
			.then((r) => r.json())
			.then(async (r) => {
				let combosAdvogados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let advogados = {
						type: 'advogados',
						display: item.nome,
						value: item.pk_adv
					};
					await combosAdvogados.push(advogados);
				});
				this.setState({ combos: combosAdvogados });
			});


		//Graus
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getGrauParentesco').then(r => r.json()).then(async r => {
			let combosGrau = this.state.combos
			await asyncForEach(r, async (item) => {
				let grau = {
					type: 'grau',
					display: item.descricao,
					value: item.pk_tde
				}
				await combosGrau.push(grau)
			})
			this.setState({ combos: combosGrau })
		})

		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSituacoes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosSituacao = this.state.combos;
				await asyncForEach(r, async (item) => {
					let situacao = {
						type: 'situacao',
						display: item.descricao,
						value: item.pk_sit
					};
					combosSituacao.sort((a, b) => {
						a = a.display.toLowerCase();
						b = b.display.toLowerCase();
						return a < b ? -1 : a > b ? 1 : 0;
					});
					await combosSituacao.push(situacao);
				});
				this.setState({ combos: combosSituacao });
			});

		//Categoria
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let combosCategoria = this.state.combos;
				await asyncForEach(r, async (item) => {
					let categoria = {
						type: 'categoria',
						display: item.descricao,
						value: item.pk_cat
					};
					combosCategoria.sort((a, b) => {
						a = a.display.toLowerCase();
						b = b.display.toLowerCase();
						return a < b ? -1 : a > b ? 1 : 0;
					});
					await combosCategoria.push(categoria);
				});
				this.setState({ combos: combosCategoria });
			});

		//Cidades
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosCidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let cidades = {
						type: 'cidade',
						display: item.descricao,
						value: item.pk_cid
					};
					// console.log(cidades)
					await combosCidades.push(cidades);
				});
				// console.log(combosCidades)
				this.setState({ combos: combosCidades });
			});

		//Bancos
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getBancos')
			.then((r) => r.json())
			.then(async (r) => {
				let combosBancos = this.state.combos;
				await asyncForEach(r, async (item) => {
					let bancos = {
						type: 'banco',
						display: item.nome,
						value: item.pk_bco
					};

					await combosBancos.push(bancos);
				});
				// console.log(combosBancos)
				this.setState({ combos: combosBancos });
			});

		//Advogados
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTiposAcoes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosTiposAcoes = this.state.combos;
				await asyncForEach(r, async (item) => {
					let tiposAcoes = {
						type: 'tiposAcoes',
						display: item.descricao,
						value: item.pk_obj
					};
					await combosTiposAcoes.push(tiposAcoes);
				});
				this.setState({ combos: combosTiposAcoes });
			});

		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));

		if (query.pk > 0) {
			fetch(
				config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados?pk=' + query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/associados';
					} else {
						let form = document.getElementById('registroAssociados');
						let selectedCidadeComerc = this.state.combos.filter((item) => {
							return item.type === 'cidade' && item.value === r[0].fk_cid_comerc;
						});
						let selectedCidadeResid = this.state.combos.filter((item) => {
							return item.type === 'cidade' && item.value === r[0].fk_cid_resid;
						});
						this.setState({
							selected: {
								cidadeComerc: selectedCidadeComerc[0],
								cidadeResid: selectedCidadeResid[0]
							}
						});
						r[0].data_nasc = dateSql(r[0].data_nasc);
						r[0].data_rg = dateSql(r[0].data_rg);
						await populateForm(form, r[0]);
					}
				});

			fetch(
				config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados?pk=' + query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/associados';
					} else {
						this.setState({ associado: r[0] });
						fetch(
							config.protocol +
							'://' +
							config.server +
							':' +
							config.portBackend +
							'/api/getProcessos?fk=' +
							query.pk
						)
							.then((r) => r.json())
							.then(async (r) => {
								console.log(r[0]);
								let items = await this.modelingDataProcessos(r);
								this.setState({ dataProcessos: items });
							});
					}
				});

			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados?pk=' + query.pk).then(r => r.json()).then(async r => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/associados'
				} else {
					this.setState({ associado: r[0] })
					fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getDependentes?fk=' + query.pk).then(r => r.json()).then(async r => {
						console.log(r[0])
						let items = await this.modelingDataDependentes(r)
						this.setState({ dataDependentes: items })
					})
					fetch(
						config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/getArquivos?fk=' +
						query.pk
					)
						.then((r) => r.json())
						.then(async (r) => {
							console.log(r[0]);
							let items = await r;
							this.setState({ dataArquivos: items });
						});
				}

			})


			this.setState({ edit: true });
		}

		ordenar('fk_cat')
		ordenar('fk_gra')
		ordenar('fk_bco')
		ordenar('fk_sit')
		ordenar('fk_lot')
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		console.log(e.target.value)
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleSaveDependentes(e) {
		e.preventDefault()
		swal({
			title: "Atenção!",
			text: this.state.edit ? "As alterações no registro serão salvas. Confirma?" : "O registro será incluído. Confirma?",
			buttons: ["Sim", "Não"],
		}).then((result) => {
			if (!result) this.submitDataDependentes(e)
		});
	}

	async modelingDataDependentes(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_nasc_str = garanteDate(item.data_nasc)
				item.carteira_unimed = item.carteira_unimed_hospitalar || item.carteira_unimed_ambulat || item.carteira_unimed_global || item.carteira_unimed_odonto || ''
			})
			resolve(data)
		})
	}

	async modelingDataProcessos(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_str = garanteDate(item.data);
			});
			resolve(data);
		});
	}

	handleDeleteDependentes(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: "Atenção!",
			text: "O registro selecionado será excluído. Confirma?",
			buttons: ["Sim", "Não"],
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/deleteDependentes?pk=' + pk, {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify({})
				}).then(r => r.json()).then(r => {
					console.log(r)
					if (r.message === "Success!") {
						swal("Exlusão realizada", "Registro excluído com sucesso!", "success").then((result) => {
							this.componentDidMount()
						});
					} else {
						swal("Exclusão não realizada", "Registro não foi excluído.", "error");
					}
				})
			}
		});

	}

	submitDataDependentes(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroDependentes');
		const data = new FormData(form);
		const fk = Number(getParameterByName('pk'))

		let simnao = [
			'unimed_sos',
			'taxa_adm_dep'
		];

		for (let field of simnao) {
			let value = data.get(field);
			if (value !== 'S') {
				data.set(field, 'N');
			}
		}

		data.set('unimed_sos', data.get('unimed_sos_dep'))
		data.delete('unimed_sos_dep')
		data.set('taxa_adm', data.get('taxa_adm_dep'))
		data.delete('taxa_adm_dep')
		console.log(data.keys())
		//Trata valores conforme data-parse dos inputs
		// for (let name of data.keys()) {
		//     const input = form.elements[name]

		//     // Rejeita type = undefined (radio inputs)
		//     if (typeof input.type !== 'undefined') {
		//         const parserName = input.dataset.parse;
		//         if (parserName) {
		//             const parser = inputParsers[parserName];
		//             const parsedValue = parser(data.get(name));
		//             data.set(name, parsedValue);
		//         }
		//     }
		//     console.log(name, input.value)
		// }

		let validate = this.validateDataDependentes(data)

		if (!validate.isValid) {
			swal(validate.title, validate.message, "error");
			throw validate
		}


		//Insere FK
		data.append('fk_asstitular', fk)

		//Converte FormData em JSON
		var object = {};
		data.forEach(function (value, key) {
			if (value !== "") {
				object[key] = value;
			} else {
				object[key] = null;
			}
		});
		var json = JSON.stringify(object);


		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json)
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/editDependentes?pk=' + this.state.codigo, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			}).then(r => r.json()).then(r => {
				console.log(r)
				if (r.message === "Success!") {
					swal("Alteração realizada", "Registro alterado com sucesso!", "success").then((result) => {
						this.setState({ modalDependentes: { show: false } })
						this.componentDidMount()
					});
				} else {
					swal("Alteração não realizada", "Registro não foi alterado. Verifique os campos.", "error");
				}
			})
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoDependentes', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			}).then(r => r.json()).then(r => {
				console.log(r)
				if (r.message === "Success!") {
					swal("Inclusão realizada", "Registro incluído com sucesso!", "success").then((result) => {
						this.setState({ modalDependentes: { show: false } })
						this.componentDidMount()
					});
				} else {
					swal("Inclusão não realizada", "Registro não foi incluído. Verifique os campos.", "error");
				}
			})
		}





	}

	submitDataProcessos(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroProcessos');
		const data = new FormData(form);
		const fk = Number(getParameterByName('pk'));

		let validate = this.validateDataProcessos(data);

		data.delete('pdf');

		if (!validate.isValid) {
			swal(validate.title, validate.message, 'error');
			throw validate;
		}

		console.log(data.keys());

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			// Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
			console.log(name, input.value);
		}

		//Insere FK
		data.append('fk_ass', fk);
		if (this.state.novoArquivo) {
			data.append('arquivo', this.state.arquivo.name);
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function (value, key) {
			if (value !== '') {
				object[key] = value;
			} else {
				object[key] = null;
			}
		});
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/editProcessos?pk=' +
				this.state.codigo,
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal(
							'Alteração realizada',
							'Registro alterado com sucesso!',
							'success'
						).then(async (result) => {
							if (this.state.novoArquivo) {
								await axios.get(
									config.protocol +
									'://' +
									config.server +
									':' +
									config.portBackend +
									'/api/saveArquivoProcesso?name=' +
									this.state.arquivo.name.substr(0, this.state.arquivo.name.indexOf('.'))
								);
							}
							this.setState({ modalProcessos: { show: false } });
							this.componentDidMount();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoProcessos', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						if (this.state.novoArquivo) {
							await axios.get(
								config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/api/saveArquivoProcesso?name=' +
								this.state.arquivo.name.substr(0, this.state.arquivo.name.indexOf('.'))
							);
						}
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							this.setState({ modalProcessos: { show: false } });
							this.componentDidMount();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	submitDataArquivos(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroArquivos');
		const data = new FormData(form);
		const fk = Number(getParameterByName('pk'));

		data.delete('arquivo');

		console.log(data.keys());

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];

			// Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
			console.log(name, input.value);
		}

		//Insere FK
		data.append('fk_ass', fk);
		if (this.state.novoArquivoAssociado) {
			data.append('nome', this.state.arquivoAssociado.name);
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function (value, key) {
			if (value !== '') {
				object[key] = value;
			} else {
				object[key] = null;
			}
		});
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/editArquivos?pk=' +
				this.state.codigo,
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal(
							'Alteração realizada',
							'Registro alterado com sucesso!',
							'success'
						).then(async (result) => {
							if (this.state.novoArquivoAssociado) {
								await axios.get(
									config.protocol +
									'://' +
									config.server +
									':' +
									config.portBackend +
									'/api/saveArquivoAssociado?name=' +
									this.state.arquivoAssociado.name +
									'&fk_ass=' + fk
								);
							}
							this.setState({ modalArquivos: { show: false } });
							this.componentDidMount();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoArquivos', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then(async (r) => {
					console.log(r);
					if (r.message === 'Success!') {
						if (this.state.novoArquivoAssociado) {
							await axios.get(
								config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/api/saveArquivoAssociado?name=' +
								this.state.arquivoAssociado.name +
								'&fk_ass=' + fk
							);
						}
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							this.setState({ modalArquivos: { show: false } });
							this.componentDidMount();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	handleDeleteProcessos(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ["Sim", "Não"],
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/deleteProcessos?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								this.componentDidMount();
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído.', 'error');
						}
					});
			}
		});
	}

	handleDeleteArquivos(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ["Sim", "Não"],
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/deleteArquivos?pk=' +
					pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								this.componentDidMount();
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído.', 'error');
						}
					});
			}
		});
	}

	handleSaveProcessos(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: this.state.edit
				? 'As alterações no registro serão salvas. Confirma?'
				: 'O registro será incluído. Confirma?',
			buttons: ["Sim", "Não"],
		}).then((result) => {
			if (!result) this.submitDataProcessos(e);
		});
	}

	handleSaveArquivos(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: this.state.edit
				? 'As alterações no registro serão salvas. Confirma?'
				: 'O registro será incluído. Confirma?',
			buttons: ["Sim", "Não"],
		}).then((result) => {
			if (!result) this.submitDataArquivos(e);
		});
	}

	validateDataProcessos(data) {
		let valid = { isValid: true };
		//Consistências

		//Advogado
		if (data.get('fk_adv')) {
			valid = { isValid: true };
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo ADVOGADO.' };
		}

		//NOME DA AÇÃO
		if (data.get('fk_obj')) {
			valid = { isValid: true };
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo NOME DA AÇÃO.' };
		}

		//Numero do processo
		if (data.get('nro_processo')) {
			valid = { isValid: true };
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo NÚMERO DO PROCESSO.' };
		}

		//Data
		if (data.get('data')) {
			valid = { isValid: true };
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DATA.' };
		}

		console.log(valid);
		//Retorna resultado
		return valid;
	}

	validateDataDependentes(data) {
		let valid = { isValid: true }
		//Consistências

		//Nome
		if (data.get('nome')) {
			valid = { isValid: true }
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo NOME.' }
		}


		//Grau de Parentesco
		if (data.get('fk_gra') !== 'NNN') {
			valid = { isValid: true }
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo GRAU DE PARENTESCO.' }
		}

		//Data
		if (data.get('data_nasc')) {
			valid = { isValid: true }
		} else {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo DATA NASCIMENTO.' }
		}


		console.log(valid)
		//Retorna resultado
		return valid
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: ['Sim', 'Não'],
			reverseButtons: true
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: ['Sim', 'Não'],
			reverseButtons: true
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	handleSituacao(e) {
		if (e.target.value == 6 && !this.state.edit){
			$('#taxa_adm').prop( "checked", true );
		} else {
			$('#taxa_adm').prop( "checked", false );
		}
	}

	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroAssociados');
		const data = new FormData(form);

		let simnao = [
			'ativo',
			'whatsapp',
			'inadimplente',
			'envia_email_resid',
			'envia_email_com',
			'unimed_hospitalar',
			'unimed_ambulatorial',
			'unimed_global',
			'unimed_sos',
			'unimed_odonto',
			'taxa_adm',
			'telefonia_vivo',
			'telefonia_claro',
			'anape'
		];

		for (let field of simnao) {
			let value = data.get(field);
			if (value !== 'S') {
				data.set(field, 'N');
			}
		}

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			//Rejeita type = undefined (radio inputs)
			if (typeof input.type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}
		
		
		

		//Converte FormData em JSON
		var object = {};
		data.forEach(function (value, key) {
			if (String(value) === '') {
				object[key] = null;
			} else {
				object[key] = value;
			}
		});
		var json = JSON.stringify(object);

		// erros
		let erros = []
		let verificaCampos=['cobranca_mensalidade',
		'cobranca_unimed',
		'cobranca_telefonia',
		'cobranca_servicos']
		
		verificaCampos.forEach(function (value, key) {
			if (object[value] == null) {
				erros.push(value);
			} 
		});
		console.log(erros)
		
		if(erros.length > 0){
			return swal('Alteração não realizada', 'Verifique os campos '+erros.join(', ')+'.', 'error');
		}
		
		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/editAssociado?pk=' +
				getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoAssociado', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}

	render() {
		let lotacoes = this.state.combos.map(this.populaLotacao);
		let situacoes = this.state.combos.map(this.populaSituacao);
		let categorias = this.state.combos.map(this.populaCategoria);
		let graus = this.state.combos.map(this.populaGrau)
		let nomeAss = $('#nomeAss').val();
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidade';
		});
		let advogados = this.state.combos.filter((item) => {
			return item.type === 'advogados';
		});
		let tiposAcoes = this.state.combos.filter((item) => {
			return item.type === 'tiposAcoes';
		});
		console.log(cidades);
		console.log(this.state.combos);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Associados" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Associado</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroAssociados" name="registroAssociados" onSubmit={this.submitData}>
								<div style={{ height: 'auto' }}>
									<label>{Number(getParameterByName('pk')) > 0 ? 'Associado: ' + nomeAss : ''}</label>
									<Tabs defaultActiveKey={1} animation={false} id="uncontrolled-tab-example">
										<Tab eventKey={1} title="Principal">
											<div style={{ paddingTop: '30px' }}>
												<div className="row">
													<div className="col-sm-2" style={{ width: '100px' }}>
														<label>Código:</label>
														<input
															type="text"
															id="pk_ass"
															name="pk_ass"
															className="form-control"
															maxLength="10"
															disabled
														/>
													</div>

													<div className="col-sm-3" style={{ width: '150px' }}>
														<label>Matrícula:</label>
														<input
															type="text"
															id="matricula"
															name="matricula"
															className="form-control"
															maxLength="10"
														/>
													</div>
													<div className="col-sm-5">
														<label>Nome:</label>
														<input
															type="text"
															id="nomeAss"
															name="nome"
															data-parse="uppercase"
															className="form-control"
														/>
													</div>
													<div className="col-sm-2" style={{ width: '150px' }}>
														<label>RG:</label>
														<input
															type="text"
															id="rg"
															name="rg"
															className="form-control"
															maxLength="10"
														/>
													</div>
													<div className="col-sm-3" style={{ width: '150px' }}>
														<label>CPF:</label>
														<input
															type="text"
															id="cpf"
															name="cpf"
															className="form-control"
															maxLength="11"
														/>
													</div>
													<div className="col-sm-5">
														<label>Nome do Pai:</label>
														<input
															type="text"
															id="nome_pai"
															name="nome_pai"
															className="form-control"
															data-parse="uppercase"
														/>
													</div>
													<div className="col-sm-5">
														<label>Nome da Mãe:</label>
														<input
															type="text"
															id="nome_mae"
															name="nome_mae"
															className="form-control"
															data-parse="uppercase"
														/>
													</div>
													<div className="col-sm-6">
														<div className="col-sm-9" style={{ marginLeft: '-15px' }}>
															<label>Email pessoal:</label>
															<input
																type="text"
																id="email_resid"
																name="email_resid"
																className="form-control notUpper"
															/>
														</div>
														<br />
														<div className="col-sm-3" style={{ marginLeft: '-15px' }}>
															<div className="form-check">
																<input
																	type="checkbox"
																	className="check"
																	name="envia_email_resid"
																	value="S"
																/>Enviar Email
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="col-sm-9" style={{ marginLeft: '-15px' }}>
															<label>Email Funcional:</label>
															<input
																type="text"
																id="email_comerc"
																name="email_comerc"
																className="form-control notUpper"
															/>
														</div>
														<br />
														<div className="col-sm-3" style={{ marginLeft: '-15px' }}>
															<input
																type="checkbox"
																className="check"
																name="envia_email_com"
																value="S"
															/>{' '}
															Enviar email
														</div>
													</div>

													<div className="col-sm-2" style={{ width: '150px' }}>
														<label>Sexo:</label>
														<select
															name="sexo"
															id="sexo"
															data-parse="uppercase"
															className="form-control"
														>
															<option value="N">Selecione</option>
															<option value="M">Masculino</option>
															<option value="F">Feminino</option>
														</select>
													</div>
													<div className="col-sm-2" style={{ width: '150px' }}>
														<label>Estado civil:</label>
														<select
															name="estado_civil"
															id="estado_civil"
															data-parse="uppercase"
															className="form-control"
														>
															<option value="N">Selecione</option>
															<option value="S">Solteiro</option>
															<option value="C">Casado</option>
															<option value="P">Separado</option>
															<option value="D">Divorciado</option>
															<option value="V">Viúvo</option>
														</select>
													</div>
													<div className="col-sm-2" style={{ width: '180px' }}>
														<label>Data nascimento:</label>
														<input type="date" name="data_nasc" className="form-control" />
													</div>
													<div className="col-sm-3" style={{ width: '200px' }}>
														<label>Nr. OAB:</label>
														<input type="text" name="nro_oab" className="form-control" />
													</div>
													<div className="col-sm-3" style={{ width: '200px' }}>
														<label>Lotação:</label>
														<select
															name="fk_lot"
															id="fk_lot"
															className="form-control"
															data-parse="uppercase"
															required="required"
														>
															{lotacoes}
															<option value="">Selecione</option>
														</select>
													</div>
													<div className="col-sm-3" style={{ width: '200px' }}>
														<label>Situação:</label>
														<select
															name="fk_sit"
															onChange={this.handleSituacao}
															id="fk_sit"
															className="form-control"
															data-parse="uppercase"
															required="required"
														>
															{situacoes}
															<option value="">Selecione</option>
														</select>
													</div>
													<div className="col-sm-3" style={{ width: '230px' }}>
														<label>Categoria de Associado:</label>
														<select
															name="fk_cat"
															id="fk_cat"
															className="form-control"
															data-parse="uppercase"
															required="required"
														>
															{categorias}
															<option value="">Selecione</option>
														</select>
													</div>
													<div className="col-sm-4" style={{width: '900px'}}>
														<label>Celulares:</label>
														<input
															type="text"
															id="celulares"
															name="celulares"
															className="form-control notUpper"
														/>
													</div>
													<div className="col-sm-12" style={{ marginTop: '10px' }}>
														<input
															type="checkbox"
															className="check"
															name="ativo"
															value="S"
														/>{' '}
														Associado
													</div>
													{/* <div className="col-sm-12" style={{ marginTop: '10px' }}>
														<input
															type="checkbox"
															className="check"
															name="falecido"
															value="S"
														/>{' '}
														Falecido
													</div> */}
													<div
														className="col-sm-12"
														style={{ marginTop: '10px', marginBottom: '10px' }}
													>
														<input
															type="checkbox"
															className="check"
															name="whatsapp"
															value="S"
														/>{' '}
														Habilitado para receber mensagens whatsapp
													</div>
													<div
														className="col-sm-12"
														style={{ marginBottom: '10px' }}
													>
														<input
															type="checkbox"
															className="check"
															name="inadimplente"
															value="S"
														/>{' '}
														Inadimplente
													</div>
													
													<div className="col-sm-12">
														<label>Observações:</label>
														<textarea
															type="text"
															name="observacoes"
															data-parse="uppercase"
															className="form-control"
															style={{ height: '100px' }}
														/>
													</div>
												</div>
											</div>
										</Tab>
										<Tab eventKey={2} title="Endereço">
											<div style={{ paddingTop: '30px' }}>
												<div className="row">
													<div className="col-sm-12">
														<label>Residencial:</label>
													</div>
													<div className="col-sm-12">
														<label>Endereço:</label>
														<input
															type="text"
															data-parse="uppercase"
															name="endereco_resid"
															className="form-control"
														/>
													</div>
													<div className="col-sm-3">
														<label>Bairro:</label>
														<input
															type="text"
															data-parse="uppercase"
															name="bairro_resid"
															className="form-control"
														/>
													</div>
													<div className="col-sm-3">
														<label>Cidade:</label>
														<Autocomplete
															field="fk_cid_resid"
															items={cidades}
															inModal=""
															selected={this.state.selected.cidadeResid || []}
															select={this.selectCidadeResid}
														/>
													</div>
													<div className="col-sm-2" style={{ width: '120px' }}>
														<label>CEP:</label>
														<input type="text" name="cep_resid" className="form-control" />
													</div>
													<div className="col-sm-2" style={{ width: '180px' }}>
														<label>Telefone residencial:</label>
														<input
															type="text"
															name="telefone_resid"
															className="form-control"
														/>
													</div>
												</div>
											</div>
											{/* <div style={{ paddingTop: '30px' }}>
												<div className="row">
													<div className="col-sm-12">
														<label>Comercial:</label>
													</div>
													<div className="col-sm-12">
														<label>Endereço:</label>
														<input
															type="text"
															name="endereco_comerc"
															data-parse="uppercase"
															className="form-control"
														/>
													</div>
													<div className="col-sm-3">
														<label>Bairro:</label>
														<input
															type="text"
															name="bairro_comerc"
															data-parse="uppercase"
															className="form-control"
														/>
													</div>
													<div className="col-sm-3">
														<label>Cidade:</label>
														<Autocomplete
															field="fk_cid_comerc"
															items={cidades}
															inModal=""
															selected={this.state.selected.cidadeComerc || []}
															select={this.selectCidadeComerc}
														/>
													</div>
													<div className="col-sm-2" style={{ width: '120px' }}>
														<label>CEP:</label>
														<input type="text" name="cep_comerc" className="form-control" />
													</div>
													<div className="col-sm-2" style={{ width: '180px' }}>
														<label>Telefone comercial:</label>
														<input
															type="text"
															name="telefone_comerc"
															className="form-control"
														/>
													</div>
												</div>
											</div> */}
										</Tab>
										<Tab eventKey={3} title="Cobrança">
											<div style={{ paddingTop: '30px' }}>
												<div className="row">
													<div className="col-sm-8">
														<legend>Formas de Cobrança</legend>
														<div className="row">
															<div className="col-sm-12">
																<label>Banrisul:</label>
															</div>
															<div style={{ paddingTop: '30px' }}>
																<div className="col-sm-2">
																	Agência:
																	<input
																		type="text"
																		name="agencia"
																		data-parse="uppercase"
																		className="form-control"
																		style={{ width: '60px' }}
																	/>
																</div>
																<div className="col-sm-2">
																	Conta:
																	<input
																		type="text"
																		name="conta"
																		data-parse="uppercase"
																		className="form-control"
																		style={{ width: '130px' }}
																	/>
																</div>
															</div>
														</div>
														<br />
														<div className='row'>
															<div className="col-sm-12">
																<label>Tesouro:</label>
															</div>
															<div className="col-sm-6">
																Contrato Mensalidade:
																<input
																	type="text"
																	name="tesouro_contrato_mensalidade"
																	data-parse="uppercase"
																	className="form-control"
																/>
															</div>
														</div>
														<div className='row'>
															<div className="col-sm-6">
																Contrato Convênio:
																<input
																	type="text"
																	name="tesouro_contrato_convenio"
																	data-parse="uppercase"
																	className="form-control"
																/>
															</div>
														</div>
														<hr />

														<div className="row" >
															<div className="col-sm-12">
																<label>Mensalidades:</label>
																<input
																	type="radio"
																	name="cobranca_mensalidade"
																	value="B"
																/>{' '}
																Banco
																<input
																	type="radio"
																	name="cobranca_mensalidade"
																	value="T"
																/>{' '}
																Tesouro
																<input
																	type="radio"
																	name="cobranca_mensalidade"
																	value="P"
																/>{' '}
																Portabilidade
																<input
																	type="radio"
																	name="cobranca_mensalidade"
																	value="N"
																/>{' '}
																Nenhum
															</div>

														</div>
														<hr />
														<div className="row">
															<div className="col-sm-6">
																<label>Unimed:</label>
																<input
																	type="radio"
																	name="cobranca_unimed"
																	value="B"
																/>{' '}
																Banco
																<input
																	type="radio"
																	name="cobranca_unimed"
																	value="T"
																/>{' '}
																Tesouro
																<input
																	type="radio"
																	name="cobranca_unimed"
																	value="P"
																/>{' '}
																Portabilidade
																<input
																	type="radio"
																	name="cobranca_unimed"
																	value="N"
																/>{' '}
																Nenhum
															</div>
															<div className="col-sm-6">
																<input
																	type="checkbox"
																	name="unimed_hospitalar"
																	id="unimed_hospitalar"
																	value="S"
																/>{' '}
																Hospitalar
																<input
																	type="checkbox"
																	name="unimed_ambulatorial"
																	value="S"
																/>{' '}
																Ambulatorial
																<input
																	type="checkbox"
																	name="unimed_global"
																	value="S"
																/>{' '}
																Global
																<input
																	type="checkbox"
																	name="unimed_sos"
																	value="S"
																/>{' '}
																SOS
																<input
																	type="checkbox"
																	name="unimed_odonto"
																	value="S"
																/>{' '}
																Odonto
																<input
																	type="checkbox"
																	id="taxa_adm"
																	name="taxa_adm"
																	value="S"
																/>{' '}
																Taxa Administrativa
															</div>

															<div style={{ paddingTop: '30px' }}>
																<div className="col-sm-6">
																	Número Carteira Global:
																	<input
																		type="text"
																		id="carteira_unimed_global"
																		name="carteira_unimed_global"
																		className="form-control"
																	// maxLength="16"
																	/>
																</div>
																<div className="col-sm-6">
																	Número Carteira Ambulatorial:
																	<input
																		type="text"
																		id="carteira_unimed_ambulat"
																		name="carteira_unimed_ambulat"
																		className="form-control"
																	// maxLength="16"
																	/>
																</div>
																<div className="col-sm-6">
																	Número Carteira Hospitalar:
																	<input
																		type="text"
																		id="carteira_unimed_hospitalar"
																		name="carteira_unimed_hospitalar"
																		className="form-control"
																	// maxLength="16"
																	/>
																</div>
																<div className="col-sm-6">
																	Número Carteira Odonto:
																	<input
																		type="text"
																		id="carteira_unimed_odonto"
																		name="carteira_unimed_odonto"
																		className="form-control"
																	// maxLength="16"
																	/>
																</div>
															</div>
															<br />

														</div>
														<hr />
														<div className="row">
															<div className="col-sm-6">
																<label>Telefonia:</label>
																<input
																	type="radio"
																	name="cobranca_telefonia"
																	value="B"
																/>{' '}
																Banco
																{/* <input
																	type="radio"
																	name="cobranca_telefonia"
																	value="T"
																/>{' '}
																Tesouro */}
																<input
																	type="radio"
																	name="cobranca_telefonia"
																	value="P"
																/>{' '}
																Portabilidade
																<input
																	type="radio"
																	name="cobranca_telefonia"
																	value="N"
																/>{' '}
																Nenhum
															</div>
															<div className="col-sm-6">
																<input type="checkbox" name="telefonia_vivo" value="S" />{' '}
																Vivo
																<input
																	type="checkbox"
																	name="telefonia_claro"
																	value="S"
																/>{' '}
																Claro
															</div>
															<br />

														</div>
														<hr />
														<div className="row">
															<div className="col-sm-7">
																<label>Outros serviços:</label>
																<input
																	type="radio"
																	name="cobranca_servicos"
																	value="B"
																/>{' '}
																Banco
																{/* <input
																	type="radio"
																	name="cobranca_telefonia"
																	value="T"
																/>{' '}
																Tesouro */}
																<input
																	type="radio"
																	name="cobranca_servicos"
																	value="P"
																/>{' '}
																Portabilidade
																<input
																	type="radio"
																	name="cobranca_servicos"
																	value="N"
																/>{' '}
																Nenhum
															</div>
															<div className="col-sm-5">
																<input type="checkbox" name="anape" value="S" /> ANAPE
															</div>
															<br />
														</div>
													</div>
													<br />
													<div className="col-sm-3" style={{ marginLeft: '-10px' }} />
													<br />
												</div>
											</div>
										</Tab>
										<Tab eventKey={4} title="Dependentes" {... { disabled: getParameterByName('pk') ? false : true }}>
											<div style={{ paddingBottom: '30px' }}>
												<div className={this.modalStateDependentes()} tabIndex="-1">
													<Modal.Dialog className="Modal">
														<div>
															<Modal.Header className="ModalBg">
																<div className="ModalHeader">
																	<h3 className="headerModal">Registro de Dependente</h3>
																</div>
															</Modal.Header>
															<Modal.Body className="ModalBg" >
																<div className='ModalBody'>
																	<form id="registroDependentes" name="registroDependentes" onSubmit={this.submitDataDependentes}>
																		<div>
																			<label className="labelModal" style={{ fontSize: '1.1em' }}>Associado: {nomeAss}</label>
																		</div>
																		<div>
																			<label className="labelModal">Nome:</label>
																			<input type="text" name="nome" className="form-control" data-parse="uppercase" />
																		</div>
																		<div>
																			<label className="labelModal">Grau de Parentesco:</label>
																			<select name="fk_gra" id="fk_gra" className="form-control" data-parse="uppercase" style={{ width: '150px' }}>
																				{graus}
																				<option value="NNN">Selecione</option>
																			</select>
																		</div>
																		<div>
																			<label className="labelModal">Data de Nascimento:</label>
																			<input type="date" name="data_nasc" className="form-control" style={{ width: '150px' }} />
																		</div>
																		<div>
																			<label className="labelModal">Número Carteira Global:</label>
																			<input type="text" id="carteira_unimed_global" name="carteira_unimed_global" className="form-control" style={{ width: '180px' }} />
																		</div>
																		<div>
																			<label className="labelModal">Número Carteira Ambulatorial:</label>
																			<input type="text" id="carteira_unimed_ambulat" name="carteira_unimed_ambulat" className="form-control" style={{ width: '180px' }} />
																		</div>
																		<div>
																			<label className="labelModal">Número Carteira Hospitalar:</label>
																			<input type="text" id="carteira_unimed_hospitalar" name="carteira_unimed_hospitalar" className="form-control" style={{ width: '180px' }} />
																		</div>
																		<div>
																			<label className="labelModal">Número Carteira Odonto:</label>
																			<input type="text" id="carteira_unimed_odonto" name="carteira_unimed_odonto" className="form-control" style={{ width: '180px' }} />
																		</div>
																		<div style={{display: 'flex', alignItems:'center'}}>
																			<div>
																				<label className="labelModal">Unimed SOS:</label>
																				<select
																					name="unimed_sos_dep"
																					id="unimed_sos_dep"
																					data-parse="uppercase"
																					className="form-control"
																					style={{ width: '100px' }}
																				>
																					<option value="N">Não</option>
																					<option value="S">Sim</option>
																				</select>

																			</div>
																			<input
																				style={{marginTop: 35, marginLeft: 30}}
																				type="checkbox"
																				id="taxa_adm_dep"
																				name="taxa_adm_dep"
																				value="S"
																			/>{' '}
																			<p style={{marginTop: 35}}>
																				Taxa Administrativa

																			</p>
																		</div>
																		<div>
																			<label className="labelModal">Observações:</label>
																			<textarea type="text" name="observacoes" className="form-control" data-parse="uppercase" />
																		</div>
																	</form>
																</div>
															</Modal.Body>
															<Modal.Footer className="ModalBg">
																<div className="ModalFooter">
																	<button className="ModalButton" onClick={this.handleSaveDependentes}>Salvar</button>
																	<button className="ModalButton" onClick={this.closeModal}>Cancelar</button>
																</div>
															</Modal.Footer>
														</div>
													</Modal.Dialog>
												</div>
												<div
													style={{ marginTop: '30px' }}
												>
													<button className="buttonNovo" style={{ marginLeft: '0' }} onClick={this.showModalDependentes}><Icon size={20} style={{ display: "inline" }} icon={ic_add_circle}></Icon>Novo Registro</button>
													<br />
													<div className="divTabela">
														<ReactTable
															data={this.state.dataDependentes}
															previousText='Anterior'
															nextText='Próximo'
															loadingText='Carregando...'
															pageText='Página'
															ofText='de'
															rowsText='registros'
															noDataText="Nenhum registro encontrado"
															columns={[
																{
																	Header: '',
																	width: 70,
																	Cell: (row) => {
																		return (
																			<div className="">
																				<Icon size={20} icon={edit} className="iconButton" onClick={(e) => {
																					this.showModalDependentes(e, row.row.pk_ass);
																				}} />
																				<Icon size={20} icon={iosTrash} className="iconButton"
																					onClick={(e) => {
																						this.handleDeleteDependentes(e, row.row.pk_ass);
																					}} />
																			</div>
																		);
																	}
																},
																{
																	Header: "Código",
																	accessor: "pk_ass",
																	width: 50
																},
																{
																	Header: "Nome",
																	accessor: "nome",
																	width: 300
																},
																{
																	Header: "Grau de Parentesco",
																	accessor: "nomegra",
																	width: 120
																},
																{
																	Header: "Dt Nasc",
																	accessor: "data_nasc_str",
																	width: 100,
																},
																{
																	Header: "Carteira Unimed Global",
																	accessor: "carteira_unimed_global",
																	width: 140,
																},
																{
																	Header: "Carteira Unimed Odonto",
																	accessor: "carteira_unimed_odonto",
																	width: 140,
																},
																{
																	Header: "Carteira Unimed Ambulatorial",
																	accessor: "carteira_unimed_ambulat",
																	width: 170,
																},
																{
																	Header: "Carteira Unimed Hospitalar",
																	accessor: "carteira_unimed_hospitalar",
																	width: 160,
																}
															]}
															defaultSorted={[
																{
																	id: "numero",
																	desc: true
																}
															]}
															defaultPageSize={10}
															className="-striped -highlight"
														/>
													</div>
												</div>
											</div>
										</Tab>
										<Tab eventKey={5} title="Processos" {... { disabled: getParameterByName('pk') ? false : true }}>
											<div style={{ paddingBottom: '30px' }}>
												<div className={this.modalStateProcessos()} tabIndex="-1">
													<Modal.Dialog className="Modal">
														<div>
															<Modal.Header className="ModalBg">
																<div className="ModalHeader">
																	<h3 className="headerModal">Registro de Processos</h3>
																</div>
															</Modal.Header>
															<Modal.Body className="ModalBg">
																<div className="ModalBody">
																	<form
																		id="registroProcessos"
																		name="registroProcessos"
																		onSubmit={this.submitDataProcessos}
																	>
																		<div>
																			<label className="labelModal" style={{ fontSize: '1.1em' }}>Associado: {nomeAss}</label>
																		</div>
																		<div>
																			<label className="labelModal">Advogado:</label>
																			<Autocomplete
																				field="fk_adv"
																				items={advogados}
																				inModal="-inModal"
																				selected={this.state.selected.advogado}
																				select={this.selectAdvogado}
																			/>
																		</div>
																		<div>
																			<label className="labelModal">Ação:</label>
																			<Autocomplete
																				field="fk_obj"
																				items={tiposAcoes}
																				inModal="-inModal"
																				selected={this.state.selected.tipoAcao}
																				select={this.selectTipoAcao}
																			/>
																		</div>
																		<div>
																			<label className="labelModal">Número do processo:</label>
																			<input
																				type="text"
																				name="nro_processo"
																				className="form-control"
																				data-parse="uppercase"
																			/>
																		</div>
																		<div>
																			<label className="labelModal">Data:</label>
																			<input
																				type="date"
																				name="data"
																				className="form-control"
																				style={{ width: '150px' }}
																			/>
																		</div>
																		<div>
																			<label className="labelModal">Observação:</label>
																			<textarea
																				type="text"
																				name="observacao"
																				className="form-control"
																				data-parse="uppercase"
																				style={{ height: '150px' }}
																			/>
																		</div>
																		<div style={{ textAlign: 'left', marginTop: '15px' }}>
																			<input type="file" name="pdf"
																				id="html_btn"
																				accept=".pdf"
																				onChange={this.handleSelectedFile}
																			/>
																			{this.state.arquivo.name || 'Nenhum arquivo selecionado'}
																			<button
																				className="ModalButton"
																				type="file"
																				onClick={this.selectFile}
																				style={{ width: '110px' }}
																			>
																				Selecionar Arquivo
																			</button>
																		</div>
																		<iframe
																			src={this.state.enderecoArquivo}
																			style={{
																				border: 'none',
																				width: '100%',
																				height: this.state.enderecoArquivo ? '400px' : '10px',
																				marginTop: '15px'
																			}}
																			title="Arquivo do Processo"
																		/>
																	</form>
																</div>
															</Modal.Body>
															<Modal.Footer className="ModalBg">
																<div className="ModalFooter">
																	<button className="ModalButton" onClick={this.handleSaveProcessos}>
																		Salvar
																	</button>
																	<button className="ModalButton" onClick={this.closeModal}>
																		Cancelar
																	</button>
																</div>
															</Modal.Footer>
														</div>
													</Modal.Dialog>
												</div>
												<div style={{ marginTop: '30px' }}>
													{/* <LinkContainer to={"/apergs/associados/processos/registro?pk="+this.state.associado.pk_ass}> */}
													<button className="buttonNovo" style={{ marginLeft: '0' }} onClick={this.showModalProcessos}>
														<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo Registro
													</button>
													{/* </LinkContainer>  */}
													<br />
													<div className="divTabela">
														<ReactTable
															data={this.state.dataProcessos}
															previousText="Anterior"
															nextText="Próximo"
															loadingText="Carregando..."
															pageText="Página"
															ofText="de"
															rowsText="registros"
															noDataText="Nenhum registro encontrado"
															columns={[
																{
																	Header: '',
																	width: 70,
																	Cell: (row) => {
																		return (
																			<div className="">
																				<Icon size={20} icon={edit} className="iconButton" onClick={(e) => {
																					this.showModalProcessos(e, row.row.pk_asp);
																				}} />
																				<Icon size={20} icon={iosTrash} className="iconButton"

																					onClick={(e) => {
																						this.handleDeleteProcessos(e, row.row.pk_asp);
																					}} />
																			</div>
																		);
																	}
																},
																{
																	Header: 'Código',
																	accessor: 'pk_asp',
																	show: false
																},
																{
																	Header: 'Número do Processo',
																	accessor: 'nro_processo',
																	minWidth: 100
																},
																{
																	Header: 'Advogado',
																	accessor: 'nomeadv',
																	minWidth: 200
																},
																{
																	Header: 'Ação',
																	accessor: 'nomeobj',
																	minWidth: 200
																},
																{
																	Header: 'Data de Ajuizamento da Ação',
																	accessor: 'data_str',
																	minWidth: 100
																}
															]}
															defaultSorted={[
																{
																	id: 'numero',
																	desc: true
																}
															]}
															defaultPageSize={10}
															className="-striped -highlight"
														/>
													</div>
												</div>
											</div>
										</Tab>
										<Tab eventKey={6} title="Arquivos" {... { disabled: getParameterByName('pk') ? false : true }}>
											<div style={{ paddingBottom: '30px' }}>
												<div className={this.modalStateArquivos()} tabIndex="-1">
													<Modal.Dialog className="Modal">
														<div>
															<Modal.Header className="ModalBg">
																<div className="ModalHeader">
																	<h3 className="headerModal">Registro de Arquivos</h3>
																</div>
															</Modal.Header>
															<Modal.Body className="ModalBg">
																<div className="ModalBody">
																	<form
																		id="registroArquivos"
																		name="registroArquivos"
																		onSubmit={this.submitDataArquivos}
																	>
																		<div>
																			<label className="labelModal" style={{ fontSize: '1.1em' }}>Associado: {nomeAss}</label>
																		</div>
																		<div style={{ textAlign: 'left', marginTop: '15px' }}>
																			<input type="file" name="arquivo"
																				id="html_btnAssociado"
																				accept=".pdf, .jpg, .jpeg, .doc, .docx"
																				onChange={this.handleSelectedFileAssociado}
																			/>
																			{this.state.arquivoAssociado.name || 'Nenhum arquivo selecionado'}
																			<button
																				className="ModalButton"
																				type="file"
																				onClick={this.selectFileAssociado}
																				style={{ width: '110px' }}
																			>
																				Selecionar Arquivo
																			</button>
																		</div>
																		<div>
																			<label className="labelModal">Descrição:</label>
																			<textarea
																				type="text"
																				name="descricao"
																				className="form-control"
																				data-parse="uppercase"
																				style={{ height: '150px' }}
																			/>
																		</div>
																		{this.showIframeArquivo()}
																	</form>
																</div>
															</Modal.Body>
															<Modal.Footer className="ModalBg">
																<div className="ModalFooter">
																	<button className="ModalButton" onClick={this.handleSaveArquivos}>
																		Salvar
																	</button>
																	<button className="ModalButton" onClick={this.closeModal}>
																		Cancelar
																	</button>
																</div>
															</Modal.Footer>
														</div>
													</Modal.Dialog>
												</div>
												<div style={{ marginTop: '30px' }}>
													{/* <LinkContainer to={"/apergs/associados/processos/registro?pk="+this.state.associado.pk_ass}> */}
													<button className="buttonNovo" style={{ marginLeft: '0' }} onClick={this.showModalArquivos}>
														<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo Registro
													</button>
													{/* </LinkContainer>  */}
													<br />
													<div className="divTabela">
														<ReactTable
															data={this.state.dataArquivos}
															previousText="Anterior"
															nextText="Próximo"
															loadingText="Carregando..."
															pageText="Página"
															ofText="de"
															rowsText="registros"
															noDataText="Nenhum registro encontrado"
															columns={[
																{
																	Header: '',
																	width: 95,
																	Cell: (row) => {
																		return (
																			<div className="">
																				<Icon size={20} icon={edit} className="iconButton" onClick={(e) => {
																					this.showModalArquivos(e, row.row.pk_arq);
																				}} />
																				<Icon size={20} icon={ic_file_download} className="iconButton" onClick={(e) => {
																					const link = document.createElement('a');
																					link.target = "_blank"
																					link.href = config.protocol +
																						'://' +
																						config.server +
																						':' +
																						config.portBackend +
																						'/static/arquivos/' + getParameterByName('pk') + '/' +
																						row.row.nome;
																					document.body.appendChild(link);
																					link.click();
																					document.body.removeChild(link);
																				}} />
																				<Icon size={20} icon={iosTrash} className="iconButton"
																					onClick={(e) => {
																						this.handleDeleteArquivos(e, row.row.pk_arq);
																					}} />
																			</div>
																		);
																	}
																},
																{
																	Header: 'Código',
																	accessor: 'pk_arq',
																	show: false
																},
																{
																	Header: 'Nome',
																	accessor: 'nome',
																	minWidth: 100
																},
																{
																	Header: 'Descrição',
																	accessor: 'descricao',
																	minWidth: 500
																}
															]}
															defaultSorted={[
																{
																	id: 'nome',
																	desc: true
																}
															]}
															defaultPageSize={10}
															className="-striped -highlight"
														/>
													</div>
												</div>
											</div>
										</Tab>
									</Tabs>
								</div>
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroAssociados;
