import React, { Component } from "react";
import config from "../config";
import NavBar from "../components/NavBar";
import "../css/Default.css";
import { Modal } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  dateSql,
  ordenar,
  asyncForEach,
  getParameterByName,
  populateForm,
  onBlurCurrency, garanteDate
} from "../Utils";
import swal from "@sweetalert/with-react";
import { Icon } from "react-icons-kit";
import { edit } from "react-icons-kit/ionicons/edit";
import { iosSearchStrong } from "react-icons-kit/ionicons/iosSearchStrong";
import { ic_clear } from "react-icons-kit/md/ic_clear";
import { ic_email } from "react-icons-kit/md/ic_email";
import { coinDollar } from "react-icons-kit/icomoon/coinDollar";
import $ from "jquery";
import { ic_do_not_disturb_alt } from 'react-icons-kit/md/ic_do_not_disturb_alt'
import axios from 'axios';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';


const inputParsers = {
  date(input) {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase(input) {
    return input.toUpperCase();
  },
  number(input) {
    return parseFloat(input);
  },
};
const strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })
const auth = JSON.parse(strAuth) 

class Reservas extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      combos: [],
      filter: [],
      modal: { show: false },
      edit: false,
      filterChanged: "",
      data: [],
      arquivo: {},
      dataIev: [],
      total: 0,
      selectedData: [],

    };
    this.getData = this.getData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleClean = this.handleClean.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.requerimento = this.requerimento.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalArquivo = this.closeModalArquivo.bind(this);
    this.modalState = this.modalState.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getDataTax = this.getDataTax.bind(this);
    this.insertTax = this.insertTax.bind(this);
    this.limpaTax = this.limpaTax.bind(this);
    this.handleSelectedFile = this.handleSelectedFile.bind(this)
    this.showFile = this.showFile.bind(this)
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.toggleSelectRow = this.toggleSelectRow.bind(this);
    this.solicitarConfirmacao = this.solicitarConfirmacao.bind(this);

  }


  async solicitarConfirmacao(e) {
    e.preventDefault();
    console.log(this.state.selectedData)
    if (this.state.selectedData.length > 0) {

      swal({
        title: "Atenção!",
        text: "Confirma envio dos e-mails de confirmação de reserva para os registros selecionados?",
        buttons: ["Sim", "Não"],
      }).then(async (result) => {


        await axios.post(
          config.protocol + '://' + config.server + ':' + config.portBackend + '/api/emailsConfirmacaoReserva', this.state.selectedData)
          // .then((r) => r.json())
          .then((r) => {

            swal('', r.data.message, "success")
          }
          )
      })
    } else
      swal(
        "Solicitação de Confirmação",
        "Nenhum registro selecionado!",
        "error"
      );


  }


  toggleSelectAll(e) {
    if (!e.target.checked) {
      this.setState({
        selectedData: [],
      })
    } else {
      let arrayOfPks = this.state.data.map((item) => { return { codigo: item.pk_res } })
      this.setState({
        selectedData: arrayOfPks,
      })
    }
  }

  toggleSelectRow(e, codigo, valor) {
    let { selectedData } = this.state
    if (!e.target.checked) {
      const index = selectedData.map((item) => item.codigo).indexOf(codigo);
      if (index > -1) {
        selectedData.splice(index, 1);
      }
      this.setState({
        ...selectedData
      })
    } else {
      selectedData.push({ codigo })
      this.setState({
        ...selectedData
      })
    }
  }

  selectFile(e) {
    e.preventDefault();
    $('#html_btn').click();
  }

  async handleSelectedFile(e) {
    $('#arquivo').val(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append('arquivo', file, file.name);
    await axios.post(
      config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivoReserva?pk_res=' + this.state.codigo,
      data
    );
    this.setState({
      arquivo: { name: this.state.codigo + '_' + file.name }
    })

  }

  async showFile(e) {

    let a = document.createElement('a');
    a.target = '_blank';
    a.href = config.protocol +
      '://' +
      config.server +
      ':' +
      config.portBackend +
      '/static/reservas/' + this.state.arquivo.name

    a.click();


  }



  populaTpe(item, id) {
    if (item.type === "tipoEvento") {
      return (
        <option value={item.value} key={id}>
          {item.display}
        </option>
      );
    }
  }

  populaLocal(item, id) {
    if (item.type === "local") {
      return (
        <option value={item.value} key={id}>
          {item.display}
        </option>
      );
    }
  }

  populaSir(item, id) {
    if (item.type === 'statusEvento') {
      return (
        <option value={item.value} key={id}>
          {item.display}
        </option>
      );
    }
  }

  populaTax(item, id) {
    if (item.type === 'taxas') {
      return (
        <option value={item.value} key={id}>
          {item.display}
        </option>
      );
    }
  }

  populaAss(item, id) {
    if (item.type === 'associado') {
      return (
        <option value={item.value} key={id}>
          {item.display}
        </option>
      );
    }
  }


  async requerimento(e, pk) {
    e.preventDefault();
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = config.protocol +
      '://' +
      config.server +
      ':' +
      config.portBackend +
      '/api/requerimento?pk_res=' +
      pk
    a.click();

  }


  closeModal() {
    swal({
      dangerMode: true,
      title: "Atenção!",
      text: "Ao cancelar, todas as alterações serão descartadas. Confirma?",
      buttons: ["Sim", "Não"],
    }).then((result) => {
      if (!result) this.setState({ modal: { show: false } });
    });
  }

  closeModalArquivo() {
    this.setState({ modal: { arquivo: false } });

  }

  async showModal(e, codigo) {
    e.preventDefault();
    let edicao = false;
    let pk = "0";
    if (Number(codigo) > 0) {
      edicao = true;
      pk = codigo;
      this.getDataTax(e, codigo);

      await fetch(
        config.protocol +
        "://" +
        config.server +
        ":" +
        config.portBackend +
        "/api/getReservas?pk=" +
        Number(codigo).toString()
      )
        .then((r) => r.json())
        .then(async (r) => {
          if (typeof r[0] === "undefined") {
            window.location.href = "/reservas";
          } else {
            let form = document.getElementById("registroReservas");
            console.log(r[0]);
            r[0].auxdata = dateSql(r[0].data);
            await populateForm(form, r[0]);
          }
        });
    } else {
      edicao = false;
      document.getElementById("registroReservas").reset();
    }
    this.setState({ modal: { show: true }, edit: edicao, codigo: pk });
  }

  async showModalArquivo(e, codigo) {
    e.preventDefault();
    let edicao = false;
    let pk = "0";
    if (Number(codigo) > 0) {
      pk = codigo;

      await fetch(
        config.protocol +
        "://" +
        config.server +
        ":" +
        config.portBackend +
        "/api/getReservas?pk=" +
        Number(codigo).toString()
      )
        .then((r) => r.json())
        .then(async (r) => {
          if (typeof r[0] === "undefined") {
            window.location.href = "/reservas";
          } else {
            let form = document.getElementById("registroArquivo");
            this.setState({
              arquivo: { name: r[0].arquivo }
            })
            await populateForm(form, r[0]);
          }
        });
    } else {
      document.getElementById("registroArquivo").reset();
    }
    this.setState({ modal: { arquivo: true }, codigo: pk });
  }

  modalState() {
    if (this.state.modal.show === true) {
      return "ModalShow";
    } else {
      return "ModalHide";
    }
  }

  modalStateArquivo() {
    if (this.state.modal.arquivo === true) {
      return "ModalShow";
    } else {
      return "ModalHide";
    }
  }

  handleSave(e) {
    e.preventDefault();
    swal({
      title: "Atenção!",
      text: this.state.edit
        ? "As alterações no registro serão salvas. Confirma?"
        : "O registro será incluído. Confirma?",
      buttons: ["Sim", "Não"],
    }).then((result) => {
      if (!result) this.submitData(e);
    });
  }

  async insertTax(e) {
    e.preventDefault();
    const form = document.getElementById("registroReservas");
    const data = new FormData(form);
    let fk_tax = "";
    let valor = "";
    let nometax = "";
    let qtd = "";

    for (let name of data.keys()) {
      const input = form.elements[name];

      const parserName = input.dataset.parse;
      if (parserName) {
        const parser = inputParsers[parserName];
        const parsedValue = parser(data.get(name));

        if (name == "fk_tax") {
          fk_tax = parsedValue;

          await fetch(
            config.protocol +
            "://" +
            config.server +
            ":" +
            config.portBackend +
            "/api/getTaxas?pk=" +
            fk_tax
          )
            .then((r) => r.json())
            .then(async (r) => {
              nometax = r[0].descricao;


            });
        }
        if (name == "valor") valor = parsedValue;

        if (name == "qtd") qtd = parsedValue;

        data.set(name, parsedValue);
      }
    }

    let tax = this.state.dataTax || [];

    tax.push({
      nometax: nometax,
      fk_tax: fk_tax,
      valor: valor,
      qtd: qtd,
    });

    console.log(tax)

    this.setState({ dataTax: tax });

    let total = 0;
    await asyncForEach(tax, async (item) => {

      total = total + (parseFloat(item.valor) * parseFloat(item.qtd))

      console.log(total)
    });
    this.setState({ total: total });


  }

  async limpaTax(e) {
    e.preventDefault();
    this.setState({ dataTax: [], total: 0 });
  }

  submitData(e) {
    e.preventDefault();
    //Pega valores do form
    const form = document.getElementById("registroReservas");
    const data = new FormData(form);

    //Trata valores conforme data-parse dos inputs
    for (let name of data.keys()) {
      const input = form.elements[name];

      // Rejeita type = undefined (radio inputs)
      if (typeof input.type !== "undefined") {
        const parserName = input.dataset.parse;
        if (parserName) {
          const parser = inputParsers[parserName];
          const parsedValue = parser(data.get(name));
          data.set(name, parsedValue);
        }
      }
    }

    //Converte FormData em JSON
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    object.dataTax = this.state.dataTax;
    var json = JSON.stringify(object);

    //Post no server
    if (this.state.edit) {
      //Editar
      console.log(json);
      fetch(
        config.protocol +
        "://" +
        config.server +
        ":" +
        config.portBackend +
        "/api/editReservas?pk=" +
        this.state.codigo,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: json,
        }
      )
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          if (r.message === "Success!") {
            swal(
              "Alteração realizada",
              "Registro alterado com sucesso!",
              "success"
            ).then((result) => {
              this.setState({ modal: { show: false } });
              var event = new Event("build");
              this.getData(event);
            });
          } else {
            swal(
              "Alteração não realizada",
              "Registro não foi alterado. Verifique os campos.",
              "error"
            );
          }
        });
    } else {
      //Inserir
      fetch(
        config.protocol +
        "://" +
        config.server +
        ":" +
        config.portBackend +
        "/api/novoReservas",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: json,
        }
      )
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          if (r.message === "Success!") {
            swal(
              "Inclusão realizada",
              "Registro incluído com sucesso!",
              "success"
            ).then((result) => {
              this.setState({ modal: { show: false } });
              var event = new Event("build");
              this.getData(event);
            });
          } else {
            swal(
              "Inclusão não realizada",
              "Registro não foi incluído. Verifique os campos.",
              "error"
            );
          }
        });
    }
  }

  async componentDidMount() {
    //Carregar Parâmetros de pesquisa

    await fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getTipoEvento"
    )
      .then((r) => r.json())
      .then(async (r) => {
        let comboTpe = this.state.combos;
        await asyncForEach(r, async (item) => {
          let tipoEvento = {
            type: "tipoEvento",
            display: item.descricao,
            value: item.pk_tpe,
          };
          await comboTpe.push(tipoEvento);
        });
      });
    ordenar("comboTpe");

    await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSituacao_Reserva')
      .then((r) => r.json())
      .then(async (r) => {
        let comboSir = this.state.combos;
        await asyncForEach(r, async (item) => {
          let statusEvento = {
            type: 'statusEvento',
            display: item.descricao,
            value: item.pk_sir
          };
          await comboSir.push(statusEvento);
        });
      });
    ordenar('comboSir')

    await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getTaxas')
      .then((r) => r.json())
      .then(async (r) => {
        let comboTax = this.state.combos;
        await asyncForEach(r, async (item) => {
          let taxas = {
            type: 'taxas',
            display: item.descricao,
            value: item.pk_tax
          };
          await comboTax.push(taxas);
        });
      });
    ordenar('comboTax')

    await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
      .then((r) => r.json())
      .then(async (r) => {
        let comboAss = this.state.combos;
        await asyncForEach(r, async (item) => {
          let associado = {
            type: 'associado',
            display: item.nome,
            value: item.pk_ass
          };
          await comboAss.push(associado);
        });
      });
    ordenar('comboAss')

    await fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getLocal"
    )
      .then((r) => r.json())
      .then(async (r) => {
        let comboLocal = this.state.combos;
        await asyncForEach(r, async (item) => {
          let local = {
            type: "local",
            display: item.descricao,
            value: item.pk_loc,
          };
          await comboLocal.push(local);
        });
      });
    ordenar("comboLocal");

    let query = {};
    query.filtered = false;
    getParameterByName("filtered");
    query.descricao = getParameterByName("descricao");

    this.setState({
      filter: query,
    });

    //Caso filtered=true, traz form já processado
    var event = new Event("build");
    if (query.filtered) this.getData(event);
  }

  handleDelete(e, pk) {
    e.preventDefault();
    fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getDiasReserva?pk=" + pk,
    )
      .then((r) => r.json())
      .then((r) => {

        let msgMulta = ""

        if (r[0].multa == 0)
          msgMulta = " Não será aplicada nenhuma multa!"
        else
          msgMulta = "Será aplicada multa de " + r[0].multa + "% do valor da locação."


        swal({
          dangerMode: true,
          title: "Atenção!",
          text: "O registro selecionado será cancelado! " + msgMulta + " Confirma?",
          buttons: ["Sim", "Não"],
        }).then((result) => {
          if (!result) {
            //Delete
            fetch(
              config.protocol +
              "://" +
              config.server +
              ":" +
              config.portBackend +
              "/api/cancelaReservas?pk=" +
              pk + '&multa=' + r[0].multa,
              {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({}),
              }
            )
              .then((r) => r.json())
              .then((r) => {
                console.log(r);
                if (r.message === "Success!") {
                  swal(
                    "Cancelamento realizado",
                    "Registro cancelado com sucesso!",
                    "success"
                  ).then((result) => {
                    //Caso filtered=true, traz form já processado
                    var event = new Event("build");
                    this.getData(event);
                  });
                } else {
                  swal(
                    "Cancelamento não realizado",
                    "Registro não foi cancelado.",
                    "error"
                  );
                }
              });
          }
        });


      })


  }

  handleChange(e) {
    //Popula inputs do filtro
    e.preventDefault();
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;
    let reg = this.state.filter;
    reg[name] = value;
    this.setState({
      filter: reg,
    });
  }

  handleClean(e) {
    //Limpa o filtro
    e.preventDefault();
    console.log("limpa");
    window.history.replaceState(
      { filtered: false },
      "filter",
      "/apergs/reservas"
    ); //Apaga QueryURL
    this.setState({ filter: [] });
  }

  selectFile(e) {
    e.preventDefault();
    $("#html_btn").click();
  }

  async filterData(data) {
    //Filtra os dados
    return new Promise(async (resolve) => {
      let filter = this.state.filter;

      let queryString = "?";

      if (filter.fk_loc) {
        if (queryString === "?") {
          queryString = queryString + "fk_loc=" + filter.fk_loc;
        } else queryString = queryString + "&fk_loc=" + filter.fk_loc;
      }

      if (filter.fk_ass) {
        if (queryString === "?") {
          queryString = queryString + "fk_ass=" + filter.fk_ass;
        } else queryString = queryString + "&fk_ass=" + filter.fk_ass;
      }

      if (filter.fk_tpe) {
        if (queryString === "?") {
          queryString = queryString + "fk_tpe=" + filter.fk_tpe;
        } else queryString = queryString + "&fk_tpe=" + filter.fk_tpe;
      }


      if (filter.fk_sir) {
        if (queryString === "?") {
          queryString = queryString + "fk_sir=" + filter.fk_sir;
        } else queryString = queryString + "&fk_sir=" + filter.fk_sir;
      }

      if (filter.fk_sev) {
        if (queryString === "?") {
          queryString = queryString + "fk_sev=" + filter.fk_sev;
        } else queryString = queryString + "&fk_sev=" + filter.fk_sev;
      }
      if (filter.data_ini) {
        if (queryString === "?") {
          queryString = queryString + "data_ini=" + filter.data_ini;
        } else queryString = queryString + "&data_ini=" + filter.data_ini;
      }

      if (filter.data_fim) {
        if (queryString === "?") {
          queryString = queryString + "data_fim=" + filter.data_fim;
        } else queryString = queryString + "&data_fim=" + filter.data_fim;
      }
      //Monta Query URL
      if (queryString !== "?") {
        window.history.replaceState(
          { filtered: true },
          "filter",
          "/apergs/reservas" + queryString + "&filtered=true"
        );
      } else {
        window.history.replaceState(
          { filtered: true },
          "filter",
          "/apergs/reservas?filtered=true"
        );
      }

      // resolve(filtered);
      resolve(queryString);
    });
  }

  async getData(e) {
    e.preventDefault();
    let queryString = await this.filterData();
    await fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getReservas" +
      queryString
    )
      .then((r) => r.json())
      .then(async (r) => {
        // console.log(r);
        // let items = await this.modelingData(r);

        this.setState({ data: r });
      });
  }


  async modelingData(data) {
    //Trata os campos
    return new Promise(async (resolve) => {
      await asyncForEach(data, async (item, index) => {

        item.data = garanteDate(item.data);
        if (data.length === index + 1) {
          resolve(data);
        }
      });

    });
  }

  async getDataTax(e, codigo) {
    e.preventDefault();

    await fetch(
      config.protocol +
      "://" +
      config.server +
      ":" +
      config.portBackend +
      "/api/getReservasTaxas?pk=" +
      codigo
    )
      .then((r) => r.json())
      .then(async (r) => {
        if (r.data)
          this.setState({ dataTax: r, total: r[0].total.replace(".", "").replace(",", ".") });
        // console.log(r[0])
      })
  }

  render() {
    let tipoEvento = this.state.combos.map(this.populaTpe);
    let statusEvento = this.state.combos.map(this.populaSir);
    let local = this.state.combos.map(this.populaLocal);
    let associado = this.state.combos.map(this.populaAss);
    let taxas = this.state.combos.map(this.populaTax);

    return (
      <div className="boxSite colorSettings">
        {/***************** Barra de Navegação *******************/}
        <div className="boxNavBar">
          <NavBar selected="GrauParentesco" />
        </div>
        {/***************** Tela do WebSite *******************/}
        <div className="boxTela">
          {/*********************** Header ***********************/}
          <div className="boxHeader">
            <h3 className="headerCadastro">
              Cadastro de Reservas - Sede Social
            </h3>
          </div>
          {/*********************** Filtros ***********************/}
          <form className="boxFiltros">
            {/* Parâmetros de pesquisa:<br/> */}
            <div className="filtros">
              <div className="column-filter">
                <div className="itemFiltro">
                  <label className="labelFiltro">Local</label>
                  <select
                    name="fk_loc"
                    id="comboLocal"
                    className="selectFiltro"
                    value={this.state.filter.fk_loc || ""}
                    onChange={this.handleChange}
                    style={{ width: "200px" }}
                  >
                    {local}
                    <option value="">TODOS</option>
                  </select>
                </div>

                <div className="itemFiltro">
                  <label className="labelFiltro">Período</label>
                  <input
                    name="data_ini"
                    type="date"
                    id="filtroDataIni"
                    className="inputFiltro"
                    style={{ width: "10vw" }}
                    value={this.state.filter.data_ini || ""}
                    onChange={this.handleChange}
                  />

                  <input
                    name="data_fim"
                    type="date"
                    id="filtroDataFim"
                    className="inputFiltro"
                    style={{ width: "10vw" }}
                    value={this.state.filter.data_fim || ""}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="itemFiltro">
                  <label className="labelFiltro">Associado</label>
                  <select
                    name="fk_ass"
                    id="comboAss"
                    className="selectFiltro"
                    value={this.state.filter.fk_ass || ''}
                    onChange={this.handleChange}
                    style={{ width: '300px' }}
                  >
                    {associado}
                    <option value="">TODOS</option>

                  </select>
                </div>


                <div className="itemFiltro">
                  <label className="labelFiltro">Tipo de Evento</label>
                  <select
                    name="fk_tpe"
                    id="comboTpe"
                    className="selectFiltro"
                    value={this.state.filter.fk_tpe || ''}
                    onChange={this.handleChange}
                    style={{ width: '200px' }}
                  >
                    {tipoEvento}
                    <option value="">TODOS</option>

                  </select>
                </div>


                <div className="itemFiltro">
                  <label className="labelFiltro">Situação</label>
                  <select
                    name="fk_sir"
                    id="comboSir"
                    className="selectFiltro"
                    value={this.state.filter.fk_sir || ""}
                    onChange={this.handleChange}
                    style={{ width: "200px" }}
                  >
                    {statusEvento}
                    <option value="">TODOS</option>
                  </select>
                </div>
              </div>
              <br />
              <div className="column-filter-2">
                <button
                  className="buttonFiltroProcessar"
                  onClick={this.getData}
                >
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={iosSearchStrong}
                  />
                  Processar
                </button>
                <button
                  className="buttonFiltroLimpar"
                  onClick={this.handleClean}
                >
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={ic_clear}
                  />
                  Limpar
                </button>
                {/* <button className="buttonNovo" onClick={this.showModal}>
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={ic_add_circle}
                  />
                  Novo Registro
                </button> */}

                <button className="buttonFiltroLimpar" onClick={this.solicitarConfirmacao}>
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={ic_email}
                  />
                  Solicitar Confirmação
                </button>

                <button className="buttonNovo" onClick={this.showModal}>
                  <Icon
                    size={20}
                    style={{ display: "inline" }}
                    icon={coinDollar}
                  />
                  Gerar Cobrança
                </button>
              </div>
            </div>
          </form>
          {/*********************** Tabela ***********************/}
          <div style={{ paddingBottom: "30px" }}>
            <div className={this.modalState()} tabIndex="-1">
              <Modal.Dialog className="Modal">
                <div>
                  <Modal.Header className="ModalBg">
                    <div className="ModalHeader">
                      <h3 className="headerModal">{(this.state.edit) ? 'Edição de Reserva' : 'Inclusão de Reserva'}</h3>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="ModalBg">
                    <div className="ModalBody">
                      <form
                        id="registroReservas"
                        name="registroReservas"
                        onSubmit={this.submitData}
                      >
                        <div>
                          <label className="labelModal">Associado</label>
                          <input
                            type="text"
                            id="nomeass"
                            name="nomeass"
                            className="form-control"
                            disabled
                          />
                        {/* {auth.user === 1 ? */}
                          <>
                            <label className="labelModal">Evento</label>
                            <input
                              type="text"
                              id="titulo"
                              name="titulo"
                              className="form-control"
                              disabled
                            />
                          </> 
                        {/**/}
                        </div>

                        <div class="row">
                          <div className="col-sm-2" style={{ width: "200px" }}>
                            <label className="labelModal">Data</label>
                            <input
                              type="date"
                              id="auxdata"
                              name="auxdata"
                              className="form-control"
                            />

                          </div>


                          <div className="col-sm-2" style={{ width: "250px" }}>
                            <label className="labelModal">Local</label>
                            <select
                              data-parse="uppercase"
                              id="fk_loc"
                              name="fk_loc"
                              className="form-control"
                              defaultValue=""
                              disabled={this.state.edit}
                            >
                              {local}
                              <option value="">Selecione</option>
                            </select>
                          </div>
                        </div>

                        <div class="row">
                          <div className="col-sm-2" style={{ width: "200px" }}>

                            <label className="labelModal">Tipo de Evento</label>
                            <select
                              data-parse="uppercase"
                              id="fk_tpe"
                              name="fk_tpe"
                              className="form-control"
                              defaultValue=""
                              disabled={this.state.edit}
                            >
                              {tipoEvento}
                              <option value="">Selecione</option>
                            </select>
                          </div>

                          <div className="col-sm-2" style={{ width: "200px" }}>

                            <label className="labelModal">Situação do Evento</label>
                            <select
                              data-parse="uppercase"
                              id="fk_sir"
                              name="fk_sir"
                              className="form-control"
                              defaultValue=""
                            >
                              {statusEvento}
                              <option value="">Selecione</option>
                            </select>
                          </div>
                        </div>

                        <div>
                          <br />
                          <strong>Taxas do Evento</strong>
                          <hr width="100%" size="50" color=" #528b46" />

                          <div className="row">
                            <div
                              className="col-xs-1"
                              style={{ width: "180px" }}
                            >
                              <label className="labelModal">
                                Taxas
                              </label>
                              <select
                                name="fk_tax"
                                id="comboTax"
                                className="form-control"
                                data-parse="uppercase"
                                required="required"
                                defaultValue=""
                              >
                                {taxas}
                                <option value="">Selecione</option>
                              </select>
                            </div>

                            <div
                              className="col-xs-1"
                              style={{ width: "100px" }}
                            >
                              <label className="labelModal">Qtd</label>

                              <input
                                type="text"
                                id="qtd"
                                name="qtd"
                                className="form-control"
                                data-parse="uppercase"
                              />
                            </div>


                            <div
                              className="col-xs-1"
                              style={{ width: "100px" }}
                            >
                              <label className="labelModal">Valor</label>

                              <input
                                type="text"
                                id="valor"
                                name="valor"
                                className="form-control"
                                data-parse="uppercase"
                                onBlur={(e) => {
                                  onBlurCurrency(e);
                                }}
                              />
                            </div>


                            <div
                              className="col-xs-1"
                              style={{ width: "40px", paddingTop: "20px" }}
                            >
                              <button
                                className="ModalButton"
                                onClick={this.insertTax}
                                style={{ width: "100px" }}
                              >
                                Adicionar
                              </button>
                              <div style={{ paddingTop: "10px" }}>
                                <button
                                  className="ModalButton"
                                  onClick={this.limpaTax}
                                  style={{ width: "100px" }}
                                >
                                  Limpar Registros
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              marginLeft: "0px",
                              marginTop: "10px",
                              marginRight: "0px",
                            }}
                          >
                            <div className="divTabela">
                              <ReactTable
                                showPagination={true}
                                data={this.state.dataTax}
                                previousText="Anterior"
                                nextText="Próximo"
                                loadingText="Carregando..."
                                pageText="Página"
                                ofText="de"
                                rowsText="registros"
                                noDataText="Nenhum item encontrado"
                                columns={[
                                  {
                                    Header: "Taxa",
                                    accessor: "nometax",
                                    minWidth: 100,
                                  },

                                  {
                                    Header: "Qtd",
                                    accessor: "qtd",
                                    minWidth: 80,
                                  },

                                  {
                                    Header: "Valor",
                                    accessor: "valor",
                                    minWidth: 80,
                                  },

                                ]}
                                defaultPageSize={5}
                              />
                            </div>

                            <div
                              style={{ backgroundColor: '#e1e6e6', color: '#004136' }}>

                              TOTAL: {Number(this.state.total).toFixed(2).replace(".", ",")}

                            </div>

                          </div>
                        </div>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="ModalBg">
                    <div className="ModalFooter">
                      <button className="ModalButton" onClick={this.handleSave}>
                        Salvar
                      </button>
                      <button className="ModalButton" onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </div>
                  </Modal.Footer>
                </div>
              </Modal.Dialog>
            </div>


            <div className={this.modalStateArquivo()} tabIndex="-1">
              <Modal.Dialog className="Modal">
                <div>
                  <Modal.Body className="ModalBg">
                    <div className="ModalBody">
                      <label className="labelModal">Lista de Convidados</label>

                      <input

                        type="file"
                        name="arquivo"
                        id="html_btn"
                        accept=".pdf"
                        onChange={this.handleSelectedFile}
                      />

                      {this.state.arquivo.name || 'Nenhum arquivo selecionado'}

                      <button
                        className="ModalButton"
                        type="file"
                        onClick={this.selectFile}
                        style={{ width: '110px' }}
                      >
                        Selecionar Arquivo
                      </button>

                      <button
                        className="ModalButton"
                        // type="file"
                        onClick={this.showFile}
                        style={{ width: '110px' }}
                        hidden={this.state.arquivo.name == ''}
                      >
                        Abrir Arquivo
                      </button>

                    </div>
                  </Modal.Body>

                  <Modal.Footer className="ModalBg">
                    <div className="ModalFooter">
                      <button className="ModalButton" onClick={this.closeModalArquivo}>
                        Sair
                      </button>
                    </div>
                  </Modal.Footer>

                </div>
              </Modal.Dialog>
            </div>

            <div
              style={{
                marginLeft: "30px",
                marginTop: "30px",
                marginRight: "30px",
              }}
            >
              <div className="divTabela">
                <ReactTable
                  data={this.state.data}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando..."
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  noDataText="Nenhum registro encontrado"
                  columns={[
                    {
                      Header: "",
                      width: 70,
                      Cell: (row) => {
                        return (
                          <div className="">
                            <Icon
                              size={20}
                              icon={edit}
                              className="iconButton"
                              onClick={(e) => {
                                this.showModal(e, row.row.pk_res);
                              }}
                            />

                            <Icon size={20} icon={ic_do_not_disturb_alt} className="iconButtonRed"

                              onClick={(e) => {
                                this.handleDelete(e, row.row.pk_res);
                              }}
                            />
                          </div>
                        );
                      },
                    },

                    {
                      id: 'selection',
                      Header: (header) => {
                        header.column.sortable = false
                        return (
                          <div>
                            <input type="checkbox" onChange={this.toggleSelectAll} checked={this.state.selectedData.length === this.state.data.length} />
                          </div>
                        )
                      },
                      Cell: ({ row }) => (
                        <div>
                          <input type="checkbox" onChange={(e) => this.toggleSelectRow(e, row.pk_res)} checked={this.state.selectedData.map((item) => item.codigo).indexOf(row.pk_res) > -1} />
                        </div>
                      ),
                      width: 47,
                    },

                    {
                      Header: "Código",
                      accessor: "pk_res",
                      minWidth: 100,
                      show: false,

                    },

                    {
                      Header: "Data",
                      accessor: "auxdata",
                      minWidth: 50,
                      sortMethod: (a, b) => {
                        if (a === b) {
                          return 0;
                        }
                        const aData = a.substr(6, 4) + a.substr(3, 2) + a.substr(0, 2);
                        const bData = b.substr(6, 4) + b.substr(3, 2) + b.substr(0, 2);
                        return aData > bData ? 1 : -1;
                      }
                    },

                    {
                      Header: "Dia Semana",
                      accessor: "diaSemana",
                      minWidth: 80,
                    },

                    {
                      Header: "Local",
                      accessor: "nomeloc",
                      minWidth: 80,
                    },

                    {
                      Header: "Associado",
                      accessor: "nomeass",
                      minWidth: 200,
                    },

                    {
                      Header: "Situação",
                      accessor: "nomesir",
                      minWidth: 80,

                    },

                    {
                      Header: "Exclusiva",
                      accessor: "exclusiva",
                      minWidth: 30,

                    },

                    {

                      Cell: (row) => {
                        return (
                          <div >
                            <button className="buttonGrid"
                              onClick={(e) => {
                                this.requerimento(e, row.row.pk_res);
                              }}
                            >
                              Requerimento
                            </button>

                            <button className="buttonGrid"
                              onClick={(e) => {
                                this.showModalArquivo(e, row.row.pk_res);

                              }}
                            >
                              Convidados
                            </button>
                          </div>
                        )
                      }
                    }

                  ]}
                  defaultSorted={[
                    {
                      id: "data",
                      desc: false,
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Reservas;
