import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown, NavItem, MenuItem } from 'react-bootstrap';
import '../css/Default.css';
import { Icon } from 'react-icons-kit';
import { ic_notifications_active } from 'react-icons-kit/md/ic_notifications_active'
import swal from '@sweetalert/with-react';
import ReactLoading from 'react-loading';
import  version  from '../../package.json';
import apergs from '../imgs/icontransparent.png'
import config from '../config'

const versao = version

class NavBar extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			selected: 'Nenhum',
			item: ''
		};
		this.detailItem = this.detailItem.bind(this);
		this.clearItem = this.clearItem.bind(this);
		this.items = this.items.bind(this);
	}

	items(item) {
		if (item !== '') {
			let pos = (item - 1) * 150 + 50;
			return (
				<div className="themeDetailNavBar" style={{ position: 'absolute', left: pos + 'px' }}>
					<a href="#test" className="secondItemNavBar">
						Gerais
					</a>
					<br />
					<a href="#test" className="secondItemNavBar">
						Associação
					</a>
					<br />
					<a href="#test" className="secondItemNavBar">
						Financeiro
					</a>
					<br />
				</div>
			);
		}
	}

	componentDidMount() {
		// let authApergs = sessionStorage.getItem('authApergs');
		//Criar controle de acesso, utilizando o usuario logado na sessão
	}

	detailItem(e) {
		e.preventDefault();
		this.setState({
			item: e.target.id
		});
	}

	clearItem(e) {
		e.preventDefault();
		this.setState({
			item: ''
		});
	}

	logout(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao sair, todas as alterações em andamento serão descartadas. Você tem certeza que deseja sair?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				let strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })
        		let auth = JSON.parse(strAuth)
				var object = {};
				object.user = auth.user;
				var json = JSON.stringify(object);
				fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/logout', {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				})
				sessionStorage['authApergs'] = JSON.stringify({ authenticated: false, user: 0 });
				window.location.reload();
			}
		});
	}

	async update(e) {
		e.preventDefault();
		localStorage.removeItem("ApergsNeedUpdate")
		swal({
			title: 'Atualizando Sistema',
			// text: '',
			// icon: "info",
			closeOnClickOutside: false,
			buttons: false,
			// showLoaderOnConfirm: true,
			content: (
				<div style={{ width: '100%' }}>
					<div style={{ display: 'table', margin: '0 auto' }}>
						<ReactLoading
							type={'bubbles'}
							color={'#004136'}
							height={100}
							width={100}
						/>
					</div>
				</div>
			)
		});
		await setTimeout(() => { window.location.reload(true) }, 3000)
	}

	render() {
		// let detailed = this.items(this.state.item)
		// return (
		//     <div className="themeNavBar" onMouseLeave={this.clearItem}>
		//         <a id='2' className="headItemNavBar" href='#test'>Apergs Web</a>
		//         <a id='2' className="firstItemNavBar" href='#test' onMouseOver={this.detailItem}>Cadastros</a>
		//         <a id='3' className="firstItemNavBar" href='#test' onMouseOver={this.detailItem}>Lançamentos</a>
		//         <a id='4' className="firstItemNavBar" href='#test' onMouseOver={this.detailItem}>Funções Especiais</a>
		//         {detailed}
		//         <a id='exit' className="logOutNavBar" href='#test'>Sair</a>
		//     </div>
		// );
		let needUpdate = Boolean(localStorage.getItem('ApergsNeedUpdate') || false);
		return (
			<div>
				<style type="text/css">
					{`
                    .navbar {
                        background-color: var(--navbar-background);
                        z-index: 888
                    }
                    `}
				</style>
				<Navbar inverse collapseOnSelect className="h-20">
					<Navbar.Header>
						<Navbar.Brand>
							<a href="/apergs/home" style={{ display: 'flex' }}>
								<img
									alt="APERGS"
									src={apergs}
									className="iconApergs"
									title="APERGS"
								/>
								APERGS
							</a>
						</Navbar.Brand>
						<Navbar.Toggle />
					</Navbar.Header>
					<Navbar.Collapse>
						<Nav>
							<NavDropdown eventKey={1} title="Cadastros" id="basic-nav-dropdown">
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Gerais
								</p>
								<MenuItem eventKey={1.1} href="/apergs/paises">
									Países
								</MenuItem>
								<MenuItem eventKey={1.2} href="/apergs/estados">
									Estados
								</MenuItem>
								<MenuItem eventKey={1.3} href="/apergs/cidades">
									Cidades
								</MenuItem>
								<MenuItem eventKey={1.4} href="/apergs/parametros">
									Parâmetros
								</MenuItem>
								<MenuItem eventKey={1.5} href="/apergs/classes">
									Classes de Despesas
								</MenuItem>
								<MenuItem eventKey={1.6} href="/apergs/subclasses">
									Subclasses de Despesas
								</MenuItem>
								<MenuItem eventKey={1.7} href="/apergs/fornecedores">
									Fornecedores
								</MenuItem>
								<MenuItem divider />
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Associação
								</p>
								<MenuItem eventKey={1.1} href="/apergs/associados">
									Associados
								</MenuItem>
								<MenuItem eventKey={1.1} href="/apergs/normativas">
									Normativas
								</MenuItem>
								<MenuItem eventKey={1.2} href="/apergs/situacoes">
									Situações
								</MenuItem>
								<MenuItem eventKey={1.3} href="/apergs/lotacoes">
									Lotações
								</MenuItem>
								<MenuItem eventKey={1.4} href="/apergs/categorias-associados">
									Categorias de Associados
								</MenuItem>
								<MenuItem eventKey={1.5} href="/apergs/grau-parentesco">
									Grau de Parentesco
								</MenuItem>
								<MenuItem eventKey={1.6} href="/apergs/advogados">
									Advogados
								</MenuItem>
								<MenuItem eventKey={1.7} href="/apergs/tipos-acoes">
									Tipos de Ações
								</MenuItem>
								<MenuItem eventKey={1.7} href="/apergs/informativos">
									Informativos da Associação
								</MenuItem>
								<MenuItem eventKey={1.7} href="/apergs/arquivos">
									Disponibilização de Arquivos para Associados
								</MenuItem>

								<br />
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Eventos
								</p>

								<MenuItem eventKey={1.8} href="/apergs/status_eventos">
									Status de Eventos
								</MenuItem>
								<MenuItem eventKey={1.8} href="/apergs/tipos_itens_eventos">
									Tipos de Itens de Eventos
								</MenuItem>

								<MenuItem eventKey={1.9} href="/apergs/tipo_evento">
									Tipos de Eventos
								</MenuItem>

								<MenuItem eventKey={1.9} href="/apergs/eventos">
									Eventos
								</MenuItem>

								<br />
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Reservas Sede Social
								</p>

								<MenuItem eventKey={1.9} href="/apergs/locais">
									Locais
								</MenuItem>

								<MenuItem eventKey={1.9} href="/apergs/tipo_locacao">
									Tipos de Locação
								</MenuItem>

								<MenuItem eventKey={1.9} href="/apergs/materiais">
									Materiais / Equipamentos
								</MenuItem>

								<MenuItem eventKey={1.9} href="/apergs/situacao_reserva">
									Situações de Reservas
								</MenuItem>

								<MenuItem eventKey={1.8} href="/apergs/taxas">
									Taxas de Reservas
								</MenuItem>

								<MenuItem eventKey={1.9} href="/apergs/reservas">
									Reservas - Sede Social
								</MenuItem>

								<MenuItem eventKey={1.9} href="/apergs/lista_espera">
									Lista Espera
								</MenuItem>

								<MenuItem divider />
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Financeiro
								</p>
								<MenuItem eventKey={1.1} href="/apergs/bancos">
									Bancos
								</MenuItem>
								<MenuItem eventKey={1.2} href="/apergs/tipos-servicos">
									Tipos de serviço
								</MenuItem>
							</NavDropdown>
							<NavDropdown eventKey={2} title="Lançamentos" id="basic-nav-dropdown">
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d',
									}}
								>
									Mensalidades
								</p>
								<MenuItem eventKey={2.1} href="/apergs/gerar-mensalidades">
									Gerar Mensalidades
								</MenuItem>
								{/* <div style={{ borderBottom: '1px solid #E5E5E5', marginLeft: '20px',  marginRight: '20px', color: 'transparent'}}></div> */}
								<p
									style={{
										// fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d',
										marginBottom: '2px',
									}}
								>
									Importação Arquivos
								</p>
								<MenuItem eventKey={2.2} href="/apergs/unimed">
									Unimed
								</MenuItem>
								{/* <div style={{ borderBottom: '1px solid #E5E5E5', marginLeft: '20px',  marginRight: '20px', color: 'transparent'}}></div> */}
								<MenuItem eventKey={2.3} href="/apergs/telefonia">
									Telefonia Claro/Vivo
								</MenuItem>
								<MenuItem eventKey={2.4} href="/apergs/criticas-tesouro">
									Criticas Tesouro
								</MenuItem>
								<MenuItem eventKey={2.4} href="/apergs/retornos-banrisul">
									Retorno Banrisul
								</MenuItem>
								<p
									style={{
										// fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d',
										marginBottom: '2px',
									}}
								>
									Exportação Arquivos
								</p>
								<MenuItem eventKey={2.4} href="/apergs/remessas-tesouro">
									Remessa Tesouro
								</MenuItem>
								<MenuItem eventKey={2.4} href="/apergs/remessas-banrisul">
									Remessa Banrisul
								</MenuItem>
								{/* <div style={{ borderBottom: '1px solid #E5E5E5', marginLeft: '20px',  marginRight: '20px', color: 'transparent'}}></div>
                                    <MenuItem eventKey={2.4} href="/">Gerar Arquivo Tesouro</MenuItem>
                                    <MenuItem eventKey={2.5} href="/">Ler Arquivo Tesouro</MenuItem>
                                    <div style={{ borderBottom: '1px solid #E5E5E5', marginLeft: '20px',  marginRight: '20px', color: 'transparent'}}></div>
                                    <MenuItem eventKey={2.6} href="/">Gerar Arquivo Banrisul</MenuItem>
                                    <MenuItem eventKey={2.7} href="/">Ler Arquivo Banrisul</MenuItem> */}
								<MenuItem divider />
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Financeiro
								</p>
								<MenuItem eventKey={2.8} href="/apergs/contas-receber">
									Contas a receber
								</MenuItem>
								<MenuItem eventKey={2.9} href="/apergs/contas-pagar">
									Contas a pagar
								</MenuItem>
								<MenuItem eventKey={2.10} href="/apergs/demonstrativos">
									Demonstrativos
								</MenuItem>
								<MenuItem eventKey={2.10} href="/apergs/demonstrativosIR">
									Demonstrativos IR
								</MenuItem>
							</NavDropdown>
							<NavDropdown eventKey={3} title="Relatórios" id="basic-nav-dropdown">
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Cadastral
								</p>
								<MenuItem eventKey={3.1} href="/apergs/relatorio/acoes-andamento">
									Processos/Ações em Andamento
								</MenuItem>
								<MenuItem divider />
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Financeiro
								</p>
								<MenuItem eventKey={3.2} href="/apergs/relatorio/cobranca-servicos">
									Cobrança por Tipo de Serviço
								</MenuItem>
								<MenuItem eventKey={3.3} href="/apergs/relatorio/debitos/tesouro">
									Débitos - Tesouro
								</MenuItem>
								<MenuItem eventKey={3.4} href="/apergs/relatorio/debitos/banrisul">
									Débitos - Banrisul
								</MenuItem>
								<MenuItem eventKey={3.4} href="/apergs/relatorio/relacao-contas-aberto">
									Relação de Contas em Aberto
								</MenuItem>

							</NavDropdown>
							<NavDropdown eventKey={4} title="Funções Especiais" id="basic-nav-dropdown">
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Sistema
								</p>
								<MenuItem eventKey={4.1} href="/apergs/usuarios">
									Usuários
								</MenuItem>
								<MenuItem eventKey={4.2} href="/apergs/importacao-db" disabled>
									Importar Banco de Dados
								</MenuItem>
								<MenuItem eventKey={4.3} href="/apergs/etiquetas">
									Etiquetas
								</MenuItem>
								<MenuItem eventKey={4.4} href='/apergs/tipos-acoes?importar=true'>
									Importar Tipos de Ação
								</MenuItem>
							</NavDropdown>
						</Nav>
						<Nav pullRight>
							<NavItem onClick={this.update}>
								{'Versão'+ (versao.version)}
								{needUpdate ? (<Icon size={15} icon={ic_notifications_active} className="newVersion" />) : (<div></div>)}
							</NavItem>
							<NavItem eventKey={1} onClick={this.logout}>
								Sair
							</NavItem>
						</Nav>

					</Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}

export default NavBar;
