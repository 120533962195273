// local
// export const config = {
// 	protocol: 'http',
// 	server: 'localhost',
// 	port: 3000,
// 	portBackend: 21000,
// 	frontend: 'http://localhost.com:3000'
// }

// base teste Delphus
// export const config = {
// 	// protocol: 'http',//vps delphus e apergs servidor
// 	protocol: 'https',//vps delphus e apergs servidor
// 	server: 'apps.delphus.inf.br',//base vps delphus
// // 	// server: 'localhost',
// 	// api: 'http://localhost.com:21000',//base vps delphus
// 	port: 3000,
// 	frontend: 'http://localhost.com:3000',
// 	api: 'https://apps.delphus.inf.br:21005',//base vps delphus
// 	portBackend: 21005,//vps delphus
// 	// portBackend: 21000,

// };

// base teste Delphus ---->> SERVIDOR DE TESTE -- NOVO
// export const config = {
// 	protocol: 'https',//servidor delphus e apergs servidor
// 	server: 'apps.delphus.inf.br',//base servidor delphus
// 	port: 3000,
// 	frontend: 'http://localhost.com:3000',
// 	api: 'http://192.168.0.254:21004',//base servidor delphus
// 	portBackend: 21005,//servidor delphus

// };

// servidor apergs
export const config = {
	protocol: 'https',//vps delphus e apergs servidor
	server: window.location.hostname,//apergs servidor
	port: 3000,
	frontend: 'http://localhost.com:3000',
	// api: 'https://vps15027.publiccloud.com.br:21001',
	api: 'https://' + window.location.hostname + ':21001',//apergs servidor
	portBackend: 21001,//apergs servidor

};

export default config;
