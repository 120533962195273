import React, { Component } from 'react';
import "../css/Login.css";
import {login} from 'react-icons-kit/ikons/login'
import { Icon } from 'react-icons-kit'
import config from '../config'
import swal from '@sweetalert/with-react';
import apergs from '../imgs/ApergsPNG.png'



class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        };

    }


    login(e){
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);
        var hash = ''
        var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < 32; i++) {
            hash += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }


        // console.log(data.get('user'), data.get('password'))

        //Converte FormData em JSON
        var object = {};
        data.forEach(function(value, key){
            object[key] = value;
        });
        var json = JSON.stringify(object);

        fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/login', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: json
        }).then(r=>r.json()).then(r=>{

            object.hash = hash
            object.pk = r.user
            json = JSON.stringify(object);
            fetch(config.protocol+'://'+config.server+':'+config.portBackend+'/api/loginHash', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: json
            })
            
            // window.alert(JSON.stringify()

            
            if (r.authenticated === true) {
                sessionStorage['authApergs'] = JSON.stringify({ authenticated: true, user: r.user , hash: hash})
                localStorage.removeItem("ultimoFk_ser");
                window.location.href = '/apergs/home'
            } else {
                sessionStorage['authApergs'] = JSON.stringify({ authenticated: false, user: 0 })
                swal("Erro!", "Usuário ou senha incorretos. Tente novamente.", "error");
            }
        })
        

    }

    

    async componentDidMount() {
        let strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })
        let auth = JSON.parse(strAuth) 
        var object = {};
        var hash = 'naoLoga'
		object.user = auth.user;
		var json = JSON.stringify(object);
        await fetch(
            config.protocol +
            '://' +
            config.server +
            ':' +
            config.portBackend +
            '/api/autenticacao?pk_usu=' +
            auth.user
        )
            .then((r) => r.json())
            .then((r) => {
                if (typeof r[0] === 'undefined') {

                } else {
                    hash = r[0].hash

                }
            }
            );
            // window.alert(hash)
        if (auth.hash == hash) window.location.href = '/apergs/home'
    }

    render() {
        return (
            <div className="boxSite colorSettings">
                <img alt="Apergs" src={apergs} title="Apergs" className="logoLogin"/>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto centerLogin">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h5 className="card-title text-center headerLogin">Login</h5>
                                    <form className="form-signin" id="loginForm" onSubmit={this.login}>
                                        <div className="form-label-group">
                                            <input type="text" id="user" name="user" className="form-control" placeholder="Usuário" required autoFocus/>
                                            <label htmlFor="user">Usuário</label>
                                        </div>

                                        <div className="form-label-group">
                                            <input type="password" id="password" name="password" className="form-control" placeholder="Senha" required/>
                                            <label htmlFor="password">Senha</label>
                                        </div>

                                        <button className="buttonLogin" type="submit">
                                            <Icon size={20} icon={login}></Icon>
                                            <p className='buttonLoginText'>Entrar</p> 
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
