import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import ReactLoading from 'react-loading';
import { populateForm, delay } from '../Utils';
// import swal from '@sweetalert/with-react';
import swal from '@sweetalert/with-react';

class GerarMensalidade extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			modal: { show: false },
			edit: false,
			mensalidadesStats: { message: 'Not started', info: [] },
			filterChanged: '',
			data: []
		};

		this.handleSave = this.handleSave.bind(this);
		this.submitData = this.submitData.bind(this);
	}

	handleSave(e) {
		e.preventDefault();
		const form = document.getElementById('gerarMensalidade');
		const data = new FormData(form);
		let competencia = '';
		let competencia_ano = '';
		let competencia_mes = '';

		if (Number(data.get('competencia_mes') < 10)) {
			competencia_mes = String('0' + data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		} else {
			competencia_mes = String(data.get('competencia_mes'));
			competencia_ano = String(data.get('competencia_ano'));
			competencia = competencia_mes + competencia_ano;
			data.set('competencia', competencia);
		}

		let date = new Date();
		date.setFullYear(Number(competencia_ano), Number(competencia_mes) - 1, Number(data.get('dia')));
		if (
			date.getFullYear() === Number(competencia_ano) &&
			date.getMonth() + 1 === Number(competencia_mes) &&
			date.getDate() === Number(data.get('dia'))
		) {
			swal({
				title: 'Atenção!',
				text: 'Deseja gerar as mensalidades de ' + competencia_mes + '/20' + competencia_ano + '?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) this.submitData(e, data);
			});
		} else {
			swal('Rotina não executada', 'Rotina não foi executada. Data inválida.', 'error');
		}
	}

	async submitData(e, data) {
		let strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 });
		let auth = JSON.parse(strAuth);

		this.setState({ mensalidadesStats: { message: 'Started', info: [] } });
		fetch(
			config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/gerarMensalidades?competencia=' +
				data.get('competencia') +
				'&fk_usu=' +
				auth.user +
				'&dia=' +
				data.get('dia'),
			{
				method: 'GET',
				headers: {
					'Content-type': 'application/json'
				}
			}
		)
			.then(async (r) => r.json())
			.then(async (r) => {
				console.log(r);
				if (r.message === 'Success!') {
					while (this.state.mensalidadesStats.message !== 'Done') {
						await fetch(
							config.protocol +
								'://' +
								config.server +
								':' +
								config.portBackend +
								'/api/checkMensalidades',
							{
								method: 'GET',
								headers: {
									'Content-type': 'application/json'
								}
							}
						)
							.then(async (r) => r.json())
							.then(async (r) => {
								this.setState({ mensalidadesStats: r });
								swal({
									title: 'Gerando mensalidades',
									text: this.state.mensalidadesStats.message,
									// icon: "info",
									closeOnClickOutside: false,
									buttons: false,
									// showLoaderOnConfirm: true,
									content: (
										<div style={{ width: '100%' }}>
											<div style={{ display: 'table', margin: '0 auto' }}>
												<ReactLoading
													type={'bubbles'}
													color={'#004136'}
													height={100}
													width={100}
												/>
											</div>
										</div>
									)
								});
							});
						if (this.state.mensalidadesStats.message === 'Error') {
							break;
						}
						await delay(1000);
					}
					if (this.state.mensalidadesStats.message === 'Error') {
						swal('Geração não concluída', this.state.mensalidadesStats.info[0], 'error');
					} else {
						swal('Geração concluída', 'As informações foram geradas com sucesso!', 'success');
					}
				} else {
					swal('Geração não concluída', 'Houve um problema na geração.', 'error');
				}
			});
	}

	async componentDidMount() {
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let selectedCidade = this.state.combos.filter((item) => {
						return item.type === 'cidades' && item.value === r[0].fk_cid;
					});
					this.setState({
						selected: {
							cidade: selectedCidade[0]
						}
					});
					let form = document.getElementById('gerarMensalidade');
					r[0].competencia_mes = Number(r[0].competencia.substring(0, 2));
					r[0].competencia_ano = Number(r[0].competencia.substring(2, 4));
					console.log(r[0].competencia_mes, r[0].competencia_ano);
					r[0].valor_anape = r[0].valor_anape.toFixed(2).replace('.', ',');
					populateForm(form, r[0]);
				}
			});
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}

	populaVencto(item, id) {
		return <option value={item}>{item}</option>;
	}

	listaVencto() {
		let listaVencto = [];
		for (let i = 1; i <= 31; i++) {
			listaVencto.push(i);
		}
		return listaVencto;
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let vencto = this.listaVencto().map(this.populaVencto);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Situacoes" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Gerar Mensalidades</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros ModalBg">
							<form id="gerarMensalidade" name="gerarMensalidade" onSubmit={this.submitData}>
								<div className="col-sm-12" style={{ marginTop: '15px', marginBottom: '15px' }}>
									<label>Competência/Débito:</label>
								</div>
								<div className="col-sm-4">
									<label htmlFor="dia">Dia:</label>
									<select name="dia" className="form-control">
										<option value="">Selecione</option>
										{vencto}
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_mes">Mês:</label>
									<select name="competencia_mes" className="form-control">
										<option value="">Selecione</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>
									</select>
								</div>
								<div className="col-sm-4" style={{ marginBottom: '15px' }}>
									<label htmlFor="competencia_ano">Ano:</label>
									<select name="competencia_ano" className="form-control">
										<option value="">Selecione</option>
										{anos}
									</select>
								</div>
							</form>
						</div>
					</form>
					<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
						<div className="buttonsCadastro">
							<button
								className="buttonVoltar"
								style={{ marginTop: '10px', marginLeft: '0px' }}
								onClick={this.handleSave}
							>
								Gerar
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default GerarMensalidade;
