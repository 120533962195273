import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { garanteDate, asyncForEach, getParameterByName } from '../Utils';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { money } from 'react-icons-kit/fa/money'
import { edit } from 'react-icons-kit/ionicons/edit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { back } from 'react-icons-kit/entypo/back'
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import Autocomplete from '../components/Autocomplete';

class ContasPagar extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			filterChanged: '',
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.filterFornecedor = this.filterFornecedor.bind(this);
		this.filterClasse = this.filterClasse.bind(this);
		this.filterSubclasse = this.filterSubclasse.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getFornecedores')
			.then((r) => r.json())
			.then(async (r) => {
				let comboFornecedores = this.state.combos;
				await asyncForEach(r, async (item) => {
					let fornecedores = {
						type: 'fornecedores',
						display: item.razao_social,
						value: item.pk_for
					};
					await comboFornecedores.push(fornecedores);
				});
				this.setState({ combos: this.state.combos });
			});

		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSubclasses')
			.then((r) => r.json())
			.then(async (r) => {
				let combosSubclasses = this.state.combos;
				await asyncForEach(r, async (item) => {
					let subclasse = {
						type: 'subclasses',
						display: item.descricao,
						value: item.pk_sub
					};
					await combosSubclasses.push(subclasse);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getClasses')
			.then((r) => r.json())
			.then(async (r) => {
				let combosClasses = this.state.combos;
				await asyncForEach(r, async (item) => {
					let classes = {
						type: 'classes',
						display: item.descricao,
						value: item.pk_cla
					};
					await combosClasses.push(classes);
				});
				this.setState({ combos: this.state.combos });
			});

		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = true;
		getParameterByName('filtered');
		query.fk_for = Number(getParameterByName('fk_for'));
		query.fornecedor = this.state.combos.filter((item) => {
			return item.type === 'fornecedores' && Number(item.value) === Number(query.fk_for);
		})[0];
		query.fk_cla = Number(getParameterByName('fk_cla'));
		query.classe = this.state.combos.filter((item) => {
			return item.type === 'classes' && Number(item.value) === query.fk_cla;
		})[0];
		query.fk_sub = Number(getParameterByName('fk_sub'));
		query.subclasse = this.state.combos.filter((item) => {
			return item.type === 'subclasses' && Number(item.value) === query.fk_sub;
		})[0];
		query.data_venc_max = getParameterByName('data_venc_max');
		query.data_venc_min = getParameterByName('data_venc_min');
		query.data_emis_max = getParameterByName('data_venc_max');
		query.data_emis_min = getParameterByName('data_venc_min');

		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	async handleDelete(e, pk) {
		e.preventDefault();
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getBaixaTit?fk_tit='+pk)
			.then((r) => r.json())
			.then(async (r) => {

				if (r[0]) {
					return swal('Erro!!', 'Exclusão não realizada. Registro já possui liquidações.', 'error');
				} else {
					swal({
						dangerMode: true,
						title: 'Atenção!',
						text: 'O registro selecionado será excluído. Confirma?',
						buttons: [ 'Sim', 'Não' ]
					}).then((result) => {
						if (!result) {
							//Delete
							fetch(
								config.protocol +
									'://' +
									config.server +
									':' +
									config.portBackend +
									'/api/deleteContasPagar?pk=' +
									pk,
								{
									method: 'POST',
									headers: {
										'Content-type': 'application/json'
									},
									body: JSON.stringify({})
								}
							)
								.then((r) => r.json())
								.then((r) => {
									console.log(r);
									if (r.message === 'Success!') {
										swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
											//Caso filtered=true, traz form já processado
											var event = new Event('build');
											this.getData(event);
										});
									} else {
										swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
									}
								});
						}
					});
				}
			});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/contas-pagar'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_venc = garanteDate(item.data_venc);
				item.data_emis = garanteDate(item.data_emis);
				item.data_liq = garanteDate(item.data_liq);
			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let filtered = await data.filter((item) => {
				//Filtro: Fornecedor
				let fk_for = Number(item.fk_for) === Number(filter.fk_for) || (filter.fk_for || '') === '';

				//Filtro: Subclasse
				let fk_sub = Number(item.fk_sub) === Number(filter.fk_sub) || (filter.fk_sub || '') === '';

				//Filtro: Classe
				let fk_cla = Number(item.fk_cla) === Number(filter.fk_cla) || (filter.fk_cla || '') === '';

				//Filtro: Data de Nascimento
				// eslint-disable-next-line
				let data_venc_min = true;
				if (!isNaN(new Date((filter.data_venc_min || '') + 'T00:00:00').getTime())) {
					if (
						moment(
							new Date(((item.data_venc || '0000-00-00T00:00:00.000Z').split('T')[0] || '') + 'T12:00:00')
						).isSameOrAfter(new Date((filter.data_venc_min || '') + 'T00:00:00'))
					) {
						data_venc_min = true;
					} else data_venc_min = false;
				}
				// eslint-disable-next-line
				let data_venc_max = true;
				if (!isNaN(new Date((filter.data_venc_max || '') + 'T00:00:00').getTime())) {
					if (
						moment(
							new Date(((item.data_venc || '0000-00-00T00:00:00.000Z').split('T')[0] || '') + 'T12:00:00')
						).isSameOrBefore(new Date((filter.data_venc_max || '') + 'T00:00:00'))
					) {
						data_venc_max = true;
					} else data_venc_max = false;
				}

				//Filtro: Data de Nascimento
				// eslint-disable-next-line
				let data_emis_min = true;
				if (!isNaN(new Date((filter.data_emis_min || '') + 'T00:00:00').getTime())) {
					if (
						moment(
							new Date(((item.data_emis || '0000-00-00T00:00:00.000Z').split('T')[0] || '') + 'T12:00:00')
						).isSameOrAfter(new Date((filter.data_emis_min || '') + 'T00:00:00'))
					) {
						data_emis_min = true;
					} else data_emis_min = false;
				}
				// eslint-disable-next-line
				let data_emis_max = true;
				if (!isNaN(new Date((filter.data_emis_max || '') + 'T00:00:00').getTime())) {
					if (
						moment(
							new Date(((item.data_emis || '0000-00-00T00:00:00.000Z').split('T')[0] || '') + 'T12:00:00')
						).isSameOrBefore(new Date((filter.data_emis_max || '') + 'T00:00:00'))
					) {
						data_emis_max = true;
					} else data_emis_max = false;
				}

				//Filtra
				return data_venc_min && data_venc_max && data_emis_min && data_emis_max && fk_cla && fk_for && fk_sub;
			});

			let queryString = '?';

			
			if (filter.data_venc_min) {
				if (queryString === '?') {
					queryString = queryString + 'data_venc_min=' + filter.data_venc_min;
				} else queryString = queryString + '&data_venc_min=' + filter.data_venc_min;
			}

			if (filter.data_venc_max) {
				if (queryString === '?') {
					queryString = queryString + 'data_venc_max=' + filter.data_venc_max;
				} else queryString = queryString + '&data_venc_max=' + filter.data_venc_max;
			}

			if (filter.data_emis_min) {
				if (queryString === '?') {
					queryString = queryString + 'data_emis_min=' + filter.data_emis_min;
				} else queryString = queryString + '&data_emis_min=' + filter.data_emis_min;
			}

			if (filter.data_emis_max) {
				if (queryString === '?') {
					queryString = queryString + 'data_emis_max=' + filter.data_emis_max;
				} else queryString = queryString + '&data_emis_max=' + filter.data_emis_max;
			}

			if (filter.fk_for) {
				if (queryString === '?') {
					queryString = queryString + 'fk_for=' + filter.fk_for;
				} else queryString = queryString + '&fk_for=' + filter.fk_for;
			}

			if (filter.fk_cla) {
				if (queryString === '?') {
					queryString = queryString + 'fk_cla=' + filter.fk_cla;
				} else queryString = queryString + '&fk_cla=' + filter.fk_cla;
			}

			if (filter.fk_sub) {
				if (queryString === '?') {
					queryString = queryString + 'fk_sub=' + filter.fk_sub;
				} else queryString = queryString + '&fk_sub=' + filter.fk_sub;
			}

			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/contas-pagar' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/contas-pagar?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getContasPagar')
			.then((r) => r.json())
			.then(async (r) => {
				//Filtra
				let items = await this.filterData(r);
				//Trata
				items = await this.modelingData(items);
				this.setState({ data: items });
			});
	}


	filterFornecedor(item) {
		let newFilter = this.state.filter;
		newFilter.fk_for = item.value;
		newFilter.fornecedor = item;
		this.setState({ filter: newFilter });
	}

	filterSubclasse(item) {
		let newFilter = this.state.filter;
		newFilter.fk_sub = item.value;
		newFilter.subclasse = item;
		this.setState({ filter: newFilter });
	}

	filterClasse(item) {
		let newFilter = this.state.filter;
		newFilter.fk_cla = item.value;
		newFilter.classe = item;
		this.setState({ filter: newFilter });
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}


	render() {
		let fornecedores = this.state.combos.filter((item) => {
			return item.type === 'fornecedores';
		});
		let classes = this.state.combos.filter((item) => {
			return item.type === 'classes';
		});
		let subclasses = this.state.combos.filter((item) => {
			return item.type === 'subclasses';
		});

		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="ContasPagar" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Contas a Pagar</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
							<div className="itemFiltro" style={{ width: '250px' }}>
									<label className="labelFiltro">Fornecedor</label>
									<Autocomplete
										field="filtroFk_for"
										items={fornecedores}
										inModal=""
										selected={this.state.filter.fornecedor || { display: '' }}
										select={this.filterFornecedor}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '200px' }}>
									<label className="labelFiltro">Classe de Despesa</label>
									<Autocomplete
										field="filtroFk_cla"
										items={classes}
										inModal=""
										selected={this.state.filter.classe || { display: '' }}
										select={this.filterClasse}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '200px' }}>
									<label className="labelFiltro">Subclasse de Despesa</label>
									<Autocomplete
										field="filtroFk_sub"
										items={subclasses}
										inModal=""
										selected={this.state.filter.subclasse || { display: '' }}
										select={this.filterSubclasse}
									/>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<LinkContainer to={'/apergs/contas-pagar/registro'}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo
										Registro
									</button>
								</LinkContainer>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: '',
											width: 123,
											Cell: (row) => {
												return (
													<div>
														<LinkContainer
															to={'/apergs/contas-pagar/registro?pk=' + row.row.pk_tit}
														>
																<Icon
																	size={20}
																	icon={edit}
																	className="iconButton"
																/>
														</LinkContainer>
														<Icon
															size={20}
															icon={iosTrash}
															name={row.row.pk_tit}
															className="iconButton"
															onClick={(e) => {
																this.handleDelete(
																	e,
																	row.row.pk_tit,
																);
															}}
														/>
														<LinkContainer
															to={'/apergs/contas-pagar/liquidar?pk=' + row.row.pk_tit}
														>
																<Icon
																	size={20}
																	icon={money}
																	className="iconButton"
																/>
														</LinkContainer>
														<LinkContainer
															to={'/apergs/contas-pagar/reabrir?pk=' + row.row.pk_tit}
														>
																<Icon
																	size={20}
																	icon={back}
																	className="iconButton"
																/>
														</LinkContainer>
													</div>
												);
											}	
										},
										{
											Header: 'Nº Doc',
											accessor: 'numdoc',
											width: 120
										},
										{
											Header: 'Dt Vencimento',
											accessor: 'data_venc',
											width: 110
										},
										{
											Header: 'Fornecedor',
											accessor: 'razao_social',
											width: 250
										},
										{
											Header: 'Dt Emissao',
											accessor: 'data_emis',
											width: 110
										},
										{
											Header: 'Valor',
											accessor: 'valor',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Pago',
											accessor: 'liquidado',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.liquidado).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Pendente',
											accessor: 'pendente',
											width: 90,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor - row.original.liquidado).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Dt Liquidação',
											accessor: 'data_liq',
											width: 110
										},
										{
											Header: 'Classe',
											accessor: 'descricaocla',
											width: 100
										},
										{
											Header: 'Subclasse',
											accessor: 'descricaosub',
											width: 130
										},
										{
											Header: 'Código',
											accessor: 'pk_tit',
											show: false
										},
									]}
									defaultSorted={[
										{
											id: 'data_venc',
											desc: true
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ContasPagar;
