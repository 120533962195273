import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import $ from 'jquery';
import '../css/Default.css';
import { Modal, ListGroup, ListGroupItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { garanteDate, asyncForEach, populateForm, delay, getParameterByName } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import { iosFolder } from 'react-icons-kit/ionicons/iosFolder';
import {view} from 'react-icons-kit/ikons/view'
import ReactLoading from 'react-loading';
import Autocomplete from '../components/Autocomplete';

class RetornosBanrisul extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			file: '',
			filter: [],
			modal: { show: false },
			modalDelete: { show: false },
			edit: false,
			defaultFilePath: '',
			filterChanged: '',
			data: []
		};
		this.handleSelectedFile = this.handleSelectedFile.bind(this);
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.closeModalDelete = this.closeModalDelete.bind(this);
		this.modalState = this.modalState.bind(this);
		this.modalDeleteState = this.modalDeleteState.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.filterAssociado = this.filterAssociado.bind(this);
		this.showDeleteModal = this.showDeleteModal.bind(this);
	}

	closeModal() {
		this.setState({ modal: { show: false } });
	}

	closeModalDelete() {
		this.setState({ modalDelete: { show: false } });
	}

	filterAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ass = item.value;
		newFilter.associado = item;
		this.setState({ filter: newFilter });
	}


	async showModal(e) {
		e.preventDefault();
		console.log(e);
		let edicao = false;
		let pk = '0';
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let form = document.getElementById('gerarRetornosBanrisul');
					form.reset();
					this.setState({ file: [] });
					r[0].competencia_mes = Number(r[0].competencia.substring(0, 2));
					r[0].competencia_ano = Number(r[0].competencia.substring(2, 4));
					console.log(r[0].competencia_mes, r[0].competencia_ano);
					populateForm(form, r[0]);
				}
			});
		this.setState({ modal: { show: true }, modalDelete: { show: false }, edit: edicao, codigo: pk });
	}

	async showDeleteModal(e) {
		e.preventDefault();
		let edicao = false;
		let pk = '0';
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros?pk=999')
			.then((r) => r.json())
			.then(async (r) => {
				if (typeof r[0] === 'undefined') {
					window.location.href = '/home';
				} else {
					let form = document.getElementById('excluirRetornosBanrisul');
					form.reset();
					this.setState({ file: [] });
					r[0].competencia_mes = Number(r[0].competencia.substring(0, 2));
					r[0].competencia_ano = Number(r[0].competencia.substring(2, 4));
					console.log(r[0].competencia_mes, r[0].competencia_ano);
					populateForm(form, r[0]);
				}
			});
		this.setState({ modalDelete: { show: true }, modal: { show: false }, edit: edicao, codigo: pk });
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	modalDeleteState() {
		if (this.state.modalDelete.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}

	handleSave(e) {
		e.preventDefault();
		if (this.state.file.name) {
			swal({
				title: 'Atenção!',
				text: 'Deseja iniciar a importação?',
				buttons: [ 'Sim', 'Não' ]
			}).then((result) => {
				if (!result) this.submitData(e);
			});
		} else {
			swal({
				title: 'Erro!',
				text: 'Nenhum arquivo informado!',
				icon: 'error'
			});
		}
	}

	async handleDelete(e) {
		e.preventDefault();
		this.closeModalDelete();
		let form = new FormData(document.getElementById('excluirRetornosBanrisul'));
		let orgao = form.get('orgao');
		let competencia =
			Number(form.get('competencia_mes')) < 10
				? '0' + form.get('competencia_mes') + form.get('competencia_ano')
				: form.get('competencia_mes') + form.get('competencia_ano');
		if (orgao && competencia) {
			await fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/getRetornosBanrisul?orgao=' +
					orgao +
					'&competencia=' +
					competencia,
				{
					method: 'GET',
					headers: {
						'Content-type': 'application/json'
					}
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.length >= 1) {
						swal({
							title: 'Atenção!',
							text: 'Deseja excluir todos os lançamentos referentes à Competência/Débito ' + (
									Number(form.get('competencia_mes')) < 10 ? ('0' + form.get('competencia_mes') + '/20' + form.get('competencia_ano'))
									: (form.get('competencia_mes') +
										'/20' +
										form.get('competencia_ano'))) +
										" do arquivo '" +
										r[0].arquivo +
										"'?",
							buttons: [ 'Sim', 'Não' ],
							icon: 'warning'
						}).then(async (result) => {
							if (!result) {
								await fetch(
									config.protocol +
										'://' +
										config.server +
										':' +
										config.portBackend +
										'/api/deleteRetornosBanrisul?orgao=' +
										orgao +
										'&competencia=' +
										competencia,
									{
										method: 'GET',
										headers: {
											'Content-type': 'application/json'
										}
									}
								)
									.then((r) => r.json())
									.then((r) => {
										console.log(r);
										if (r.message === 'Success!') {
											swal(
												'Exclusão concluída',
												'As informações foram excluídas com sucesso!',
												'success'
											);
										} else {
											swal('Erro!', r.message, 'error');
										}
									});
							}
						});
					} else {
						swal({
							title: 'Erro!',
							text:
								r.length > 0
									? 'Importação selecionada possui valor recebido!'
									: 'Nenhuma importação encontrada com o tipo e Competência/Débito informados!',
							icon: 'error'
						});
					}
				});
		} else {
			swal({
				title: 'Erro!',
				text: 'Informe o tipo!',
				icon: 'error'
			});
		}
	}

	async submitData(e) {
		// e.preventDefault();
		this.setState({ modal: { show: false } });
		let strAuth = sessionStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 });
		let auth = JSON.parse(strAuth);

		//Bota arquivo no FormData
		const data = new FormData();
		data.append('file', this.state.file, this.state.file.name);
		let competencia =
			String(
				Number($('#competencia_mes').val()) < 10
					? '0' + $('#competencia_mes').val()
					: $('#competencia_mes').val()
			) + String($('#competencia_ano').val());



		this.setState({ retornosBanrisulStats: { message: 'Started', info: [] } });
		axios
			.post(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/novoRetornoBanrisul?competencia=' +
					competencia +
					'&fk_usu=' +
					auth.user,
				data
			)
			.then(async (response) => {
				this.setState({ retornosBanrisulStats: response.data });
				console.log(response.data);
				if (response.data.message === 'Success!') {
					// let count = 0
					while (this.state.retornosBanrisulStats.message !== 'Done') {
						await fetch(
							config.protocol + '://' + config.server + ':' + config.portBackend + '/api/checkRetornosBanrisul',
							{
								method: 'GET',
								headers: {
									'Content-type': 'application/json'
								}
							}
						)
							.then(async (r) => r.json())
							.then(async (r) => {
								this.setState({ retornosBanrisulStats: r });
								console.log(this.state.retornosBanrisulStats);
								swal({
									title: 'Importando arquivo RetornosBanrisul',
									text: this.state.retornosBanrisulStats.message,
									closeOnClickOutside: false,
									buttons: false,
									// showLoaderOnConfirm: true,
									content: (
										<div style={{ width: '100%' }}>
											<div style={{ display: 'table', margin: '0 auto' }}>
												<ReactLoading
													type={'bubbles'}
													color={'#004136'}
													height={100}
													width={100}
												/>
											</div>
										</div>
									)
								});
							});
						if (this.state.retornosBanrisulStats.message === 'Error') {
							swal('Importação não concluída', 'Houve um problema na importação.', 'error');
							break;
						}
						if (this.state.retornosBanrisulStats.message === 'Cancelado') {
							break;
						}
						await delay(1000);
					}
					if (this.state.retornosBanrisulStats.message === 'Error') {
						swal('Geração não concluída', this.state.retornosBanrisulStats.info[0], 'error');
					} else if (this.state.retornosBanrisulStats.message === 'Cancelado') {
						console.log(this.state.naoEncontrados);
						this.setState({ naoEncontrados: this.state.retornosBanrisulStats.info[0].itens });
						let naoEncontrados = this.state.naoEncontrados.map(this.populaNaoEncontrados);
						swal({
							content: (
								<div>
									<p>
										A importação não pode ser concluída. Os registros abaixo não foram encontrados:
									</p>
									<div style={{ overflowY: 'auto', maxHeight: '300px' }}>
										<ListGroup>{naoEncontrados}</ListGroup>
									</div>
								</div>
							),
							icon: 'error'
						});
					} else {
						swal('Geração concluída', 'As informações foram geradas com sucesso!', 'success');
					}
				} else {
					swal('Importação não concluída', response.data.info[0], 'error');
				}
			});
	}

	async componentDidMount() {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let comboAssociados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let associados = {
						type: 'associados',
						display: item.nome,
						value: item.pk_ass
					};
					await comboAssociados.push(associados);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getParametros')
			.then((r) => r.json())
			.then(async (r) => {
				this.setState({ defaultFilePath: r[0].pasta_retorno_tesouro });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getDependentes')
			.then((r) => r.json())
			.then(async (r) => {
				let comboDependentes = this.state.combos;
				await asyncForEach(r, async (item) => {
					let dependentes = {
						type: 'dependentes',
						display: item.nome,
						value: item.pk_ass
					};
					await comboDependentes.push(dependentes);
				});
				this.setState({ combos: this.state.combos });
			});

		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = true;
		getParameterByName('filtered');
		query.matricula = getParameterByName('matricula');
		query.competenciaAno = getParameterByName('competenciaAno');
		query.competenciaMes = getParameterByName('competenciaMes');
		query.tipo = getParameterByName('tipo');
		query.fk_ass = Number(getParameterByName('fk_ass'));
		query.associado = this.state.combos.filter((item) => {
			return item.type === 'associados' && Number(item.value) === Number(query.fk_ass);
		})[0];


		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/retornos-banrisul'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.competenciastr = item.competencia.substr(0, 2) + '/20' + item.competencia.substr(2, 2);
				item.data_geracaostr =
					garanteDate(item.data_geracao) === '00/00/0000' ? '' : garanteDate(item.data_geracao);
				item.data_debitostr =
					garanteDate(item.data_debito) === '00/00/0000' ? '' : garanteDate(item.data_debito);
				item.data_realizacaostr =
					garanteDate(item.data_realizacao) === '00/00/0000' ? '' : garanteDate(item.data_realizacao);

				item.valor_debitado_real = (item.codigo_retorno === '00' ? item.valor_debitado : 0)
				
				item.tipostr =
					item.tipo === 'M'
						? 'MENSALIDADE'
						: item.tipo === 'S' ? 'SOS' : item.tipo === 'O' ? 'ODONTO' : 'PARTICIPACAO';
			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let filtered = await data.filter((item) => {
				// // //Filtro: Nome
				// let tipo = filter.tipo
				// 	? filter.tipo !== 'P'
				// 		? (item.tipo || '').toUpperCase() === (filter.tipo || '')
				// 		: ![ 'M', 'S', 'O' ].includes(item.tipo)
				// 	: true;

				// //Filtro: Competencia - Ano
				let competenciaAno = ((item.competencia || '').substr(2, 2) || '')
					.includes((filter.competenciaAno || '').toUpperCase());

				// //Filtro: Competencia - Mes
				let competenciaMes = ((item.competencia || '').substr(0, 2) || '')
					.includes((filter.competenciaMes || '').toUpperCase());

				//Filtro: Associado
				let fk_ass = Number(item.fk_ass) === Number(filter.fk_ass) || (filter.fk_ass || '') === '';


				//Filtro: Matrícula
				let matricula = (item.matricula || '').includes((filter.matricula || '').toUpperCase());

				//Filtro: Espécie
				let especie = (item.especie || '').includes((filter.especie || '').toUpperCase());

				//Filtra
				return fk_ass && competenciaMes && competenciaAno && matricula && especie;
			});

			let queryString = '?';


			if (filter.competenciaAno) {
				if (queryString === '?') {
					queryString = queryString + 'competenciaAno=' + filter.competenciaAno;
				} else queryString = queryString + '&competenciaAno=' + filter.competenciaAno;
			}

			if (filter.competenciaMes) {
				if (queryString === '?') {
					queryString = queryString + 'competenciaMes=' + filter.competenciaMes;
				} else queryString = queryString + '&competenciaMes=' + filter.competenciaMes;
			}

			if (filter.fk_ass) {
				if (queryString === '?') {
					queryString = queryString + 'fk_ass=' + filter.fk_ass;
				} else queryString = queryString + '&fk_ass=' + filter.fk_ass;
			}

		
			if (filter.matricula) {
				if (queryString === '?') {
					queryString = queryString + 'matricula=' + filter.matricula;
				} else queryString = queryString + '&matricula=' + filter.matricula;
			}

			if (filter.especie) {
				if (queryString === '?') {
					queryString = queryString + 'especie=' + filter.especie;
				} else queryString = queryString + '&especie=' + filter.especie;
			}

			//Monta Query URL
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/retornos-banrisul' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/retornos-banrisul?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getRetornosBanrisul')
			.then((r) => r.json())
			.then(async (r) => {
				//Filtra
				let items = await this.filterData(r);
				//Trata
				items = await this.modelingData(items);
				this.setState({ data: items });
			});
	}

	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	handleSelectedFile(e) {
		$('#nome').val(e.target.files[0].name);
		this.setState({ file: e.target.files[0] });
		console.log(e.target.files[0]);
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaNaoEncontrados(item, id) {
		return (
			<ListGroupItem id={'list' + id}>
				Matricula:{' '}
				{item.matricula+',  '}
				Contrato:{' '}
				{item.contrato}
				<br />
				{/* <label className="labelSwal">Carteira:</label>
                <input name="codigo" type="text" id={id} className="inputSwal" style={{width: '40%'}} defaultValue='0'></input> */}
			</ListGroupItem>
		);
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}

	render() {
		let anos = this.listaAnos().map(this.populaAnos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});

		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="RetornosBanrisul" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Retornos - Banrisul</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div className="itemFiltro">
									<label className="labelFiltro">Competência/Débito</label>
									<select
										name="competenciaMes"
										id="filtroCompetenciaMes"
										className="selectFiltro"
										value={this.state.filter.competenciaMes || ''}
										onChange={this.handleChange}
									>
										<option value="">Mês</option>
										<option value="1">JANEIRO</option>
										<option value="2">FEVEREIRO</option>
										<option value="3">MARÇO</option>
										<option value="4">ABRIL</option>
										<option value="5">MAIO</option>
										<option value="6">JUNHO</option>
										<option value="7">JULHO</option>
										<option value="8">AGOSTO</option>
										<option value="9">SETEMBRO</option>
										<option value="10">OUTUBRO</option>
										<option value="11">NOVEMBRO</option>
										<option value="12">DEZEMBRO</option>
									</select>
									<select
										name="competenciaAno"
										id="filtroCompetenciaAno"
										className="selectFiltro"
										value={this.state.filter.competenciaAno || ''}
										onChange={this.handleChange}
									>
										<option value="">Ano</option>
										{anos}
									</select>
								</div>

								<div className="itemFiltro">
									<label className="labelFiltro">Matrícula</label>
									<input
										name="matricula"
										type="text"
										id="filtroMatricula"
										className="inputFiltro"
										style={{ width: '100px' }}
										value={this.state.filter.matricula || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '300px' }}>
									<label className="labelFiltro">Associado</label>
									<Autocomplete
										field="filtroFk_ass"
										items={associados}
										inModal=""
										selected={this.state.filter.associado || { display: '' }}
										select={this.filterAssociado}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Espécie</label>
									<input
										name="especie"
										type="text"
										id="filtroEspecie"
										className="inputFiltro"
										style={{ width: '300px' }}
										value={this.state.filter.especie || ''}
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<button className="buttonNovo" onClick={this.showModal}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Importar
									Arquivo
								</button>
								<button className="buttonNovo" onClick={this.showDeleteModal}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosTrash} />Excluir Importação
								</button>
								<LinkContainer to={'/apergs/relatorio/debitos/banrisul'}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline', marginRight: '5px' }} icon={view} />
										Relatório de Débitos
									</button>
								</LinkContainer>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Importação de Arquivo Retorno Banrisul</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="gerarRetornosBanrisul" name="gerarRetornosBanrisul" onSubmit={this.submitData}>
												<div>
													<div>
														<label htmlFor="competencia_mes" className="labelModal">
															Competência/Débito - Mês:
														</label>
														<select
															name="competencia_mes"
															id="competencia_mes"
															className="form-control"
															style={{ width: '150px' }}
														>
															<option value="">Selecione</option>
															<option value="1">JANEIRO</option>
															<option value="2">FEVEREIRO</option>
															<option value="3">MARÇO</option>
															<option value="4">ABRIL</option>
															<option value="5">MAIO</option>
															<option value="6">JUNHO</option>
															<option value="7">JULHO</option>
															<option value="8">AGOSTO</option>
															<option value="9">SETEMBRO</option>
															<option value="10">OUTUBRO</option>
															<option value="11">NOVEMBRO</option>
															<option value="12">DEZEMBRO</option>
														</select>
													</div>
													<div>
														<label htmlFor="competencia_ano" className="labelModal">
															Competência/Débito - Ano:
														</label>
														<select
															name="competencia_ano"
															id="competencia_ano"
															className="form-control"
															style={{ width: '100px' }}
														>
															<option value="">Selecione</option>
															{anos}
														</select>
													</div>
												</div>
												<div>
													<label className="labelModal">Arquivo</label>
													<input
														type="file"
														name="nome"
														id="html_btn"
														value={this.defaultFilePath}
														onChange={this.handleSelectedFile}
													/>
													<button
														className="ModalButton"
														onClick={this.selectFile}
														style={{
															width: '100px',
															marginLeft: '10px',
															float: 'right',
															marginTop: '2px'
														}}
													>
														<Icon size={20} icon={iosFolder} /> Selecionar...
													</button>
													<input
														type="text"
														id="nome"
														name="nome"
														className="form-control"
														data-parse="uppercase"
														style={{ display: 'inline', width: 'calc(100% - 110px)' }}
														readOnly
													/>
												</div>
												
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSave}>
												Importar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div className={this.modalDeleteState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Exclusão de Importação</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="excluirRetornosBanrisul" name="excluirRetornosBanrisul">
												<div>
													<div>
														<label htmlFor="competencia_mes" className="labelModal">
															Competência/Débito - Mês:
														</label>
														<select
															name="competencia_mes"
															id="competencia_mes"
															className="form-control"
															style={{ width: '150px' }}
														>
															<option value="">Selecione</option>
															<option value="1">JANEIRO</option>
															<option value="2">FEVEREIRO</option>
															<option value="3">MARÇO</option>
															<option value="4">ABRIL</option>
															<option value="5">MAIO</option>
															<option value="6">JUNHO</option>
															<option value="7">JULHO</option>
															<option value="8">AGOSTO</option>
															<option value="9">SETEMBRO</option>
															<option value="10">OUTUBRO</option>
															<option value="11">NOVEMBRO</option>
															<option value="12">DEZEMBRO</option>
														</select>
													</div>
													<div>
														<label htmlFor="competencia_ano" className="labelModal">
															Competência/Débito - Ano:
														</label>
														<select
															name="competencia_ano"
															id="competencia_ano"
															className="form-control"
															style={{ width: '100px' }}
														>
															<option value="">Selecione</option>
															{anos}
														</select>
													</div>
												</div>
												
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleDelete}>
												Excluir
											</button>
											<button className="ModalButton" onClick={this.closeModalDelete}>
												Cancelar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: 'Código',
											accessor: 'pk_itc',
											show: false
										},
										{
											Header: 'Competência',
											accessor: 'competenciastr',
											width: 130
										},
										{
											Header: 'Data Débito',
											accessor: 'data_debitostr',
											width: 130
										},
										{
											Header: 'Associado',
											accessor: 'nomeass',
											width: 200
										},
										{
											Header: 'Código Retorno',
											accessor: 'codigo_retorno',
											width: 110
										},										
										{
											Header: 'Valora à Debitar',
											accessor: 'valor_debitado',
											width: 120,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_debitado).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Valor Debitado',
											accessor: 'valor_debitado_real',
											width: 120,
											Cell: (row) => {
												return (
													<div>
														{Number(row.original.valor_debitado_real).toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
													</div>
												);
											}
										},
										{
											Header: 'Arquivo',
											accessor: 'arquivo',
											width: 230
										},
										{
											Header: 'Usuário',
											accessor: 'nomeusu',
											width: 120
										},

									]}
									defaultSorted={[
										{
											id: 'pk_bco',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RetornosBanrisul;
