import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
// import {iosContact} from 'react-icons-kit/ionicons/iosContact'
// import {iphone} from 'react-icons-kit/ionicons/iphone'
import { edit } from 'react-icons-kit/ionicons/edit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
// import {tasks} from 'react-icons-kit/fa/tasks'
import Autocomplete from '../components/Autocomplete';

class Fornecedores extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			filter: [],
			filterChanged: '',
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.filterSubclasse = this.filterSubclasse.bind(this);
		this.filterCidades = this.filterCidades.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	async componentDidMount() {
		//Busca valores para combo de filtro
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSubclasses')
			.then((r) => r.json())
			.then(async (r) => {
				let comboSubclasses = this.state.combos;
				await asyncForEach(r, async (item) => {
					let subclasses = {
						type: 'subclasses',
						display: item.descricao,
						value: item.pk_sub
					};
					await comboSubclasses.push(subclasses);
				});
				this.setState({ combos: comboSubclasses });
			});

		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
			.then((r) => r.json())
			.then(async (r) => {
				let combosTiposCidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let cidades = {
						type: 'cidades',
						display: item.nome,
						value: item.pk_cid
					};
					await combosTiposCidades.push(cidades);
				});
				this.setState({ combos: combosTiposCidades });
			});

		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = true;
		getParameterByName('filtered');
		query.razao_social = getParameterByName('razao_social');
		query.nome_fantasia = getParameterByName('nome_fantasia');
		query.fk_sub = getParameterByName('fk_sub');
		query.subclasse = this.state.combos.filter((item) => {
			return item.type === 'subclasses' && Number(item.value) === Number(query.fk_sub);
		})[0];
		query.fk_cid = getParameterByName('fk_cid');
		query.subclasse = this.state.combos.filter((item) => {
			return item.type === 'cidades' && Number(item.value) === Number(query.fk_cid);
		})[0];


		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleDelete(e, pk, automatico, liquidado) {
		e.preventDefault();
		if (automatico === 'S')
			return swal('Erro!!', 'Exclusão não realizada. Registro foi gerado automaticamente.', 'error');
		if (liquidado > 0) return swal('Erro!!', 'Exclusão não realizada. Registro possui valor recebido.', 'error');
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteFornecedores?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/fornecedores'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {

			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let filtered = await data.filter((item) => {
				//Filtro: Razão Social
				let razao_social = (item.razao_social || '').includes((filter.razao_social || '').toUpperCase());

				//Filtro: Nome Fantasia
				let nome_fantasia = (item.nome_fantasia || '').includes((filter.nome_fantasia || '').toUpperCase());

				//Filtro: Subclasse
				let fk_sub = Number(item.fk_sub) === Number(filter.fk_sub) || (filter.fk_sub || '') === '';

				//Filtro: Tipo de Serviço
				let fk_cid = Number(item.fk_cid) === Number(filter.fk_cid) || (filter.fk_cid || '') === '';

				//Filtra
				return razao_social && nome_fantasia && fk_sub && fk_cid;
			});

			let queryString = '?';


			if (filter.razao_social) {
				if (queryString === '?') {
					queryString = queryString + 'razao_social=' + filter.razao_social;
				} else queryString = queryString + '&razao_social=' + filter.razao_social;
			}

			if (filter.nome_fantasia) {
				if (queryString === '?') {
					queryString = queryString + 'nome_fantasia=' + filter.nome_fantasia;
				} else queryString = queryString + '&nome_fantasia=' + filter.nome_fantasia;
			}

			if (filter.fk_sub) {
				if (queryString === '?') {
					queryString = queryString + 'fk_sub=' + filter.fk_sub;
				} else queryString = queryString + '&fk_sub=' + filter.fk_sub;
			}

			if (filter.fk_cid) {
				if (queryString === '?') {
					queryString = queryString + 'fk_cid=' + filter.fk_cid;
				} else queryString = queryString + '&fk_cid=' + filter.fk_cid;
			}

			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/fornecedores' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/fornecedores?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getFornecedores')
			.then((r) => r.json())
			.then(async (r) => {
				//Filtra
				let items = await this.filterData(r);
				//Trata
				items = await this.modelingData(items);
				this.setState({ data: items });
			});
	}


	filterSubclasse(item) {
		let newFilter = this.state.filter;
		newFilter.fk_sub = item.value;
		newFilter.subclasse = item;
		this.setState({ filter: newFilter });
	}

	filterCidades(item) {
		let newFilter = this.state.filter;
		newFilter.fk_cid = item.value;
		newFilter.cidades = item;
		this.setState({ filter: newFilter });
	}

	render() {
		let subclasses = this.state.combos.filter((item) => {
			return item.type === 'subclasses';
		});
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidades';
		});
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Fornecedores" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Fornecedores</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
							<div className="itemFiltro">
								<label className="labelFiltro">Razão Social</label>
								<input
									name="razao_social"
									type="text"
									id="filtroRazaoSocial"
									className="inputFiltro"
									style={{ width: '30vw' }}
									value={this.state.filter.razao_social || ''}
									onChange={this.handleChange}
								/>
							</div>
							<div className="itemFiltro">
								<label className="labelFiltro">Nome Fantasia</label>
								<input
									name="nome_fantasia"
									type="text"
									id="filtroNomeFantasia"
									className="inputFiltro"
									style={{ width: '30vw' }}
									value={this.state.filter.nome_fantasia || ''}
									onChange={this.handleChange}
								/>
							</div>
							<div className="itemFiltro" style={{ width: '300px' }}>
									<label className="labelFiltro">Subclasse</label>
									<Autocomplete
										field="filtroFk_sub"
										items={subclasses}
										inModal=""
										selected={this.state.filter.subclasse || { display: '' }}
										select={this.filterSubclasse}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '300px' }}>
									<label className="labelFiltro">Cidade</label>
									<Autocomplete
										field="filtroFk_cid"
										items={cidades}
										inModal=""
										selected={this.state.filter.cidades || { display: '' }}
										select={this.filterCidades}
									/>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<LinkContainer to={'/apergs/fornecedores/registro'}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo
										Registro
									</button>
								</LinkContainer>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: '',
											width: 70,
											Cell: (row) => {
												return (
													<div className="">
														<LinkContainer
															to={'/apergs/fornecedores/registro?pk=' + row.row.pk_for}
														>
															<Icon size={20} icon={edit}  className="iconButton"/>
														</LinkContainer>
														<Icon size={20} icon={iosTrash} className="iconButton"
																
															onClick={(e) => {this.handleDelete(e, row.row.pk_for);
														}}/>
													</div>
												);
											}
										},
										{
											Header: 'Código',
											accessor: 'pk_for',
											width: 60
										},
										{
											Header: 'Razão Social',
											accessor: 'razao_social',
											width: 200
										},
										{
											Header: 'Nome Fantasia',
											accessor: 'nome_fantasia',
											width: 130
										},
										{
											Header: 'Subclasse',
											accessor: 'descricaosub',
											width: 110
										},
										{
											Header: 'Endereço',
											accessor: 'endereco',
											width: 200
										},
										{
											Header: 'Bairro',
											accessor: 'bairro',
											width: 100
										},
										{
											Header: 'CEP',
											accessor: 'cep',
											width: 80
										},
										{
											Header: 'Cidade',
											accessor: 'nomecid',
											width: 130
										}
									]}
									defaultSorted={[
										{
											id: 'data_venc',
											desc: true
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
									// SubComponent={(row) => {
									// 	return (
									// 		<div className="buttonsDetail">
									// 			<LinkContainer
									// 				to={'/apergs/fornecedores/registro?pk=' + row.row.pk_for}
									// 			>
									// 				<button className="buttonDetail">
									// 					<Icon
									// 						size={20}
									// 						icon={edit}
									// 						style={{ marginRight: '5px', marginLeft: '-5px' }}
									// 					/>Editar
									// 				</button>
									// 			</LinkContainer>
									// 			<button
									// 				className="buttonDetail"
									// 				onClick={(e) => {
									// 					this.handleDelete(
									// 						e,
									// 						row.row.pk_for
									// 					);
									// 				}}
									// 				name={row.row.pk_for}
									// 			>
									// 				<Icon
									// 					size={20}
									// 					icon={iosTrash}
									// 					style={{ marginRight: '5px', marginLeft: '-5px' }}
									// 				/>Excluir
									// 			</button>
									// 		</div>
									// 	);
									// }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Fornecedores;
