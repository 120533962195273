import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import $ from 'jquery';
import '../css/Default.css';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import MultiSelect from "@khanacademy/react-multi-select";
import { asyncForEach, getParameterByName } from '../Utils';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import { view } from 'react-icons-kit/ikons/view'
import ReactLoading from 'react-loading';
import Autocomplete from '../components/Autocomplete';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import axios from 'axios';

class Arquivos extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			file: '',
			associados: [],
			filter: [],
			modal: { show: false },
			registro: {},
			edit: false,
			filterChanged: '',
			data: [],
			arquivo: {},
			arquivos: [],
			path: ''

		};
		this.handleSelectedFile = this.handleSelectedFile.bind(this);
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modalState = this.modalState.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.filterAssociado = this.filterAssociado.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleView = this.handleView.bind(this);
		this.handleSelectedPath = this.handleSelectedPath.bind(this)
	}

	closeModal() {
		this.setState({ modal: { show: false } });
	}

	filterAssociado(item) {
		let newFilter = this.state.filter;
		newFilter.fk_ass = item.value;
		newFilter.associado = item;
		this.setState({ filter: newFilter });
	}


	async showModal(e, associados) {
		e.preventDefault();
		let form = document.getElementById('gerarDemonstrativos');
		form.reset();
		this.setState({
			arquivo: {},
			path: ''
		})
		let values = []
		asyncForEach(associados, async (item, index) => {
			values.push(item.value)
			if (index + 1 === associados.length) {
				this.setState({ modal: { show: true }, associados: values });
			}
		})


	}


	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: ['Sim', 'Não']
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol + '://' + config.server + ':' + config.portBackend + '/api/deleteArquivos?pk=' + pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exclusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}


	handleSave(e) {
		e.preventDefault();
		let competencia = ''
		if (Number($('#competencia_mes').val()) > 0)
			competencia = String(
				Number($('#competencia_mes').val()) < 10
					? '0' + $('#competencia_mes').val()
					: $('#competencia_mes').val()
			) + String($('#competencia_ano').val());

		let competencia_contas = ''

		let competencia_dirf = ''
		if (Number($('#competencia_dirf').val()) > 0)
			competencia_dirf = $('#competencia_dirf').val();

		let descricao =
			String($('#descricao').val());

		if (this.state.associados.length > 0) {
			swal({
				title: 'Atenção!',
				text: 'Confirma disponibilizar os arquivos escolhidos?',
				buttons: ['Sim', 'Não']
			}).then((result) => {
				if (!result) this.submitData(e, competencia, competencia_contas, competencia_dirf, this.state.arquivo, this.state.associados, descricao);
			});
		} else {
			swal({
				title: 'Erro!',
				text: 'Nenhum associado informado!',
				icon: 'error'
			});
		}
	}

	async submitData(e, competencia, competencia_contas, competencia_dirf, arquivo, associados, descricao) {
		// e.preventDefault();
		this.setState({ modal: { show: false } });
		try {
			if (this.state.arquivos.length > 0) {
				swal({
					title: 'Disponibilizando Arquivos',
					text: 'Disponibilizando arquivos',
					closeOnClickOutside: false,
					buttons: false,
					// showLoaderOnConfirm: true,
					content: (
						<div style={{ width: '100%' }}>
							<div style={{ display: 'table', margin: '0 auto' }}>
								<ReactLoading
									type={'bubbles'}
									color={'#004136'}
									height={100}
									width={100}
								/>
							</div>
						</div>
					)
				});

				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/insertArquivosLote?descricao=' + descricao + '&arquivos=' + this.state.arquivos)
					.then(async (r) => {
						swal('Geração concluída', 'Arquivos disponibilizados com sucesso!', 'success');

					})
			} else

				if ((competencia) || (competencia_dirf) || (arquivo.name)) {
					asyncForEach(associados, async (item, index) => {

						await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/insertArquivos?fk_ass=' + item + '&competencia=' + competencia + '&competencia_contas=' + competencia_contas + '&competencia_dirf=' + competencia_dirf + '&arquivo=' + arquivo.name + '&descricao=' + descricao)
							.then((r) => r.json())
							.then(async (r) => {
								swal({
									title: 'Disponibilizando Arquivos',
									text: 'Disponibilizando arquivos (' + (index + 1) + '/' + associados.length + ')',
									closeOnClickOutside: false,
									buttons: false,
									// showLoaderOnConfirm: true,
									content: (
										<div style={{ width: '100%' }}>
											<div style={{ display: 'table', margin: '0 auto' }}>
												<ReactLoading
													type={'bubbles'}
													color={'#004136'}
													height={100}
													width={100}
												/>
											</div>
										</div>
									)
								});

							})

						if (index + 1 === associados.length) {
							swal('Geração concluída', 'Arquivos disponibilizados com sucesso!', 'success');
						}
					})
				}

		} catch (err) {
			swal('Geração não concluída', 'Ocorreu um erro na geração!', 'error');
		}


	}

	async componentDidMount() {
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados')
			.then((r) => r.json())
			.then(async (r) => {
				let comboAssociados = this.state.combos;
				await asyncForEach(r, async (item) => {
					let associados = {
						type: 'associados',
						display: item.nome,
						label: item.nome,
						value: item.pk_ass
					};
					await comboAssociados.push(associados);
				});
				this.setState({ combos: this.state.combos });
			});

		let query = {};
		query.filtered = getParameterByName('filtered');
		query.fk_ass = Number(getParameterByName('fk_ass'));
		query.dataini = getParameterByName('dataini');
		query.datafim = getParameterByName('datafim');

		query.associado = this.state.combos.filter((item) => {
			return item.type === 'associados' && Number(item.value) === Number(query.fk_ass);
		})[0];


		this.setState({
			filter: query
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/apergs/arquivos'); //Apaga QueryURL
		this.setState({ filter: [] });
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			let filtered = await data.filter((item) => {

				//Filtro: Associado
				let fk_ass = Number(item.fk_ass) === Number(filter.fk_ass) || (filter.fk_ass || '') === '';

				let dataini = filter.dataini == (item.dataini || '');

				let datafim = filter.datafim == (item.datafim || '');

				//Filtra
				return fk_ass;
			});

			let queryString = '?';

			if (filter.fk_ass) {
				if (queryString === '?') {
					queryString = queryString + 'fk_ass=' + filter.fk_ass;
				} else queryString = queryString + '&fk_ass=' + filter.fk_ass;
			}

			if (filter.dataini) {
				if (queryString === '?') {
					queryString = queryString + 'dataini=' + filter.dataini;
				} else queryString = queryString + '&dataini=' + filter.dataini;
			}

			if (filter.datafim) {
				if (queryString === '?') {
					queryString = queryString + 'datafim=' + filter.datafim;
				} else queryString = queryString + '&datafim=' + filter.datafim;
			}

			//Monta Query URL
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/apergs/arquivos' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/apergs/arquivos?filtered=true');
			}

			resolve(filtered);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		let queryString = '?';
		let filter = this.state.filter

		if (filter.fk_ass) {
			if (queryString === '?') {
				queryString = queryString + 'fk_ass=' + filter.fk_ass;
			} else queryString = queryString + '&fk_ass=' + filter.fk_ass;
		}

		if (filter.dataini) {
			if (queryString === '?') {
				queryString = queryString + 'dataini=' + filter.dataini;
			} else queryString = queryString + '&dataini=' + filter.dataini;
		}

		if (filter.datafim) {
			if (queryString === '?') {
				queryString = queryString + 'datafim=' + filter.datafim;
			} else queryString = queryString + '&datafim=' + filter.datafim;
		}

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getArqs' + queryString)
			.then((r) => r.json())
			.then(async (r) => {
				//Filtra
				console.log(r)
				let items = await this.filterData(r);
				this.setState({ data: items });
			});
	}


	selectFile(e) {
		e.preventDefault();
		$('#html_btn').click();
	}

	selectPath(e) {
		e.preventDefault();
		$('#html_path').click();
	}

	async handleSelectedFile(e) {
		let file = e.target.files[0];
		let data = new FormData();
		data.append('arquivo', file, file.name);
		await axios.post(
			config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivo',
			data
		);
		this.setState({
			arquivo: { name: file.name }
		})
	}

	listaAnos() {
		let listaAnos = [];
		for (let i = 2019; i <= 2040; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
	}

	populaAnos(item, id) {
		return (
			<option value={item - 2000} key={id}>
				{item}
			</option>
		);
	}


	handleView(e, link) {
		// alert(config.protocol + '://' + config.server + ':' + config.portBackend + link)
		let a = document.createElement('a');
		a.target = '_blank';
		a.href = config.protocol + '://' + config.server + ':' + config.portBackend + link
		a.click();

	}


	async handleSelectedPath(e) {
		let files = e.target.files;
		let path = files[0].webkitRelativePath
		let pos = path.indexOf('/')
		this.setState({ path: path.substring(0, pos) })
		let data = new FormData();

		let values = []
		asyncForEach(files, async (item, index) => {
			values.push(item.name)
			data.append('arquivo', item, item.name);

			if (index + 1 === files.length) {
				this.setState({ arquivos: values });
				await axios.post(
					config.protocol + '://' + config.server + ':' + config.portBackend + '/api/uploadArquivo',
					data
				);

			}
		})
	}



	render() {

		let anos = this.listaAnos().map(this.populaAnos);
		let associados = this.state.combos.filter((item) => {
			return item.type === 'associados';
		});

		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Demonstrativos" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Disponibilização de Arquivos para Associados</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
								<div class="row">

									<div class="col-sm-4" >

										{/* <div className="itemFiltro" style={{ width: '300px' }}> */}
										<label className="labelFiltro">Associado</label>
										<Autocomplete
											field="filtroFk_ass"
											items={associados}
											inModal=""
											selected={this.state.filter.associado || { display: '' }}
											select={this.filterAssociado}
										/>
									</div>
									<div class="col-sm-2" style={{ paddingLeft: '25px', width: '200px' }} >
										<label className="labelFiltro">Período</label>
										<input
											type="date"
											id="dataini"
											name="dataini"
											className="form-control"
											onChange={this.handleChange}
											value={this.state.filter.dataini || ''}

										/>
									</div>
									<div class="col-sm-2" style={{ paddingTop: '25px', paddingLeft: '1px', width: '175px' }}>
										<input
											type="date"
											id="datafim"
											name="datafim"
											className="form-control"
											onChange={this.handleChange}
											value={this.state.filter.datafim || ''}
										/>
									</div>
								</div>
							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>

								<button className="buttonNovo" onClick={(e) => this.showModal(e, associados)}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Upload de Arquivos
								</button>

							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
							<Modal.Dialog className="Modal">
								<div>
									<Modal.Header className="ModalBg">
										<div className="ModalHeader">
											<h3 className="headerModal">Disponibilização de Arquivos para Associados</h3>
										</div>
									</Modal.Header>
									<Modal.Body className="ModalBg">
										<div className="ModalBody">
											<form id="gerarDemonstrativos" name="gerarDemonstrativos">
												<div>

													<label htmlFor="descricao">Descrição</label>
													<input
														type="text"
														name="descricao"
														id="descricao"
														className="form-control"
													/>

												</div>

												<div>
													<label htmlFor="enviar_email" className="labelModal">
														Associados:
													</label>
													<MultiSelect
														options={associados}
														selected={this.state.associados}
														onSelectedChanged={selected => this.setState({ associados: selected })}
														className="multiSelectFiltro"
														overrideStrings={{
															selectSomeItems: "Selecione um ou mais associados...",
															allItemsAreSelected: "Todos",
															selectAll: "SELECIONAR TODOS",
															search: "Pesquisar",
														}}
													/>

													<div>
														<h6 className="headerModal">Upload Arquivo Avulso</h6>

														<button
															className="ModalButton"
															type="file"
															onClick={this.selectFile}
															style={{ width: '120px', height: '18px' }}
														>
															Selecionar Arquivo
														</button>

														<input
															type="file"
															name="arquivo"
															id="html_btn"
															// accept=".pdf"
															onChange={this.handleSelectedFile}
														/>

														{this.state.arquivo.name || 'Nenhum arquivo selecionado'}
													</div>

													<div>
														<h6 className="headerModal">Upload Múltiplos Arquivos</h6>

														<button
															className="ModalButton"
															type="file"
															onClick={this.selectPath}
															style={{ width: '120px', height: '18px' }}
														>
															Selecionar Pasta
														</button>

														<input directory="" webkitdirectory="" type="file" id="html_path" onChange={this.handleSelectedPath} />

														{this.state.path || 'Nenhuma pasta selecionada'}
													</div>

													{/* <div>
														<h6 className="headerModal">Demonstrativos Mensais</h6>

														<div class="row" style={{ padding: '3px' }}>
															<div class="col">
																<label htmlFor="competencia_mes" >
																	Competência/Débito:
																</label>
															</div>

															<div class="col">

																<select
																	name="competencia_mes"
																	id="competencia_mes"
																	className="selectModal"
																>
																	<option value="">Mês</option>
																	<option value="1">JANEIRO</option>
																	<option value="2">FEVEREIRO</option>
																	<option value="3">MARÇO</option>
																	<option value="4">ABRIL</option>
																	<option value="5">MAIO</option>
																	<option value="6">JUNHO</option>
																	<option value="7">JULHO</option>
																	<option value="8">AGOSTO</option>
																	<option value="9">SETEMBRO</option>
																	<option value="10">OUTUBRO</option>
																	<option value="11">NOVEMBRO</option>
																	<option value="12">DEZEMBRO</option>
																</select>

																<select
																	name="competencia_ano"
																	id="competencia_ano"
																	className="selectModal"
																>
																	<option value="">Ano</option>
																	{anos}
																</select>
															</div>
														</div> */}


													{/* <div>
															<h6 className="headerModal">DIRF</h6>

															<div class="row" style={{ padding: '3px' }}>
																<div class="col">
																	<label htmlFor="competencia_dirf" >
																		Competência:
																	</label>
																</div>

																<div class="col">
																	<select
																		name="competencia_dirf"
																		id="competencia_dirf"
																		className="selectModal"
																	>
																		<option value="">Ano</option>
																		{anos}
																	</select>
																</div>
															</div>
														</div>
													</div> */}
												</div>
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer className="ModalBg">
										<div className="ModalFooter">
											<button className="ModalButton" onClick={this.handleSave}>
												Confirmar
											</button>
											<button className="ModalButton" onClick={this.closeModal}>
												Fechar
											</button>
										</div>
									</Modal.Footer>
								</div>
							</Modal.Dialog>
						</div >
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: 'Arquivo',
											accessor: 'nome',
											width: 300,
											// show: false
										},
										{
											Header: 'Associado',
											accessor: 'nomeass',
											width: 300
										},
										{
											Header: 'Descrição',
											accessor: 'descricao',
											width: 300,
											// show: false
										},
										{
											Header: 'Data Disponibilização',
											accessor: 'data',
											width: 150,
											// show: false
										},

										{
											Header: 'PK',
											accessor: 'pk_arq',
											width: 100,
											show: false
										},
										{
											Header: 'Opções',
											minWidth: 200,
											maxWidth: 200,
											Cell: (row) => {
												return (
													<div  >
														<button
															className="buttonDetailColumn2"

															onClick={(e) => {
																this.handleView(e, row.row.nome);
															}}

															style={{ width: '80px' }}

														>
															<Icon size={20} icon={view} />
															Visualizar
														</button>

														<button
															className="buttonDetailColumn"
															onClick={(e) => {
																this.handleDelete(e, row.row.pk_arq);
															}}
															style={{ width: '80px' }}
														>
															<Icon size={20} icon={iosTrash} />
															Excluir
														</button>

													</div>
												);
											}
										}
									]}
									defaultSorted={[
										{
											id: 'data',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div >
				</div >
			</div >
		);
	}
}

export default Arquivos;
