import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
// import {LinkContainer}  from 'react-router-bootstrap'
import 'react-table/react-table.css';
import { asyncForEach, getParameterByName, populateForm } from '../Utils';
import swal from '@sweetalert/with-react';
import Autocomplete from '../components/Autocomplete';

const inputParsers = {
	date(input) {
		const [ month, day, year ] = input.split('/');
		return `${year}-${month}-${day}`;
	},
	uppercase(input) {
		return input.toUpperCase();
	},
	number(input) {
		return parseFloat(input);
	}
};

class RegistroFornecedores extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			recebido: 0,
			automatico: false,
			filter: [],
			selected: {
				subclasse: []
			},
			filterChanged: '',
			edit: false,
			data: []
		};
		this.submitData = this.submitData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.selectSubclasse = this.selectSubclasse.bind(this);
		this.selectCidade = this.selectCidade.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}



	async componentDidMount() {
		//Busca valores para combo de filtro
		//Situação
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getSubclasses')
			.then((r) => r.json())
			.then(async (r) => {
				let comboSubclasses = this.state.combos;
				await asyncForEach(r, async (item) => {
					let subclasses = {
						type: 'subclasses',
						display: item.descricao,
						value: item.pk_sub
					};
					await comboSubclasses.push(subclasses);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
			.then((r) => r.json())
			.then(async (r) => {
				let comboCidades = this.state.combos;
				await asyncForEach(r, async (item) => {
					let cidades = {
						type: 'cidades',
						display: item.nome,
						value: item.pk_cid
					};
					await comboCidades.push(cidades);
				});
				this.setState({ combos: this.state.combos });
			});

		//Testar se é edição
		let query = {};
		query.pk = Number(getParameterByName('pk'));

		if (query.pk > 0) {
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/getFornecedores?pk=' +
					query.pk
			)
				.then((r) => r.json())
				.then(async (r) => {
					if (typeof r[0] === 'undefined') {
						window.location.href = '/fornecedores';
					} else {
						let form = document.getElementById('registroFornecedores');
						let selectedSubclasse = this.state.combos.filter((item) => {
							return item.type === 'subclasses' && item.value === r[0].fk_sub;
						});
						this.setState({
							selected: {
								subclasse: selectedSubclasse[0]
							}
						});

						let selectedCidades = this.state.combos.filter((item) => {
							return item.type === 'cidades' && item.value === r[0].fk_cid;
						});
						this.setState({
							selected: {
								cidade: selectedCidades[0]
							}
						});

						console.log(r[0]);
						populateForm(form, r[0]);
					}
				});
			this.setState({ edit: true });
		}
	}

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value;
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
	}

	async modelingData(data) {
		//Trata os campos
	}

	handleSave(e) {
		e.preventDefault();
		swal({
			title: 'Atenção!',
			text: 'Deseja ' + (this.state.edit ? 'editar' : 'incluir') + ' o registro?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) this.submitData(e);
		});
	}

	handleBack(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao voltar, todas as alterações serão descartadas. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) window.history.back();
		});
	}

	validateData(data) {
		let valid = { isValid: true };
		//Consistências



		//Razão Social
		if (!data.get('razao_social')) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo RAZÃO SOCIAL.' };
		}

		//Nome Fantasia
		if (!data.get('nome_fantasia')) {
			return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo NOME FANTASIA.' };
		}

		// //Endereço
		// if (!data.get('endereco')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo ENDEREÇO.' };
		// }

		// //Bairro
		// if (!data.get('bairro')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo BAIRRO.' };
		// }

		// //CEP
		// if (!data.get('cep')) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo CEP.' };
		// }

		// //Subclasse
		// if (Number(data.get('fk_sub') || 0) === 0) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo SUBCLASSE.' };
		// }

		// //Cidade
		// if (Number(data.get('fk_cid' || 0)) === 0) {
		// 	return { isValid: false, title: 'Campo inválido!', message: 'Verifique o campo CIDADE.' };
		// }

		console.log(valid);
		//Retorna resultado
		return valid;
	}

	submitData(e) {
		e.preventDefault();
		//Pega valores do form
		const form = document.getElementById('registroFornecedores');
		const data = new FormData(form);

	
		let validate = this.validateData(data);

		if (!validate.isValid) {
			swal(validate.title, validate.message, 'error');
			throw validate;
		}

		//Trata valores conforme data-parse dos inputs
		for (let name of data.keys()) {
			const input = form.elements[name];
			//console.log(input)
			//Rejeita type = undefined (radio inputs)
			if (typeof (input || []).type !== 'undefined') {
				const parserName = input.dataset.parse;
				if (parserName) {
					const parser = inputParsers[parserName];
					const parsedValue = parser(data.get(name));
					data.set(name, parsedValue);
				}
			}
		}

		//Converte FormData em JSON
		var object = {};
		data.forEach(function(value, key) {
			object[key] = value;
		});
		var json = JSON.stringify(object);

		//Post no server
		if (this.state.edit) {
			//Editar
			console.log(json);
			fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/editFornecedores?pk=' +
					getParameterByName('pk'),
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: json
				}
			)
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Alteração realizada', 'Registro alterado com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Alteração não realizada', 'Registro não foi alterado. Verifique os campos.', 'error');
					}
				});
		} else {
			//Inserir
			fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/novoFornecedores', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: json
			})
				.then((r) => r.json())
				.then((r) => {
					console.log(r);
					if (r.message === 'Success!') {
						swal('Inclusão realizada', 'Registro incluído com sucesso!', 'success').then((result) => {
							window.history.back();
						});
					} else {
						swal('Inclusão não realizada', 'Registro não foi incluído. Verifique os campos.', 'error');
					}
				});
		}
	}


	selectSubclasse(item) {
		this.setState({
			selected: {
				subclasse: item
			}
		});
	}

	selectCidade(item) {
		this.setState({
			selected: {
				cidade: item
			}
		});
	}


	render() {
		let subclasses = this.state.combos.filter((item) => {
			return item.type === 'subclasses';
		});		
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidades';
		});
		console.log(this.state.combos);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Fornecedores" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Registro de Contas a Receber</h3>
					</div>
					{/*********************** Campos ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginRight: '30px', paddingBottom: '30px' }}>
							<form id="registroFornecedores" name="registroFornecedores" onSubmit={this.submitData}>
								<div style={{ height: 'auto' }}>
									{/* <Tabs defaultActiveKey={1} animation={false} id="uncontrolled-tab-example"> */}
									<div className="boxRegistro">
										<div
											style={{
												paddingTop: '30px'
											}}
										>
											<div className="row">
												<div className="col-sm-4">
													<label>Razão Social:</label>
													<input type="text" name="razao_social" className="form-control" data-parse="uppercase" />
												</div>
												<div className="col-sm-3">
													<label>Nome Fantasia:</label>
													<input type="text" name="nome_fantasia" className="form-control" data-parse="uppercase" />
												</div>
												<div className="col-sm-4">
													<label>Endereço:</label>
													<input type="text" name="endereco" className="form-control" data-parse="uppercase" />
												</div>
												<div className="col-sm-3">
													<label>Bairro:</label>
													<input type="text" name="bairro" className="form-control" data-parse="uppercase" />
												</div>
												<div className="col-sm-2" style={{ width: '120px' }}>
													<label>CEP:</label>
													<input type="text" name="cep" className="form-control" data-parse="uppercase" />
												</div>
												<div
													className="col-sm-3"
													style={{ height: 'auto', marginBottom: '-10px' }}
												>
													<label>Subclasse:</label>
													<Autocomplete
														field="fk_sub"
														items={subclasses}
														inModal=""
														selected={this.state.selected.subclasse}
														select={this.selectSubclasse}
													/>
												</div>
												<div
													className="col-sm-4"
													style={{ height: 'auto', marginBottom: '-10px' }}
												>
													<label>Cidade:</label>
													<Autocomplete
														field="fk_cid"
														items={cidades}
														inModal=""
														selected={this.state.selected.cidade}
														select={this.selectCidade}
													/>
												</div>
												
												
												<div className="col-sm-12">
													<label>Observações:</label>
													<textarea
														type="text"
														name="observacao"
														data-parse="uppercase"
														className="form-control"
														style={{ height: '100px' }}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br />
								<div className="buttonsCadastro">
									<button className="buttonSalvar" onClick={this.handleSave}>
										Salvar
									</button>
									<button className="buttonVoltar" onClick={this.handleBack}>
										Voltar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistroFornecedores;
